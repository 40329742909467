// react imports
import { Box } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Progress } from 'reactstrap';
// timeone info
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = dayjs.tz.guess();

// logos
// import AWS_GuardDuty from '@root/assets/svg/Threats/AWS_GuardDuty.svg';
import AWS_GuardDuty from '@root/assets/img/GuardDuty.svg';
import anomalous from '@root/assets/svg/Threats/Anomalous.svg';
import Malware from '@root/assets/svg/Threats/Malware.svg';
import AWSIcon from '@root/assets/img/header/cloud_providers/aws.png';
import AzureIcon from '@root/assets/img/header/cloud_providers/azure.png';
import GCPIcon from '@root/assets/img/header/cloud_providers/gcp.png';

// import Secrets from '@root/assets/svg/Threats/Secrets.svg';
import Secrets from '@root/assets/svg/Risk/Risks Icons/Exposed Secrets.svg';
// import './style.scss';
// import './style.css';
import './dashboard.scss';

// common components
import { Grid, Skeleton } from '@mui/material';
import agent from '@root/agent';

import externaIcon from '@root/assets/svg/external-link.svg';
import NoData from '@root/components/common/NoData';
import CloudProviderBar from '@root/components/v2/content/CloudProviderBar';
import ContentBox from '@root/components/v2/content/ContentBox';
import LastUpdate from '@root/components/v2/content/LastUpdate';
import PageContainer from '@root/components/v2/layout/PageContainer';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeader from '@root/components/v2/layout/PageHeader';
import useTimeFilter from '@root/hooks/SelectTimeFilter.jsx';
import { useEnvironments, useSelectedEnvironments } from '@root/hooks/environments';
import { useQuery as useQueryReact } from '@tanstack/react-query';

// dashboard components
import CountTooltip from '@root/components/table/cell-types/CountTooltip';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';
import AssessmentDropdown from '@root/components/v2/content/AssessmentFilter/AssessmentDropdown';
import GaugeChart from './Charts/GaugeChart';
import RiskTrendChart from './Charts/RiskTrendChart';
import CompliancePosture from './components/componentsNew/CompliancePosture';
import InternetExposedResources from './components/componentsNew/InternetExposedResources';
import PrioritizationFunnel from './components/componentsNew/PrioritizationFunnel';
import Regions from './components/componentsNew/Regions';
import ResourceTrend from './components/componentsNew/ResourceTrend';
import RiskPosture from './components/componentsNew/RiskPosture';
// import RiskRecommendation from './components/componentsNew/RiskRecommendation';
import Vulnerabilities from './components/componentsNew/Vulnerabilities';
import vulnerabilitiesLogo from '@root/assets/svg/Cloud_Events/Vulnerabilities.svg';

//import
// redux
import ComplianceIconMapping from '@root/components/complianceIconMapping';
import { useCloudProvider } from '@root/components/v2/content/CloudProviderBar/CloudProviderContext';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import TopOpenTooltip from '@root/components/v2/ui-elements/Tooltip/TopOpenTooltip';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import { connect } from 'react-redux';
import DonutChart from './Charts/DonutChart';
import { fetchDashboardData, fetchuserPolicyData, fetchuserPolicyTableData } from './action';
import CQShieldPriority from './components/componentsNew/CQShieldPriority';
import DashboardSkeleton from './skeleton';
import TopRiskRecommendation from './components/componentsNew/TopRiskRecommendation';
import TopTriggered from './components/componentsNew/TopTriggered';
import InventoryComponent from './components/componentsNew/InventoryComponent';
import OpenThreatsAndAnamoly from './components/componentsNew/OpenThreatsAndAnamoly';
import ThreatExposed from './components/componentsNew/ThreatExposed.jsx';
const mapStateToProps = state => {
  return {
    dashboardStatus: state.mainDashboard.status,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDashboard: () => dispatch(fetchDashboardData),
    fetchUserPolicy: () => dispatch(fetchuserPolicyData),
    fetchUserPolicyTable: () => dispatch(fetchuserPolicyTableData),
  };
};

const DashboardNew = props => {
  const history = useHistory();

  const { dashboardStatus, fetchUserPolicy, fetchUserPolicyTable } = props;
  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();
  const { timeFilter } = useTimeFilter();
  // const [cloudProvider, setCloudProvider] = useState('all');
  const { cloudProvider } = useCloudProvider();
  const [selectedTab, setSelectedTab] = useState('All');
  const [loading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const allEnvironments = useEnvironments();
  useEffect(() => {
    if (dashboardStatus === 'loading') {
      // fetchDashboard();
      fetchUserPolicy();
      fetchUserPolicyTable();
    }
  }, [dashboardStatus, fetchUserPolicy, fetchUserPolicyTable]);

  // const handleCloudProviderChange = useCallback(provider => {
  //   setCloudProvider(provider);
  // }, []);

  const environments = useMemo(() => {
    if (selectedEnvironments.length && cloudProvider !== 'all') {
      return selectedEnvironments.filter(environment => environment.cloudProvider === cloudProvider);
    } else if (cloudProvider === 'all') {
      return selectedEnvironments.filter(
        environment => environment.cloudProvider === 'aws' || environment.cloudProvider === 'gcp',
      );
    }
    return allEnvironments;
  }, [selectedEnvironments, allEnvironments, cloudProvider]);

  // api calls
  const {
    data: lastUpdate,
    isFetching: isFetchingLastUpdate,
    isLoading: isLoadingLastUpdate,
  } = useQueryReact(
    ['get_last_updated', environments, selectedEnvironmentTags, cloudProvider, timeFilter, selectedValue],
    () =>
      agent.Dashboard_New.get_last_updated({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: riskTrend,
    isFetching: isFetchingRiskTrend,
    isLoading: isLoadingRiskTrend,
  } = useQueryReact(
    [
      'get_risk_trend',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_risk_trend({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: securityScore,
    isFetching: isFetchingSecurityScore,
    isLoading: isLoadingSecurityScore,
  } = useQueryReact(
    [
      'get_security_score',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_security_score({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: compliance,
    isFetching: isFetchingCompliance,
    isLoading: isLoadingCompliance,
  } = useQueryReact(
    [
      'get_compliance',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_compliance({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: top3RiskRecommendations,
    isFetching: isFetchingTop3RiskRecommendations,
    isLoading: isLoadingTop3RiskRecommendations,
  } = useQueryReact(
    [
      'get_top_3_risk_recommendations',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_top_3_risk_recommendations({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: compliancePosture,
    isFetching: isFetchingCompliancePosture,
    isLoading: isLoadingCompliancePosture,
  } = useQueryReact(
    [
      'get_compliance_posture',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_compliance_posture({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: threats,
    isFetching: isFetchingThreats,
    isLoading: isLoadingThreats,
  } = useQueryReact(
    [
      'get_threats',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_threats({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: identitySecurityPosture,
    isFetching: isFetchingIdentitySecurityPosture,
    isLoading: isLoadingIdentitySecurityPosture,
  } = useQueryReact(
    [
      'get_identity_security_posture',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_identity_security_posture({
        accountId: environments.map(e => e.id),
        assessment_time_filter: selectedValue,
        period:
          timeFilter === '24h'
            ? 1
            : timeFilter === '7d'
            ? 7
            : timeFilter === '30d'
            ? 30
            : timeFilter === '90d'
            ? 90
            : 1,
        timezone: 'Asia/Calcutta', // moment
        latest_scan_id: environments.map(e => e.identity_scan_id).filter(i => i !== null),
        all: timeFilter === 'All' ? true : false,
      }),
  );

  const {
    data: vulnerabilities,
    isFetching: isFetchingVulnerabilities,
    isLoading: isLoadingVulnerabilities,
  } = useQueryReact(
    ['get_vulnerability', `${environments.map(e => e.id)}`, selectedEnvironmentTags, cloudProvider, selectedValue],
    () =>
      agent.Dashboard_New.get_vulnerabilities({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        assessment_time_filter: selectedValue,
        cloud_provider: cloudProvider,
        // date_filter: timeFilter
        //   ? timeFilter === '24h'
        //     ? '24hours'
        //     : timeFilter.replace('d', ' days').replace(/ /g, '')
        //   : '24hours',
        date_filter: '90days',
        timezone: TIMEZONE,
      }),
  );
  const {
    data: prioritizationFunnel,
    isFetching: isFetchingPrioritizationFunnel,
    isLoading: isLoadingPrioritizationFunnel,
  } = useQueryReact(['get_prioritization_funnel', `${environments.map(e => e.id)}`], () =>
    agent.Dashboard_New.get_prioritization_funnel({
      // scope: selectedEnvironmentTags,
      environments: environments.map(e => e.id),
      assessment_time_filter: selectedValue,
      cloud_provider: cloudProvider,
      date_filter: timeFilter
        ? timeFilter === '24h'
          ? '24hours'
          : timeFilter.replace('d', ' days').replace(/ /g, '')
        : '24hours',
      timezone: TIMEZONE,
    }),
  );

  const {
    data: internetExposedResources,
    isFetching: isFetchingInternetExposedResources,
    isLoading: isLoadingInternetExposedResources,
  } = useQueryReact(
    [
      'get_internet_exposed_resources',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_internet_exposed_resources({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: resourceTrend,
    isFetching: isFetchingResourceTrend,
    isLoading: isLoadingResourceTrend,
  } = useQueryReact(
    [
      'get_resource_trend',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_resource_trend({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
  );

  const {
    data: regions,
    isFetching: isFetchingRegions,
    isLoading: isLoadingRegions,
  } = useQueryReact(
    [
      'get_regions',
      `${environments.map(e => e.id)}`,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
      selectedTab,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_regions({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        assessment_time_filter: selectedValue,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        service_category: selectedTab,
        timezone: TIMEZONE,
      }),
  );
  useEffect(() => {}, []);

  const {
    data: assessmentTimeFilter,
    isFetching: isFetchingAssessmentTimeFilter,
    isLoading: isLoadingAssessmentTimeFilter,
  } = useQueryReact(
    [
      `get-assessment-time-filter`,
      selectedEnvironmentTags,
      timeFilter,
      selectedEnvironments.map(item => item.id),
      cloudProvider,
    ],

    () =>
      agent.Compliance.get_assessment_time_filter({
        scope: selectedEnvironmentTags,
        environments: selectedEnvironments.map(item => item.id),
        compliance_family: [],
        cloud_provider: cloudProvider,
        date_filter: timeFilter
          ? timeFilter === '24h'
            ? '24hours'
            : timeFilter.replace('d', ' days').replace(/ /g, '')
          : '24hours',
        timezone: TIMEZONE,
      }),
    {
      enabled: environments.length > 0,
    },
  );

  const idToTagsMapping = useMemo(() => {
    let mapping = {};
    if (environments?.length) {
      for (let item of environments) {
        mapping[item?.id] = item?.tags;
      }
    }
    return mapping;
  }, [environments]);

  const securityScoreEnvBreakup = useMemo(() => {
    // console.log('environments', environments)
    try {
      if (!securityScore?.error && securityScore?.data?.env_breakup.length) {
        const envBreakup = securityScore?.data?.env_breakup?.filter(
          item => item?.cloud_provider === cloudProvider || cloudProvider === 'all',
        );
        const modifiedEnvBreakup = [];
        for (let item of envBreakup) {
          modifiedEnvBreakup.push({
            ...item,
            tags: item?.env_id in idToTagsMapping ? idToTagsMapping[item?.env_id] : [],
          });
        }
        console.log(modifiedEnvBreakup, 'modifiedEnvBreakup');
        return modifiedEnvBreakup;
      }
    } catch (e) {
      console.error('SOME ERROR WHILE PARSING ENV BREAKUP FOR SECURITY SCORE');
      return [];
    }
  }, [securityScore, cloudProvider, idToTagsMapping]);

  const [assessmentFilterOptions, setAssessmentFilterOptions] = useState([]);
  useEffect(() => {
    if (!isFetchingAssessmentTimeFilter && assessmentTimeFilter?.data?.assessment_times?.length) {
      setAssessmentFilterOptions(
        assessmentTimeFilter?.data?.assessment_time.map(item => {
          return {
            label: DateFormatPipe(item.assessment_time, true),
            value: item.assessment_time,
            env_id: item.environment_id,
          };
        }),
      );
    }
  }, [assessmentTimeFilter, isFetchingAssessmentTimeFilter]);
  useEffect(() => {
    setSelectedValue('');
  }, [assessmentTimeFilter]);

  const _threatData = useMemo(() => {
    if (!threats?.data || _.isEmpty(threats?.data)) return [];
    return threats?.data?.threat_types?.map(item => {
      return {
        name: item.threat_type === 'Threat & Anomalous' ? 'Threat/Anomaly' : item.threat_type,
        value: item.total_threats,
      };
    });
  }, [threats]);

  const theatColors = {
    'AWS GuardDuty': '#FF5252',
    'Threat/Anomaly': '#ED5656',
    Malware: '#194BFB',
    Secrets: '#FFB951',
    Vulnerability: '#7593FD',
  };
  if (
    isLoadingAssessmentTimeFilter &&
    isLoadingRegions &&
    isLoadingResourceTrend &&
    isLoadingInternetExposedResources &&
    isLoadingVulnerabilities &&
    isLoadingIdentitySecurityPosture &&
    isLoadingThreats &&
    isLoadingCompliancePosture &&
    isLoadingTop3RiskRecommendations &&
    isLoadingCompliance &&
    isLoadingSecurityScore &&
    isLoadingRiskTrend &&
    isLoadingLastUpdate &&
    isLoadingPrioritizationFunnel
  ) {
    return <DashboardSkeleton />;
  }

  console.log('prior_funnel', prioritizationFunnel);

  return (
    <>
      <PageContainer className={'dashboard'}>
        <PageHeader title={'Dashboard'} breadcrumb={false}></PageHeader>
        <PageContent>
          <CloudProviderBar
            // cloudProvider={cloudProvider}
            // onChange={handleCloudProviderChange}
            // rightElement={
            //   <LastUpdate
            //     date={
            //       !isFetchingLastUpdate && cloudProvider !== 'gcp' && cloudProvider !== 'azure'
            //         ? new Date(lastUpdate?.data.last_updated)
            //         : null
            //     }
            //   />
            // }
            // />
            rightElement={
              isFetchingAssessmentTimeFilter ? (
                <Box>
                  <Skeleton width="100px" />
                </Box>
              ) : assessmentTimeFilter?.data?.assessment_times?.length === 0 ? (
                <LastUpdate
                  date={
                    !isFetchingLastUpdate && cloudProvider !== 'azure' ? new Date(lastUpdate?.data.last_updated) : null
                  }
                />
              ) : (
                <AssessmentDropdown
                  setValue={setSelectedValue}
                  value={
                    !selectedValue
                      ? DateFormatPipe(assessmentFilterOptions[0]?.value, true)
                      : DateFormatPipe(selectedValue, true)
                  }
                  options={assessmentFilterOptions}
                  selectedValue={selectedValue}
                  envtooltiptrue={true}
                  singleSelect
                />
              )
            }
          />
          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {/* 1st row */}

            <div>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ContentBox
                    title="Security Score"
                    titleSize="medium"
                    height={'442px'}
                    infoMsg={
                      <>
                        Major contributing factors in the order of severity are Anomaly/threat, Attack Paths,
                        <br /> CQ Shield Priority. Fixing each will improve the security score.
                      </>
                    }
                    titleContainerStyle={{
                      // border: "0px",
                      fontSize: '16px !important',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      borderBottom: '1px solid #CBD5E0',

                      margin: '0px',
                      color: 'var(--keyinsight-text-color)', // same as #1a202c
                      // background: '#F7FAFC',
                      // backgroundColor: 'var(--keyinsight-box-header-background)',
                    }}
                    headerEndElement={
                      <div
                        onClick={() => {
                          history.push(
                            buildBreadcrumbUrl({
                              path: '/environments',
                              breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Environments' }],
                            }),
                          );
                        }}>
                        <img src={externaIcon} style={{ width: '12px', height: '12px', cursor: 'pointer' }} />
                      </div>
                    }
                    paddingMode="content"
                    containerClassName={'border-box-no-pad'}>
                    {cloudProvider === 'azure' ? (
                      <NoData height={'442px'} />
                    ) : isFetchingSecurityScore || loading ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        height="406px"
                        padding="15px"
                        paddingTop={'30px'}>
                        {' '}
                        <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                          <Skeleton variant="circular" width="190px" height="190px" />
                        </Box>
                        <Box flexGrow={1} height="10px" width="100%" />
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginBottom="10px">
                          <Skeleton width="80px" />
                          <Skeleton width="80px" />
                        </Box>
                        <Grid sx={{ width: '100%' }}>
                          {Array.from(new Array(4)).map((i, index) => (
                            <Box key={i} width="100%" display="flex" flexDirection="column" gap={'10px'}>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                marginTop={index === 0 ? '0px' : '8px'}>
                                <Skeleton width="70px" />
                                <Skeleton width="70px" />
                              </Box>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    ) : (
                      <div style={{ height: '442px', padding: '15px 0px' }}>
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                          <GaugeChart
                            score={securityScore?.data?.average_security_score}
                            count={securityScore?.data?.total_env_count}
                          />
                        </Grid>

                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            pt: '10px',
                            mt: '20px',
                            paddingLeft: '15px',
                            paddingRight: '15px',
                          }}>
                          <p className="security_container_title m-0" style={{ color: '#1a202c' }}>
                            {' '}
                            Environment ID
                          </p>
                          <p className="security_container_title m-0" style={{ color: '#1a202c' }}>
                            {' '}
                            Score
                          </p>
                        </Grid>
                        <div
                          className="CustomScrollbar"
                          style={{
                            height: '150px',
                            // paddingBottom: '65px',
                            paddingBottom: '15px',
                            overflow: 'auto',
                          }}>
                          <Grid
                            sx={{
                              gap: '2px',
                              color: '#1a202c',
                              display: 'flex',
                              flexDirection: 'column',
                              paddingLeft: '15px',
                              paddingRight: '10px',
                            }}>
                            {securityScoreEnvBreakup?.map(env => (
                              <Grid
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  pt: '10px',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '5px',
                                  }}>
                                  <div
                                    style={{
                                      boxSizing: 'border-box',
                                      width: '40px',
                                      height: '20px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}>
                                    {env.cloud_provider === 'aws' && (
                                      <img src={AWSIcon} alt="AWS" style={{ height: '20px', marginRight: '5px' }} />
                                    )}
                                    {env.cloud_provider === 'azure' && (
                                      <img src={AzureIcon} alt="AZURE" style={{ height: '20px', marginRight: '5px' }} />
                                    )}
                                    {env.cloud_provider === 'gcp' && (
                                      <img src={GCPIcon} alt="GCP" style={{ height: '20px', marginRight: '5px' }} />
                                    )}
                                  </div>
                                  <div style={{ boxSizing: 'border-box' }}>
                                    <Tooltip title={env?.env_id}>
                                      <p className="security_enviroment_id m-0 SingleLine security_score">
                                        {env?.env_id?.length > 12 ? `${env.env_id.slice(0, 12)}...` : env?.env_id}
                                      </p>
                                    </Tooltip>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                    }}>
                                    <CountTooltip labels={env?.tags} />
                                  </div>
                                </div>
                                <div style={{ textAlign: 'right' }} className="security_value m-0">
                                  {' '}
                                  {env?.security_score.toFixed(0)}%
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>
                    )}
                  </ContentBox>
                </Grid>
                <Grid item xs={3}>
                  <ContentBox
                    title="Risk Trends"
                    titleSize="medium"
                    height={'442px'}
                    infoMsg={`Risk Trend for the selected time range`}
                    titleContainerStyle={{
                      // border: "0px",
                      fontSize: '16px !important',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      borderBottom: '1px solid #CBD5E0',

                      margin: '0px',
                      color: 'var(--keyinsight-text-color)',
                      // background: '#F7FAFC',
                      // backgroundColor: 'var(--keyinsight-box-header-background)',
                    }}
                    headerEndElement={
                      <div
                        onClick={() => {
                          history.push(
                            buildBreadcrumbUrl({
                              path: '/risk',
                              breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Risk' }],
                            }),
                          );
                        }}>
                        <img src={externaIcon} style={{ width: '12px', height: '12px', cursor: 'pointer' }} />
                      </div>
                    }
                    paddingMode="content"
                    containerClassName={'border-box-no-pad'}>
                    {cloudProvider === 'azure' ? (
                      <NoData height={'442px'} />
                    ) : isFetchingRiskTrend || loading ? (
                      <Box position={'relative'} height={'100%'}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          height="406px"
                          padding="15px"
                          paddingTop={'30px'}>
                          <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                            <Skeleton variant="rectangular" height="190px" sx={{ width: '100%', flexGrow: 1 }} />
                          </Box>
                          <Box flexGrow={1} height="10px" width="100%" />
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            marginBottom="10px">
                            <Skeleton width="80px" />
                            <Skeleton width="70px" />
                            <Skeleton width="80px" />
                          </Box>
                          <Grid sx={{ width: '100%' }}>
                            {Array.from(new Array(4)).map((i, index) => (
                              <Box key={i} width="100%" display="flex" flexDirection="column" gap={'10px'}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  marginTop={index === 0 ? '0px' : '8px'}>
                                  <Skeleton width="70px" />
                                  <Skeleton width="70px" />
                                  <Skeleton width="70px" />
                                </Box>
                              </Box>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    ) : (
                      <div style={{ height: '442px', padding: '15px' }}>
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '35px',
                            paddingLeft: '15px',
                          }}>
                          <RiskTrendChart
                            data={timeFilter.includes('h') ? riskTrend?.data?.trend_data : riskTrend?.data?.trend_data}
                            timeFilter={timeFilter}
                            height={165}
                          />
                        </Grid>
                        <Grid container sx={{ py: '10px' }}>
                          <Grid xs={5}>
                            <p className="security_container_title m-0" style={{ textAlign: 'left', color: '#1a202c' }}>
                              Type
                            </p>
                          </Grid>
                          <Grid xs={5}>
                            <p className="security_container_title m-0" style={{ textAlign: 'left', color: '#1a202c' }}>
                              Total
                            </p>
                          </Grid>
                          <Grid xs={2}>
                            <p
                              className="security_container_title m-0"
                              style={{ textAlign: 'right', color: '#1a202c' }}>
                              Trend
                            </p>
                          </Grid>
                        </Grid>

                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                          {riskTrend?.data?.type_breakup?.map(item => (
                            <Grid container>
                              <Grid container xs={5} sx={{ gap: '5px', alignItems: 'center' }}>
                                <p
                                  className="m-0"
                                  style={{
                                    width: '8px',
                                    height: '8px',
                                    background: `${
                                      item?.type === 'Total'
                                        ? '#194BFB'
                                        : item?.type === 'Critical'
                                        ? '#F84D4D'
                                        : item?.type === 'New'
                                        ? '#1A202C'
                                        : item?.type === 'Resolved'
                                        ? '#30E0A1'
                                        : '#194BFB'
                                    }`,
                                    borderRadius: '50%',
                                    paddingRight: '5px',
                                  }}></p>
                                <p className="security_enviroment_id m-0" style={{ color: '#1a202c' }}>
                                  {' '}
                                  {item?.type}{' '}
                                </p>
                              </Grid>
                              <Grid xs={5}>
                                <p className="risk_trend_value m-0" style={{ color: '#1a202c' }}>
                                  {' '}
                                  {item?.total}
                                </p>
                              </Grid>
                              <Grid container xs={2} sx={{ alignItems: 'center', justifyContent: 'end' }}>
                                <div
                                  className="risk_trend_percentage m-0"
                                  style={{
                                    // width: '50px',
                                    display: 'flex',
                                    gap: '2px',
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    color: `${item?.trend > 0 ? '#03bb34' : item?.trend < 0 ? '#F84D4D' : '#AFB1C1'}`,
                                  }}>
                                  {item?.trend < 0 ? -1 * item?.trend : item?.trend}%
                                  {item?.trend < 0 ? (
                                    <svg
                                      style={{
                                        marginLeft: '2px',
                                      }}
                                      width="10"
                                      height="6"
                                      viewBox="0 0 10 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M9.58711 3.76592L5.98011 0.382145C5.43697 -0.127382 4.5596 -0.127382 4.01646 0.382144L0.409459 3.76592C-0.467918 4.589 0.15878 6 1.39825 6L8.61224 6C9.85171 6 10.4645 4.589 9.58711 3.76592Z"
                                        fill="#F72121"
                                      />
                                    </svg>
                                  ) : item?.trend > 0 ? (
                                    <svg
                                      style={{
                                        marginLeft: '2px',
                                      }}
                                      width="10"
                                      height="6"
                                      viewBox="0 0 10 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                                        fill="#03BB34"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      style={{
                                        marginLeft: '2px',
                                      }}
                                      width="14"
                                      height="10"
                                      viewBox="0 0 14 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M10.2341 9.58711L13.6179 5.98011C14.1274 5.43697 14.1274 4.55959 13.6179 4.01646L10.2341 0.409459C9.411 -0.467918 8 0.15878 8 1.39825L8 8.61224C8 9.85171 9.411 10.4645 10.2341 9.58711Z"
                                        fill="#AFB1C1"
                                      />
                                      <path
                                        d="M3.76592 9.58711L0.382145 5.98011C-0.127381 5.43697 -0.127381 4.55959 0.382145 4.01646L3.76592 0.409459C4.589 -0.467918 6 0.15878 6 1.39825L6 8.61224C6 9.85171 4.589 10.4645 3.76592 9.58711Z"
                                        fill="#AFB1C1"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    )}
                  </ContentBox>
                </Grid>
                <Grid item xs={3}>
                  <ContentBox
                    title="Compliance"
                    titleSize="medium"
                    height={'442px'}
                    infoMsg={'Compliance score for the applicable compliance families'}
                    // overflowY={'auto'}
                    titleContainerStyle={{
                      // border: "0px",
                      fontSize: '16px !important',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      borderBottom: '1px solid #CBD5E0',

                      color: 'var(--keyinsight-text-color)',
                      // background: '#F7FAFC',
                      // backgroundColor: 'var(--keyinsight-box-header-background)',
                    }}
                    // containerClassName="CustomScrollbar"
                    paddingMode="content"
                    headerEndElement={
                      <div
                        onClick={() => {
                          history.push(
                            buildBreadcrumbUrl({
                              path: '/compliance',
                              breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Compliance' }],
                            }),
                          );
                        }}>
                        <img src={externaIcon} style={{ width: '12px', height: '12px', cursor: 'pointer' }} />
                      </div>
                    }
                    containerClassName={'border-box-no-pad no_overflow'}>
                    {cloudProvider === 'azure' ? (
                      <NoData height={'442px'} />
                    ) : isFetchingCompliance || loading ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        height="406px"
                        padding="15px"
                        paddingTop={'15px'}>
                        {/* <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              marginBottom="10px">
                              <Skeleton width="100px" />
                              <Skeleton width="100px" />
                            </Box> */}
                        <Grid
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            width: '100%',

                            // pt: '15px',
                          }}>
                          {Array.from(new Array(5)).map(i => (
                            <Grid
                              key={i}
                              sx={{
                                border: '1px solid #cbd5e0',
                                padding: '10px 10px',
                                borderRadius: '5px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <Grid>
                                <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                                  <Skeleton variant="rectangular" width="36px" height="36px" />
                                </Box>
                              </Grid>
                              <Grid sx={{ width: '100%', marginLeft: '10px' }}>
                                <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                                  <Skeleton width="30px" />
                                </Box>
                                <Box width="100%">
                                  <Skeleton width="100%" />
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    ) : _.isEmpty(compliance?.data) ? (
                      <NoData height={'100%'} />
                    ) : (
                      <div
                        className="CustomScrollbar"
                        style={{
                          height: '390px',
                          // paddingBottom: '65px',
                          overflow: 'auto',
                          padding: '15px',
                          paddingBottom: '0px',
                        }}>
                        <Grid
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '11px',
                            // pt: '15px',
                          }}>
                          {compliance?.data
                            .sort((a, b) => b?.score - a?.score)
                            .map((item, index) => {
                              return (
                                <Grid id={index} className="compliance_card">
                                  <Grid sx={{ display: 'flex', alignContent: 'center', marginTop: '12px' }}>
                                    <Tooltip title={`${item?.compliance_name}`}>
                                      <div>
                                        <ComplianceIconMapping style={{ width: '36px' }} name={item?.compliance_name} />
                                      </div>
                                    </Tooltip>
                                  </Grid>
                                  <Grid sx={{ width: '100%', marginLeft: '9px' }}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <p className="compliance_card_heading m-0"> {item?.compliance_rule}</p>
                                      <p
                                        className="compliance_card_percentage m-0"
                                        style={{
                                          color:
                                            item?.score >= 90
                                              ? '#03BB34'
                                              : item?.score >= 50
                                              ? '#FFA825'
                                              : item?.score >= 31
                                              ? '#F72121'
                                              : '#CD0404',
                                        }}>
                                        {`${item.score} %`}
                                      </p>
                                    </Grid>
                                    <Grid>
                                      <Progress
                                        className="ProgressBar"
                                        barClassName="ProgressBar__Bar"
                                        barStyle={{
                                          backgroundColor:
                                            item?.score >= 90
                                              ? '#03BB34'
                                              : item?.score >= 50
                                              ? '#FFA825'
                                              : item?.score >= 31
                                              ? '#F72121'
                                              : '#CD0404',
                                          borderRadius: '10px',
                                        }}
                                        value={item?.score}
                                        max={100}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          <Grid item></Grid>
                        </Grid>
                      </div>
                    )}
                  </ContentBox>
                </Grid>
                <Grid item xs={3}>
                  <ContentBox
                    padding="0px"
                    title={
                      cloudProvider !== 'azure' ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <span style={{ color: '#fff', fontSize: '14px', fontWeight: 600, marginRight: '10px' }}>
                            Runtime Protection
                          </span>

                          <div className="m-0 live_icon">
                            <p
                              className="m-0"
                              style={{
                                width: '6px',
                                height: '6px',
                                background: threats?.data?.is_live ? '#F62121' : '#7C7E87',
                                borderRadius: '50%',
                                marginRight: '4px',
                              }}></p>
                            <p className="live_text m-0"> Live</p>
                          </div>
                          <div
                            style={{
                              width: '11px',
                              height: '12px',
                              margin: '5px',
                              backgroundColor: 'white',
                              borderRadius: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <TopOpenTooltip
                              title={
                                <div style={{ padding: '15px' }}>
                                  <span> Real time detection of Threats & Anomalies </span>
                                </div>
                              }>
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                backgroundColor="white"
                                borderRadius="100%">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6 11.2C8.87188 11.2 11.2 8.87188 11.2 6C11.2 3.12812 8.87188 0.8 6 0.8C3.12812 0.799999 0.800001 3.12812 0.800001 6C0.8 8.87188 3.12812 11.2 6 11.2ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 2.34843e-07 9.31371 5.24537e-07 6C8.1423e-07 2.68629 2.68629 -8.1423e-07 6 -5.24537e-07C9.31371 -2.34843e-07 12 2.68629 12 6ZM6 9.62656C5.37909 9.62656 5.2 9.02091 5.2 8.8L5.2 5.44C5.2 5.21909 5.37909 4.82656 6 4.82656C6.62091 4.82656 6.8 5.21909 6.8 5.44L6.8 8.8C6.8 9.02091 6.62091 9.62656 6 9.62656Z"
                                  fill="#718096"
                                />
                                <path
                                  d="M5.2 3.62656C5.2 3.18473 5.55817 2.82656 6 2.82656C6.44183 2.82656 6.8 3.18473 6.8 3.62656C6.8 4.06839 6.44183 4.42656 6 4.42656C5.55817 4.42656 5.2 4.06839 5.2 3.62656Z"
                                  fill="#718096"
                                />
                              </svg>
                            </TopOpenTooltip>
                          </div>
                        </div>
                      ) : (
                        <span style={{ fontSize: '16px', fontWeight: 700, marginRight: '10px' }}>
                          Runtime Protection
                        </span>
                      )
                    }
                    titleSize="medium"
                    paddingMode="content"
                    height={'442px'}
                    titleContainerStyle={{
                      alignItems: 'center',
                      fontSize: '16px !important',
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px',
                      borderBottom: '1px solid #CBD5E0',
                      margin: '0px',
                      color: 'var(--keyinsight-text-color)',
                      background:
                        cloudProvider === 'azure' ? '#7C7E87' : threats?.data?.is_live ? '#F62121' : '#7C7E87',

                      // background: '#F84D4D',
                      // backgroundColor: '#F84D4D',
                    }}
                    containerClassName={'border-box-no-pad'}>
                    {isFetchingThreats || loading ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        height="406px"
                        padding="15px"
                        paddingTop={'30px'}>
                        {' '}
                        <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                          <Skeleton variant="circular" width="190px" height="190px" />
                        </Box>
                        <Box flexGrow={1} height="10px" width="100%" />
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          marginBottom="10px">
                          <Skeleton width="80px" />

                          <Skeleton width="80px" />
                        </Box>
                        <Grid sx={{ width: '100%' }}>
                          {Array.from(new Array(4)).map((i, index) => (
                            <Box key={i} width="100%" display="flex" flexDirection="column" gap={'10px'}>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                marginTop={index === 0 ? '0px' : '8px'}>
                                <Skeleton width="70px" />

                                <Skeleton width="70px" />
                              </Box>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    ) : cloudProvider === 'azure' ? (
                      <NoData height={'442px'} />
                    ) : (
                      <div style={{ padding: '15px' }}>
                        <Grid>
                          {/* <CircleWithText score={threats?.data?.total_threats} /> */}
                          <DonutChart
                            chartData={_threatData}
                            count_value={threats?.data?.total_threats || 0}
                            colors={theatColors}
                          />
                        </Grid>
                        <Grid>
                          <Grid sx={{ display: 'flex', justifyContent: 'space-between', py: '10px' }}>
                            <p className="security_container_title m-0" style={{ color: '#1a202c' }}>
                              {' '}
                              Type
                            </p>
                            <p className="security_container_title m-0" style={{ color: '#1a202c' }}>
                              {' '}
                              Total
                            </p>
                          </Grid>
                          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            {threats?.data?.threat_types
                              .filter(item =>
                                cloudProvider === 'gcp' ? (item?.threat_type === 'AWS GuardDuty' ? false : true) : true,
                              )
                              .map(item => (
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Grid sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <img
                                      style={{ width: '17px' }}
                                      src={
                                        item?.threat_type === 'Malware'
                                          ? Malware
                                          : item?.threat_type === 'AWS GuardDuty'
                                          ? AWS_GuardDuty
                                          : item?.threat_type === 'Secrets'
                                          ? Secrets
                                          : item?.threat_type === 'Vulnerability'
                                          ? vulnerabilitiesLogo
                                          : anomalous
                                      }
                                      alt=""
                                    />
                                    <p className="threat_title m-0" style={{ color: '#1a202c' }}>
                                      {item?.threat_type === 'Threat & Anomalous'
                                        ? 'Threat/Anomaly'
                                        : item?.threat_type}
                                    </p>
                                  </Grid>
                                  <div style={{ width: '100px', display: 'flex', justifyContent: 'flex-end' }}>
                                    <p
                                      className="threat_value m-0"
                                      style={{
                                        color: item?.total_threats > 0 ? '#194BFB' : '#1a202c',
                                        cursor: item?.total_threats > 0 ? 'pointer' : '',
                                        textAlign: 'right',
                                      }}
                                      onClick={() => {
                                        item?.total_threats > 0
                                          ? item?.threat_type === 'Malware'
                                            ? history.push('/data-security/findings?fromRuntime=true&status=INFECTED')
                                            : item?.threat_type === 'AWS GuardDuty'
                                            ? history.push(
                                                buildBreadcrumbUrl({
                                                  path: '/events/all?tab=Threat&fromRunTime=true&source=Guard Duty',
                                                  breadcrumbs: [
                                                    BreadcrumbParts.Dashboard,
                                                    { title: 'All Events' },
                                                    { title: 'Threat' },
                                                  ],
                                                }),
                                              )
                                            : item?.threat_type === 'Secrets'
                                            ? history.push('/inventory/key-insight?fromRuntime=true')
                                            : item?.threat_type === 'Vulnerability'
                                            ? history.push('/vulnerabilities')
                                            : history.push(
                                                buildBreadcrumbUrl({
                                                  path: '/events/all?tab=Threat&fromRuntimeThreat=true',
                                                  breadcrumbs: [
                                                    BreadcrumbParts.Dashboard,
                                                    { title: 'All Events' },
                                                    { title: 'Threat' },
                                                  ],
                                                }),
                                              )
                                          : '';
                                      }}>
                                      {' '}
                                      {item?.total_threats}
                                    </p>{' '}
                                    <span style={{ display: 'none', cursor: 'default', color: 'rgb(26, 32, 44)' }}>
                                      /{item?.total}
                                    </span>
                                  </div>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </ContentBox>
                </Grid>
              </Grid>
            </div>

            {/* 2nd row */}
            <Grid direction="row" spacing="16px" container>
              <TopRiskRecommendation
                data={top3RiskRecommendations?.data}
                isFetchingTop3RiskRecommendations={isFetchingTop3RiskRecommendations}
                cloudProvider={cloudProvider}
              />
              <InternetExposedResources
                data={internetExposedResources?.data}
                isFetchingInternetExposedResources={isFetchingInternetExposedResources || loading}
                cloudProvider={cloudProvider}
              />

              <CQShieldPriority
                cloudProvider={cloudProvider}
                selectedValue={selectedValue}
                dateFilter={
                  timeFilter
                    ? timeFilter === '24h'
                      ? '24hours'
                      : timeFilter.replace('d', ' days').replace(/ /g, '')
                    : '24hours'
                }
              />
            </Grid>

            <Grid container spacing={1.7}>
              <Grid item md={9}>
                <ThreatExposed
                  cloudProvider={cloudProvider}
                  dateFilter={
                    timeFilter
                      ? timeFilter === '24h'
                        ? '24hours'
                        : timeFilter.replace('d', ' days').replace(/ /g, '')
                      : '24hours'
                  }
                />{' '}
              </Grid>

              <Grid item md={3}>
                <InventoryComponent
                  cloudProvider={cloudProvider}
                  selectedValue={selectedValue}
                  dateFilter={
                    timeFilter
                      ? timeFilter === '24h'
                        ? '24hours'
                        : timeFilter.replace('d', ' days').replace(/ /g, '')
                      : '24hours'
                  }
                  regions={[]}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1.2}>
              <OpenThreatsAndAnamoly
                cloudProvider={cloudProvider}
                selectedValue={selectedValue}
                timezone={TIMEZONE}
                dateFilter={
                  timeFilter
                    ? timeFilter === '24h'
                      ? '24hours'
                      : timeFilter.replace('d', ' days').replace(/ /g, '')
                    : '24hours'
                }
              />

              <Grid item xs>
                <TopTriggered
                  cloudProvider={cloudProvider}
                  selectedValue={selectedValue}
                  dateFilter={
                    timeFilter
                      ? timeFilter === '24h'
                        ? '24hours'
                        : timeFilter.replace('d', ' days').replace(/ /g, '')
                      : '24hours'
                  }
                />
              </Grid>
            </Grid>

            {/* 4th row */}
            <RiskPosture
              cloudProvider={cloudProvider}
              identitySecurityPosture={identitySecurityPosture}
              isFetchingIdentitySecurityPosture={isFetchingIdentitySecurityPosture || loading}
            />

            {/* 5th row */}
            <Grid container spacing={1.7}>
              <Grid item xs={7.8}>
                <Vulnerabilities
                  data={vulnerabilities?.data}
                  isFetchingVulnerabilities={isFetchingVulnerabilities || loading}
                  cloudProvider={cloudProvider}
                />
              </Grid>
              <Grid item xs>
                <CompliancePosture
                  data={compliancePosture?.data}
                  isFetchingCompliancePosture={isFetchingCompliancePosture || loading}
                  cloudProvider={cloudProvider}
                />
              </Grid>
            </Grid>

            {/* 6th row */}
            <Grid container spacing={1.7}>
              <Grid item xs={7.8}>
                <Regions
                  allRegionsData={regions?.data}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  isFetchingRegions={isFetchingRegions || loading}
                  cloudProvider={cloudProvider}
                />
              </Grid>
              <Grid item xs>
                <ResourceTrend
                  data={resourceTrend?.data}
                  isFetchingResourceTrend={isFetchingResourceTrend || loading}
                  cloudProvider={cloudProvider}
                />
              </Grid>
            </Grid>
            {/* 7th row */}
            <PrioritizationFunnel
              data={prioritizationFunnel}
              isFetchingPrioritizationFunnel={isFetchingPrioritizationFunnel || loading}
              cloudProvider={cloudProvider}
            />
          </Grid>
        </PageContent>
      </PageContainer>
    </>
  );
};

// export default connect(mapDispatchToProps)(DashboardNew);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardNew);

// export default DashboardNew;
