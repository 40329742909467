import { Grid, Skeleton, Box } from '@mui/material';
import ContentBox from '@root/components/v2/content/ContentBox';
import './components.scss';

import NoData from '@root/components/common/NoData';
import fingerPrintLogo from '@root/assets/svg/Identity_Security_Posture/finger_print.svg';
import docIcon from '@root/assets/svg/Identity_Security_Posture/doc_search.svg';
// import whatIcon from '@root/assets/svg/Identity_Security_Posture/what_sign.svg';
import keyIcon from '@root/assets/svg/Identity_Security_Posture/red_key.svg';
import userIcon from '@root/assets/svg/Identity_Security_Posture/red_user.svg';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';

const RiskPosture = ({ cloudProvider, identitySecurityPosture, isFetchingIdentitySecurityPosture }) => {
  const history = useHistory();

  const data = [
    {
      resources_count: 65,
      severity: 'critical',
      name: 'Admin Users',
      rule_description: 'Users with Administrator access in your environment',
      cardName: 'adminRiskUsers',
      breadcrumbTitle: 'Admin Users',
    },
    {
      resources_count: 8,
      severity: 'critical',
      name: 'Inactive Users with Environment Access',
      rule_description: 'Disabling access for inactive users reduces risk of unauthorized access',
      cardName: 'inactiveUsersWithConsoleAccess',
      breadcrumbTitle: 'Inactive Users with Environment Access',
    },
    {
      resources_count: 91,
      severity: 'high',
      name: 'Users with Privilege Escalation Risk',
      rule_description: 'Privilege escalation allows illicit access to elevated permissions',
      cardName: 'UsersWithPrivilegedEscalationRisk',
      breadcrumbTitle: 'Users With Privilege Escalation Risk',
    },
    {
      resources_count: 75,
      severity: 'medium',
      name: 'Over Privileged Users',
      rule_description: 'Over privileged identities represent unnecessary access to your cloud resources',
      cardName: 'overPrivilegedUsers',
      breadcrumbTitle: 'Over Privileged Users',
    },
    {
      resources_count: 68,
      severity: 'critical',
      name: 'Admin Roles',
      rule_description: 'Roles with Administrator access in your environment',
      cardName: 'adminRiskRoles',
      breadcrumbTitle: 'Admin Roles',
    },
    {
      resources_count: 70,
      severity: 'low',
      name: 'Inactive Roles',
      rule_description: 'Disabling access for inactive users reduces risk of unauthorized access',
      cardName: 'inactiveRoles',
      breadcrumbTitle: 'Inactive Roles',
    },
    // {
    //   resources_count: 74,
    //   severity: 'medium',
    //   name: 'Inactive Policies',
    //   rule_description: 'Removed Inactive policies to reduce risk of unnecessary permissions',
    //   cardName: 'effectiveAdminRisk',
    //   breadcrumbTitle: 'Inactive Policies',
    // },
    {
      resources_count: 75,
      severity: 'critical',
      name: 'Effective Admin Risk',
      rule_description: 'Identities With Effective Admin Risk',
      cardName: 'effectiveAdminRisk',
      breadcrumbTitle: 'Effective Admin Risk',
    },
    {
      resources_count: 75,
      severity: 'medium',
      name: 'Access Key Risk',
      rule_description: 'Access key rotation reduces accidental exposures and unauthorized access to resources',
      cardName: 'accessKeyRiskUsers',
      breadcrumbTitle: 'Users With Access Key Risk',
    },
  ];

  const [mfaEnabledUsers, setMfaEnabledUsers] = useState(0);
  const [accessEnableUsers, setAccessEnableUsers] = useState(0);
  const [rootUsers, setRootUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [, setTotalPolicies] = useState(0);
  const [totalRoles, setTotalRoles] = useState(0);
  const [totalPermissions, setTotalPermissions] = useState(0);
  const [privilegedUsers, setPrivilegedUsers] = useState(0);
  const [totalUnusedPermissionCount, setTotalUnusedPermissionCount] = useState(0);
  const [iamUsersWithoutMfa, setIamUsersWithoutMfa] = useState(0);
  const [overPrivilegedRoles, setOverPrivilegedRoles] = useState(0);
  const [usersLevelAccessKey, setUsersLevelAccessKey] = useState(0);
  const [, setAdminRiskRoles] = useState(0);
  const [, setAdminRiskUsers] = useState(0);

  useEffect(() => {
    if (identitySecurityPosture?.data) {
      identitySecurityPosture?.data.forEach(item => {
        if (Object.keys(item).includes('cardName')) {
          if (item?.cardName === 'rootUsers') setRootUsers(item?.count);
          else if (item?.cardName === 'mfaEnabledUsers') setMfaEnabledUsers(item?.count);
          else if (item?.cardName === 'accessEnableUsers') setAccessEnableUsers(item?.count);
          else if (item?.cardName === 'privilegedUsers') setPrivilegedUsers(item?.count);
          else if (item?.cardName === 'adminRiskRoles') setAdminRiskRoles(item?.count);
          else if (item?.cardName === 'adminRiskUsers') {
            setAdminRiskUsers(item?.count);
            // setTotalPermissions(item?.totalPermissionCount || 0);
          } else if (item?.cardName === 'totalUnusedPermissionCount') setTotalUnusedPermissionCount(item?.count);
          else if (item?.cardName === 'iamUsersWithoutMfa') setIamUsersWithoutMfa(item?.count);
          else if (item?.cardName === 'overPrivilegedRoles') setOverPrivilegedRoles(item?.count);
          else if (item?.cardName === 'usersLevelAccessKey') setUsersLevelAccessKey(item?.count);
          else if (item?.cardName === 'effectiveAdminRisk') setTotalPermissions(item?.totalPermissionCount || 0);
        } else {
          if (Object.keys(item).includes('totalUsers')) setTotalUsers(item?.totalUsers);
          else if (Object.keys(item).includes('totalPolicies')) setTotalPolicies(item?.totalPolicies);
          else if (Object.keys(item).includes('totalRoles')) setTotalRoles(item?.totalRoles);
        }
      });
    }
  }, [identitySecurityPosture]);

  const getCount = cardName => {
    if (identitySecurityPosture?.data?.length) {
      for (let i = 0; i < identitySecurityPosture?.data?.length; i++) {
        if (identitySecurityPosture?.data[i]?.cardName === cardName) {
          return identitySecurityPosture?.data[i]?.count;
        }
      }
    }
    return 0;
  };

  return (
    <Grid>
      <ContentBox
        title="Identity Risk Posture"
        titleSize="medium"
        titleContainerStyle={{
          // border: "0px",
          fontSize: '16px !important',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottom: '1px solid #CBD5E0',

          margin: '0px',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        containerClassName={'border-box-no-pad'}
        paddingMode="content">
        {cloudProvider === 'azure' ? (
          <NoData height={'442px'} />
        ) : isFetchingIdentitySecurityPosture ? (
          <Box position={'relative'} height={'100%'}>
            <Box
              height="135px"
              width="100%"
              display="flex"
              gap="15px"
              padding="10px"
              alignItems="center"
              justifyContent="start">
              <Box
                width="16.5%"
                minHeight="103px"
                borderRadius="5px"
                border="1px solid #CBD5E0"
                // borderRadius: '5px',
                // border: '1px solid #CBD5E0',
                padding="10px"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                gap="10px"

                // alignItems: 'center',
              >
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Skeleton width="150px" />
                    <Skeleton width="150px" />
                    <Skeleton width="150px" />
                  </Box>
                  <Box>
                    <Skeleton width="50px" />
                    <Skeleton width="50px" />
                    <Skeleton width="50px" />
                  </Box>
                </Box>
              </Box>
              {Array.from(new Array(5)).map((i, index) => (
                <Box
                  borderRadius="5px"
                  border="1px solid #CBD5E0"
                  key={index}
                  width="16.5%"
                  minHeight="103px"
                  // borderRadius: '5px',
                  // border: '1px solid #CBD5E0',
                  padding="10px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="start"
                  gap="10px"

                  // alignItems: 'center',
                >
                  <Box>
                    <Skeleton width="150px" />
                    <Skeleton width="150px" />
                    <Skeleton width="150px" />
                  </Box>
                </Box>
              ))}
            </Box>

            <Box width="100%" display="flex" gap="15px" padding="10px" alignItems="center" justifyContent="start">
              {Array.from(new Array(4)).map((i, index) => (
                <Grid
                  key={index}
                  flexWrap="row"
                  sx={{ padding: '15px', borderRadius: '5px', border: '1px solid #CBD5E0' }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}>
                    <Box
                      style={{
                        width: '54px',
                        height: '54px',
                        flexShrink: 0,
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Skeleton width="100%" height="150%" />
                    </Box>
                    <div
                      style={{
                        width: '392px',
                      }}>
                      <Skeleton />
                    </div>
                  </Box>
                </Grid>
              ))}
            </Box>
            <Box width="100%" display="flex" gap="15px" padding="10px" alignItems="center" justifyContent="start">
              {Array.from(new Array(4)).map((i, index) => (
                <Grid
                  key={index}
                  flexWrap="row"
                  sx={{ padding: '15px', borderRadius: '5px', border: '1px solid #CBD5E0' }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}>
                    <Box
                      style={{
                        width: '54px',
                        height: '54px',
                        flexShrink: 0,
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Skeleton width="100%" height="150%" />
                    </Box>
                    <div
                      style={{
                        width: '392px',
                      }}>
                      <Skeleton />
                    </div>
                  </Box>
                </Grid>
              ))}
            </Box>
            {/* </Box> */}

            {/* <Box display="flex" flexDirection="column">
                <Box width="100%" display="flex">
                  {Array.from(new Array(6)) .map((i, index) => (
                    <Box key={index} height="100px" flex = "1" margin="10px" >
                         <Skeleton />
                         <Skeleton />
                         <Skeleton />
                    </Box>
                  ))}
                </Box>
            {/* </Box> */}
          </Box>
        ) : (
          <Grid className="dashboard_component" sx={{ height: '100%', px: '15px', paddingBottom: '15px' }}>
            <div
              style={{
                height: '135px',
                width: '100%',
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
                justifyContent: 'start',
                fontFamily: 'Manrope',
                // flexWrap: 'wrap',
              }}>
              <div
                style={{
                  width: '16.5%',
                  minHeight: '113px',
                  // borderRadius: '5px',
                  // border: '1px solid #CBD5E0',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  gap: '10px',
                  // alignItems: 'center',
                  fontSize: '14px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <span
                    style={{
                      fontWeight: '600',
                    }}>
                    Root Account
                  </span>
                  <span>{rootUsers}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <span>MFA Enabled</span>
                  <span>{mfaEnabledUsers}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <span>Access Enabled</span>
                  <span>{accessEnableUsers}</span>
                </div>
              </div>
              <div
                className="identity_top_cards"
                onClick={() => {
                  history.push(
                    buildBreadcrumbUrl({
                      path: `/identity/users?privilegedUsers=true`,
                      breadcrumbs: [
                        BreadcrumbParts.Dashboard,
                        { title: 'Users', path: '/identity/users' },
                        { title: 'Privileged Users' },
                      ],
                    }),
                  );
                }}
                style={{
                  width: '16.5%',
                  minHeight: '113px',
                  borderRadius: '5px',
                  border: '1px solid #CBD5E0',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  gap: '5px',
                  // flexShrink: '1',
                  // overflow: 'auto',
                  // alignItems: 'center',
                  fontSize: '14px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '10px',
                  }}>
                  <img src={fingerPrintLogo} />
                  <div
                    style={{
                      fontWeight: '700',
                      color: '#484d56',
                      fontSize: '20px',
                    }}>
                    {privilegedUsers}
                  </div>
                </div>
                <div style={{ fontWeight: '700', color: '#484d56' }}>Privileged Users</div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '45px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      gap: '5px',
                    }}>
                    <img src={docIcon} />
                    <div
                      style={{
                        fontWeight: '600',
                      }}>
                      {totalUsers}
                    </div>
                  </div>
                  {/* <img src={whatIcon} /> */}
                </div>
              </div>
              <div
                className="identity_top_cards"
                onClick={() => {
                  history.push(
                    buildBreadcrumbUrl({
                      path: `/identity/users`,
                      breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Users' }],
                    }),
                  );
                }}
                style={{
                  width: '16.5%',
                  minHeight: '113px',
                  borderRadius: '5px',
                  border: '1px solid #CBD5E0',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  gap: '5px',
                  // alignItems: 'center',
                  fontSize: '14px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '10px',
                  }}>
                  <img src={keyIcon} />
                  <div
                    style={{
                      fontWeight: '700',
                      color: '#484d56',
                      fontSize: '20px',
                    }}>
                    {totalUnusedPermissionCount}
                  </div>
                </div>
                <div style={{ fontWeight: '700', color: '#484d56' }}>Unused Permissions</div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '45px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      gap: '5px',
                    }}>
                    <img src={docIcon} />
                    <div style={{ fontWeight: '600' }}>{totalPermissions}</div>
                  </div>
                  {/* <img src={whatIcon} /> */}
                </div>
              </div>
              <div
                className="identity_top_cards"
                onClick={() => {
                  history.push(
                    buildBreadcrumbUrl({
                      path: `/identity/users?iamUsersWithoutMfa=true`,
                      breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'IAM Users Without MFA' }],
                    }),
                  );
                }}
                style={{
                  width: '16.5%',
                  minHeight: '113px',
                  borderRadius: '5px',
                  border: '1px solid #CBD5E0',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  gap: '5px',
                  // alignItems: 'center',
                  fontSize: '14px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '10px',
                  }}>
                  <img src={keyIcon} />
                  <div
                    style={{
                      fontWeight: '700',
                      color: '#484d56',
                      fontSize: '20px',
                    }}>
                    {iamUsersWithoutMfa}
                  </div>
                </div>
                <Tooltip title={'IAM Users Without MFA'}>
                  <div className="SingleLine" style={{ fontWeight: '700', color: '#484d56' }}>
                    IAM Users Without MFA
                  </div>{' '}
                </Tooltip>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '45px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      gap: '5px',
                    }}>
                    <img src={docIcon} />
                    <div style={{ fontWeight: '600' }}>{totalUsers}</div>
                  </div>
                  {/* <img src={whatIcon} /> */}
                </div>
              </div>
              <div
                className="identity_top_cards"
                onClick={() => {
                  history.push(
                    buildBreadcrumbUrl({
                      path: `/identity/roles?overPrivilegedRoles=true`,
                      breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Over Privileged Roles' }],
                    }),
                  );
                }}
                style={{
                  width: '16.5%',
                  minHeight: '113px',
                  borderRadius: '5px',
                  border: '1px solid #CBD5E0',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  gap: '5px',
                  // alignItems: 'center',
                  fontSize: '14px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '10px',
                  }}>
                  <img src={fingerPrintLogo} />
                  <div
                    style={{
                      fontWeight: '700',
                      color: '#484d56',
                      fontSize: '20px',
                    }}>
                    {overPrivilegedRoles}
                  </div>
                </div>
                <div style={{ fontWeight: '700', color: '#484d56' }}>Over Privileged Roles</div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '45px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      gap: '5px',
                    }}>
                    <img src={docIcon} />
                    <div style={{ fontWeight: '600' }}>{totalRoles}</div>
                  </div>
                  {/* <img src={whatIcon} /> */}
                </div>
              </div>
              <div
                className="identity_top_cards"
                onClick={() => {
                  history.push(
                    buildBreadcrumbUrl({
                      path: `/identity/users?usersLevelAccessKey=true`,
                      breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'User Level Access Keys' }],
                    }),
                  );
                }}
                style={{
                  width: '16.5%',
                  minHeight: '113px',
                  borderRadius: '5px',
                  border: '1px solid #CBD5E0',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  gap: '5px',
                  // alignItems: 'center',
                  fontSize: '14px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '10px',
                  }}>
                  <img src={userIcon} />
                  <div
                    style={{
                      fontWeight: '700',
                      color: '#484d56',
                      fontSize: '20px',
                    }}>
                    {usersLevelAccessKey}
                  </div>
                </div>
                <Tooltip title={'User Level Access Keys'}>
                  <div className="SingleLine" style={{ fontWeight: '700', color: '#484d56' }}>
                    User Level Access Keys
                  </div>{' '}
                </Tooltip>
                <div style={{ fontWeight: '700', color: '#484d56' }}></div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '45px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      gap: '5px',
                    }}>
                    <img src={docIcon} />
                    <div style={{ fontWeight: '600' }}>{totalUsers}</div>
                  </div>
                  {/* <img src={whatIcon} /> */}
                </div>
              </div>
            </div>
            <div
              // container
              style={{
                // minHeight: '305px',
                width: '100%',
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontFamily: 'Manrope',
                flexWrap: 'wrap',
                flexGrow: '1',
              }}
              // sx={{
              //   display: 'flex',
              //   flexWrap: 'no-wrap',
              //   justifyContent: 'center',
              //   width: '100%',
              //   height: '100%',
              //     }}
            >
              <Grid container spacing={1.7}>
                {data.map(item => {
                  return (
                    <Grid item xs={3}>
                      <div
                        // sx={4}
                        className=" identity_top_cards identity_main_cards"
                        style={{ height: '100%' }}
                        onClick={() => {
                          if (
                            item?.cardName !== 'inactiveRoles' &&
                            item?.cardName !== 'effectiveAdminRisk' &&
                            item?.cardName !== 'adminRiskRoles'
                          ) {
                            history.push(
                              buildBreadcrumbUrl({
                                path: `/identity/users?${item?.cardName}=true`,
                                breadcrumbs: [BreadcrumbParts.Dashboard, { title: item?.breadcrumbTitle || '' }],
                              }),
                            );
                          } else if (item?.cardName === 'effectiveAdminRisk') {
                            history.push(
                              buildBreadcrumbUrl({
                                path: `/identity/overview/table?${item?.cardName}=true`,
                                breadcrumbs: [BreadcrumbParts.Dashboard, { title: item?.breadcrumbTitle || '' }],
                              }),
                            );
                          } else if (item?.cardName === 'inactiveRoles' || item?.cardName === 'adminRiskRoles') {
                            history.push(
                              buildBreadcrumbUrl({
                                path: `/identity/roles?${item?.cardName}=true`,
                                breadcrumbs: [BreadcrumbParts.Dashboard, { title: item?.breadcrumbTitle || '' }],
                              }),
                            );
                          }
                        }}>
                        <ContentBox
                          title={`${item.name}`}
                          titleSize="medium"
                          height="100%"
                          padding="0px"
                          titleContainerStyle={{
                            // border: "0px",
                            fontSize: '16px !important',
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            borderBottom: '1px solid #CBD5E0',

                            margin: '0px',
                            color: 'var(--keyinsight-text-color)',
                            // background: '#F7FAFC',
                            // backgroundColor: 'var(--keyinsight-box-header-background)',
                            padding: '15px',
                            borderTop:
                              item.severity === 'critical'
                                ? '4px solid #CD0404'
                                : item.severity === 'high'
                                ? ' 4px solid #EB6C6C'
                                : item.severity === 'medium'
                                ? ' 4px solid #FFA825'
                                : ' 4px solid #E8DF07',
                          }}
                          containerClassName={'border-box-no-pad'}
                          paddingMode="content">
                          <Grid sx={{ padding: '15px' }}>
                            <div
                              style={{
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                justifyContent: 'start',
                              }}>
                              <div
                                style={{
                                  backgroundColor: '#EDF2F7',
                                  width: '54px',
                                  height: '54px',
                                  flexShrink: 0,
                                  borderRadius: '5px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <p className="resource_count m-0">{getCount(item.cardName)}</p>
                              </div>
                              <div
                                style={{
                                  width: '400px',
                                }}>
                                <p className="recommendation_details m-0">{item.rule_description}</p>
                              </div>
                            </div>
                          </Grid>
                        </ContentBox>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
        )}
      </ContentBox>
    </Grid>
  );
};
export default RiskPosture;
