//#region Import Dump
import administration from './assets/svg/icons/new/Administration.svg';
import dashboard from './assets/svg/icons/new/Dashboard.svg';
import identity from './assets/svg/icons/new/Identity.svg';
import insights from './assets/svg/icons/new/Insights.svg';
import inventory from './assets/svg/icons/new/Inventory.svg';
import notification from './assets/svg/icons/new/Notification.svg';
import remediation from './assets/svg/icons/new/Remediation.svg';
import reports from './assets/svg/icons/new/Report.svg';
import risk from './assets/svg/icons/new/Risk.svg';
import scan from './assets/svg/icons/new/Scan.svg';
import environment from './assets/svg/icons/new/administration/Environments.svg';
import rbac from './assets/svg/icons/new/administration/RBAC.svg';
import groups from './assets/svg/icons/new/identity/Groups.svg';
import overview from './assets/svg/icons/new/identity/Overview.svg';
import policies from './assets/svg/icons/new/identity/Policies.svg';
import roles from './assets/svg/icons/new/identity/Roles.svg';
import users from './assets/svg/icons/new/identity/Users.svg';
import compliance from './assets/svg/icons/new/insights/Compliance.svg';
import events from './assets/svg/icons/new/insights/Events.svg';
import exceptions from './assets/svg/icons/new/insights/Exceptions.svg';
import rules from './assets/svg/icons/new/insights/Rules.svg';
import key_insights from './assets/svg/icons/new/inventory/Insights.svg';
import inv_overview from './assets/svg/icons/new/inventory/Overview.svg';
import resources from './assets/svg/icons/new/inventory/Resources.svg';
import report from './assets/svg/icons/new/remediation/Reports.svg';

import administration_selected from './assets/svg/icons/new/selected/Adminstration.svg';
import compliance_selected from './assets/svg/icons/new/selected/Compliance.svg';
import dashboard_selected from './assets/svg/icons/new/selected/Dashboard.svg';
import environment_selected from './assets/svg/icons/new/selected/Environments.svg';
import events_selected from './assets/svg/icons/new/selected/Events.svg';
import exceptions_selected from './assets/svg/icons/new/selected/Exceptions.svg';
import groups_selected from './assets/svg/icons/new/selected/Groups.svg';
import identity_selected from './assets/svg/icons/new/selected/Identity.svg';
import insights_selected from './assets/svg/icons/new/selected/Insights.svg';
import inventory_selected from './assets/svg/icons/new/selected/Inventory.svg';
import key_insights_selected from './assets/svg/icons/new/selected/Key_Insights.svg';
import notification_selected from './assets/svg/icons/new/selected/Notification.svg';
import overview_selected from './assets/svg/icons/new/selected/Overview.svg';
import inv_overview_selected from './assets/svg/icons/new/selected/Overview_2.svg';
import policies_selected from './assets/svg/icons/new/selected/Policies.svg';
import rbac_selected from './assets/svg/icons/new/selected/RBAC.svg';
import remediation_selected from './assets/svg/icons/new/selected/Remediation.svg';
import reports_selected from './assets/svg/icons/new/selected/Report.svg';
import report_selected from './assets/svg/icons/new/selected/Reports.svg';
import resources_selected from './assets/svg/icons/new/selected/Resources.svg';
import risk_selected from './assets/svg/icons/new/selected/Risk.svg';
import roles_selected from './assets/svg/icons/new/selected/Roles.svg';
import rules_selected from './assets/svg/icons/new/selected/Rules.svg';
import scan_selected from './assets/svg/icons/new/selected/Scan.svg';
import users_selected from './assets/svg/icons/new/selected/Users.svg';

import enable_notifications from '@root/assets/svg/sidebar/notification_on_enabled.svg';
import cloud_account_dm from './assets/img/nav-icons/cloud_account_dm.png';
import compliance_dm from './assets/img/nav-icons/compliance_dm.png';
import dashboard_dm from './assets/img/nav-icons/dashboard_dm.png';
import insights_dm from './assets/img/nav-icons/insights_dm.png';
import notification_dm from './assets/img/nav-icons/notification_dm.png';
import reports_dm from './assets/img/nav-icons/reports_dm.png';
import risk_dm from './assets/img/nav-icons/risk_dm.png';
import rules_dm from './assets/img/nav-icons/rules_dm.png';
import settings_dm from './assets/img/nav-icons/settings_dm.png';
import user_logs_dm from './assets/img/nav-icons/user_logs_dm.png';
import new_label from './assets/img/newTag.svg';
import beta_label from './assets/img/BetaTag.svg';
import rbac_white from './assets/img/pages/rbacAssets/rbac-white.png';
import rmpolicies_icon from './assets/svg/icons/policies_icon.svg';
import rmreports_icon from './assets/svg/icons/reports_icon.svg';
//#endregion
import workloadScanIcon_selected from '@root/assets/svg/icons/new/selected/Workload-Scanning.svg';
import BucketProtection from '@root/assets/svg/malwareIcon/BucketProtection.svg';
import BucketProtectionLight from '@root/assets/svg/malwareIcon/BucketProtectionLight.svg';
import Findings from '@root/assets/svg/malwareIcon/Findings.svg';
import FindingsLight from '@root/assets/svg/malwareIcon/FindingsLight.svg';
import malware from '@root/assets/svg/malwareIcon/Malware.svg';
import malwareLight from '@root/assets/svg/malwareIcon/MalwareLight.svg';
import VulnerabilitiesIcon from '@root/assets/svg/sidebar/Vulnerabilities.svg';
import VulnerabilitiesIcon1 from '@root/assets/svg/sidebar/Vulnerabilities1.png';
import workloadScanIcon from '@root/assets/svg/sidebar/Workload Scanning.svg';
// import { AllEventsIcon } from './assets/svg/SVGComponents';
import {
  AllEventsIcon,
  AllEventsSelectedIcon,
  IdPIcon,
  IdPIconSelected,
  KubernetesNotSelected,
  KubernetesSelected,
  SuppressedEventsIcon,
  SuppressedEventsSelectedIcon,
} from './assets/svg/SVGComponents';
export const DashboardRoutes = {
  name: 'Dashboard',
  to: '/dashboard',
  exact: true,
  icon: dashboard,
  icon_selected: dashboard_selected,
  // icon_selected: dashboard_selected,
  icon_dm: dashboard_dm,
};

export const InsightsRoutes = {
  name: 'Insights',
  icon: insights,
  icon_selected: insights_selected,
  icon_dm: insights_dm,
  // icon_beta: new_label,

  children: [
    {
      name: 'Scan',
      to: '/scan-history',
      icon: scan,
      icon_selected: scan_selected,
      icon_dm: rules_dm,
    },
    {
      to: '/compliance',
      name: 'Compliance',
      icon: compliance,
      icon_selected: compliance_selected,
      icon_dm: compliance_dm,
    },
    // {
    //   to: '/rules',
    //   name: 'Rules',
    //   icon: rules,
    //   icon_selected: rules_selected,
    //   icon_dm: rules_dm,
    // },
    {
      to: '/rules',
      name: 'Rules',
      icon: rules,
      icon_selected: rules_selected,
      icon_dm: rules_dm,
    },
    {
      to: '/exceptions',
      name: 'Exceptions',
      icon: exceptions,
      icon_selected: exceptions_selected,
      icon_dm: user_logs_dm,
    },
  ],
};

export const IdentityRoutes = {
  name: 'Identity',
  icon: identity,
  icon_selected: identity_selected,
  icon_dm: insights_dm,
  icon_beta: new_label,
  children: [
    {
      to: '/identity/overview',
      name: 'Overview',
      icon: overview,
      icon_selected: overview_selected,
      icon_dm: compliance_dm,
    },
    {
      to: '/identity/users',
      name: 'Users',
      icon: users,
      icon_selected: users_selected,
      icon_dm: compliance_dm,
    },
    {
      to: '/identity/roles',
      name: 'Roles',
      icon: roles,
      icon_selected: roles_selected,
      icon_dm: compliance_dm,
    },
    {
      to: '/identity/policies',
      name: 'Policies',
      icon: policies,
      icon_selected: policies_selected,
      icon_dm: compliance_dm,
    },
    {
      to: '/identity/groups',
      name: 'Groups',
      icon: groups,
      icon_selected: groups_selected,
      icon_dm: compliance_dm,
    },
    {
      to: '/identity/idp',
      name: 'IdP',
      icon: <IdPIcon />,
      icon_selected: <IdPIconSelected />,
      icon_dm: <IdPIcon />,
    },
  ],
};
export const Malware = {
  name: (
    <>
      Data Security <img src={new_label} style={{ left:'84px' }} />
    </>
  ),
  icon: malwareLight,
  icon_selected: malware,
  icon_dm: malwareLight,
  icon_beta: new_label,
  children: [
    {
      to: '/data-security/overview',
      name: 'Overview',
      icon: inv_overview,
      icon_selected: inv_overview_selected,
      icon_dm: inv_overview,
    },
    {
      to: '/data-security/findings',
      name: 'Findings',
      icon: FindingsLight,
      icon_selected: Findings,
      icon_dm: FindingsLight,
    },
    {
      to: '/data-security/insights',
      name: 'Insights',
      icon: BucketProtectionLight,
      icon_selected: BucketProtection,
      icon_dm: BucketProtectionLight,
    },
  ],
};
export const WorkloadScanRoutes = {
  name: 'Workload',
  icon: workloadScanIcon,
  icon_selected: workloadScanIcon_selected,
  icon_dm: workloadScanIcon,
  // icon_beta: new_label,
  children: [
    {
      to: '/vulnerabilities',
      name: 'Vulnerabilities',
      icon: VulnerabilitiesIcon,
      icon_selected: VulnerabilitiesIcon1,
      icon_dm: VulnerabilitiesIcon,
    },
  ],
};

export const KubernetesRoutes = {
  name: (
    <>
      Kubernetes Live <img src={beta_label} style={{  right: '-15px' }} />{' '}
    </>
  ),
  exact: true,
  icon: <KubernetesNotSelected />,
  icon_selected: <KubernetesSelected />,
  icon_dm: <KubernetesNotSelected />,
  children: [
    {
      to: '/kubernetes-live/overview',
      name: 'Overview',
      icon: inv_overview,
      icon_selected: inv_overview_selected,
    },
    {
      to: '/kubernetes-live/inventory',
      name: 'Inventory',
      icon: inventory,
      icon_selected: inventory_selected,
    },
    {
      to: '/kubernetes-live/identity',
      name: 'Identity',
      icon: identity,
      icon_selected: identity_selected,
    },
  ],
};
export const EventRoutes = {
  name: 'CDR',
  // to: '/events',
  icon: events,
  icon_selected: events_selected,
  icon_dm: notification_dm,
  children: [
    {
      to: '/events/overview',
      name: 'Overview',
      exact: true,
      icon: inv_overview,
      icon_selected: inv_overview_selected,
      icon_dm: rmreports_icon,
    },
    {
      to: '/events/all',
      name: 'All Events',
      exact: true,
      icon: <AllEventsIcon />,
      icon_selected: <AllEventsSelectedIcon />,
      icon_dm: rmreports_icon,
    },
    {
      to: '/events/suppressed',
      name: 'Action History',
      exact: true,
      icon: <SuppressedEventsIcon />,
      icon_selected: <SuppressedEventsSelectedIcon />,
      icon_dm: rmreports_icon,
    },
  ],
  // icon_beta: new_label,
};
export const OldEventRoutes = {
  to: '/old-events',
  name: 'Events',
  exact: true,
  icon: inv_overview,
  icon_selected: inv_overview_selected,
  icon_dm: notification_dm,
  // icon_beta: new_label,
};

export const RiskRoutes = {
  name: 'Risk',
  to: '/risk',
  exact: true,
  icon: risk,
  icon_selected: risk_selected,
  icon_dm: risk_dm,
};
export const ReportsRoutes = {
  name: 'Reports',
  to: '/reports',
  exact: true,
  icon: reports,
  icon_selected: reports_selected,
  icon_dm: reports_dm,
};

export const AdministrationRoutes = {
  name: 'Admin',
  icon: administration,
  icon_selected: administration_selected,
  icon_dm: settings_dm,
  children: [
    // {
    //   to: '/profile',
    //   name: 'Profile',
    //   icon: users,
    //   icon_selected: users_selected,
    //   icon_dm: compliance_dm,
    // },
    {
      to: '/environments',
      name: 'Environments',
      icon: environment,
      icon_selected: environment_selected,
      icon_dm: cloud_account_dm,
    },
    {
      to: '/rbac',
      name: 'RBAC',
      icon: rbac,
      icon_selected: rbac_selected,
      icon_dm: rbac_white,
    },
  ],
};
export const NotificationRoutes = {
  to: '/notifications',
  name: 'Alerts',
  icon: notification,
  icon_selected: notification_selected,
  icon_dm: enable_notifications,
};

export const RemediationRoutes = {
  name: 'Remediation Hub',
  icon: remediation,
  icon_selected: remediation_selected,
  icon_dm: settings_dm,
  children: [
    {
      to: '/remediation-policy',
      name: 'Policies',
      icon: policies,
      icon_selected: policies_selected,
      icon_dm: rmpolicies_icon,
    },
    {
      to: '/remediation-report',
      name: 'Reports',
      icon: report,
      icon_selected: report_selected,
      icon_dm: rmreports_icon,
    },
  ],
};

export const InventoryRoutes2 = {
  name: 'Inventory',
  icon: inventory,
  icon_selected: inventory_selected,
  icon_dm: settings_dm,
  // icon_beta: new_label,
  children: [
    {
      to: '/inventory/overview',
      name: 'Overview',
      icon: inv_overview,
      icon_selected: inv_overview_selected,
      icon_dm: rmreports_icon,
    },
    {
      to: '/inventory/resources',
      name: 'Resources',
      icon: resources,
      icon_selected: resources_selected,
      icon_dm: rmpolicies_icon,
    },
    {
      to: '/inventory/key-insight',
      name: 'Key Insights',
      icon: key_insights,
      icon_selected: key_insights_selected,
      icon_dm: rmreports_icon,
    },
  ],
};

export const ExplorerRoutes = {
  to: '/explorer',
  name: (
    <>
      Explorer
      <img src={new_label} style={{ right: '-24px' }} /> {/* </span> */}
    </>
  ),
  icon: inv_overview,
  icon_selected: inv_overview_selected,
  icon_dm: rmreports_icon,
};

export default [
  DashboardRoutes,
  InventoryRoutes2,
  // InventoryRoutes,
  // VisualiserRoutes,
  InsightsRoutes,
  Malware,
  IdentityRoutes,
  WorkloadScanRoutes,

  // ScanRoutes,
  EventRoutes,
  // OldEventRoutes,
  KubernetesRoutes,
  RemediationRoutes,
  RiskRoutes,
  // ReportsRoutes,
  AdministrationRoutes,
  NotificationRoutes,
  ExplorerRoutes,
];
