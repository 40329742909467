import { useEffect, useState } from 'react';
import './sideoverlay.scss';
// import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
// import SelectedValues from './SelectedValues';
// import FilterItem from './FilterItem';
import { aws_light_svg } from '@root/components/utilities/SVGIconsUtils';

import { toast } from 'react-toastify';
// import aws_lambda from '@root/assets/img/aws_services/aws-lambda.svg';

// import './style.scss';
import atlassian_jira_logo_icon from '@assets/svg/atlassian_jira_logo_icon.svg';
import { Box, Grid } from '@mui/material';
import AWSServiceIcons from '@root/components/awsServiceIcons';
import _ from 'lodash';
// import { json } from 'react-syntax-highlighter/dist/esm/languages/prism';

import CircularCloseButton from '@root/components/v2/ui-elements/CircularCloseButton';
import { axiosPost } from '@root/services/axios/axiosPost';
// import { space } from 'is_js';
// import ToolTip from '../inventory-overview/resources/Tooltip';
import criticalIcon from '@root/assets/svg/Compliance_V1/Critical.svg';
import highIcon from '@root/assets/svg/Compliance_V1/High.svg';
import lowIcon from '@root/assets/svg/Compliance_V1/Low.svg';
import mediumIcon from '@root/assets/svg/Compliance_V1/Medium.svg';
import info from '@root/assets/svg/workload/info.svg';
import question1 from '@root/assets/svg/workload/question1.png';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import { appendBreadcrumbsToUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import JiraConfiguration from './Modal/JiraConfiguration';
// import { appendBreadcrumbsToUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';
import gcp_icon from '@root/assets/img/header/cloud_providers/gcp.png';
import DescriptionToggle from './DescriptionToggle';
import LinkToggle from './LinkToggle';
// import EllipsisTooltip from '@root/components/v2/ui-elements/Tooltip/EllipsisTooltip';
// import HighIcon from '@root/components/svg/high';
// import CriticalIcon from '@root/components/svg/criticalIcon';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import LowIcon from '@root/components/svg/lowIcon';

const SideOverlay = props => {
  const { selectedRow, resourceData, onClose } = props;
  const [currentSubTab, setCurrentSubTab] = useState('Properties');
  const [currentTab, setCurrentTab] = useState('details');
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const newURL = new URL(window.location);
  const history = useHistory();
  const prevPath = '/vulnerabilities';
  const [jiraError, setJiraError] = useState('');
  const [existingJiraTicket, setExistingJiraTicket] = useState(null);

  console.log('selectedRowData', selectedRow);

  const user = useSelector(state => state.user);
  useEffect(() => {
    (async () => {
      const res = await axiosPost('jira/org_id', { currentUserEmail: user.info.user.email }, true);
      const projects = await axiosPost('jira/projects', { orgId: res?.data?.data?.orgId }, true);

      if (projects.data.error) {
        setJiraError(projects.data.msg);
      } else {
        setJiraError('');
      }

      const checkExisting = await axiosPost(
        'jira/issue-id',
        {
          orgId: res?.data?.data?.orgId,
          accountId: rowData.environment_id,
          customInfo: {
            resourceId: rowData.resource_id,
            resourceName: rowData.resource_name,
            resourceType: rowData.resource_type,
            region: rowData.region,
          },
        },
        true,
      );

      if (checkExisting?.data?.data?.ticketId) {
        setExistingJiraTicket(checkExisting?.data?.data?.ticketId);
      } else {
        setExistingJiraTicket('');
      }
    })();
  }, [rowData, user]);

  const jiraOnClick = item => {
    if (jiraError === null || existingJiraTicket === null) return;

    if (jiraError) {
      toast.error(jiraError);
    } else if (existingJiraTicket) {
      history.push('/notifications/jira?fromWorkload=true');
    } else {
      setRowData(item);
      setOpen(true);
    }
  };
  const showBoolean = value => {
    if (value === null || !value) return '-';
    return JSON.stringify(value);
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const data = [
    {
      title: 'Hackerone Count ',
      Description: selectedRow?.details.cve_details?.hackerone?.count ?? '-',
    },
    {
      title: 'Hackerone  Rank',
      Description: selectedRow?.details.cve_details?.hackerone?.rank ?? '-',
    },
    {
      title: 'Age (Days)',
      Description: selectedRow?.details.cve_details?.age_in_days ?? '-',
    },
    {
      title: 'Is Exploitable',
      Description: capitalizeFirstLetter(showBoolean(selectedRow?.details.cve_details?.is_exploited)),
    },
    {
      title: 'Is POC',
      Description: capitalizeFirstLetter(showBoolean(selectedRow?.details.cve_details?.is_poc)),
    },
    {
      title: 'Is OSS',
      Description: capitalizeFirstLetter(showBoolean(selectedRow?.details.cve_details?.is_oss)),
    },
    {
      title: 'Is Remote',
      Description: capitalizeFirstLetter(showBoolean(selectedRow?.details.cve_details?.is_remote)),
    },
    {
      title: 'Is Template',
      Description: capitalizeFirstLetter(showBoolean(selectedRow?.details.cve_details?.is_template)),
    },
    {
      title: 'Last Modified ',
      Description: selectedRow?.details.cve_details?.last_modified
        ? DateFormatPipe(selectedRow?.details.cve_details?.last_modified)
        : '--',
    },
    {
      title: 'Patch URL',
      Description: selectedRow?.details.References?.join(', '),
    },
    {
      title: 'Vendor Advisory',
      Description: selectedRow?.details.cve_details?.vendor_advisory ?? '-',
    },
    {
      title: 'Vulerability Status',
      Description: selectedRow?.details?.cve_details?.vuln_status
        ? capitalizeFirstLetter(selectedRow?.details?.cve_details?.vuln_status)
        : '--',
    },
    {
      title: 'Weakness',
      // Description: [
      //   { cwe_id: 'qwe', cwe_name: 'fmdnvdmn' },
      //   { cwe_id: 'qwe', cwe_name: 'fmdnvdmn' },
      // ],
      Description: selectedRow?.details.cve_details?.weaknesses ?? '-',
    },
  ];

  console.log('data', data);
  return (
    <div className="sidebarOpen2">
      <div className="Resources__DataSidebar2">
        <div className="Resources__SidebarHeader">
          <div style={{ display: 'flex', padding: '18px 20px 18px 20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* <AWSServiceIcons name={selectedRow.service} /> */}
              <span
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '4px',
                  backgroundColor: '#F84D4D',
                  marginRight: '10px',
                }}></span>
              <span
                style={{
                  marginLeft: '10px',
                  fontFamily: 'Manrope',
                  fontWeight: 700,
                  fontSize: '16px',
                  color: '#1A202C',
                  lineHeight: 1,
                }}>
                {selectedRow.cve_id}
              </span>
            </div>
            <div style={{ flexGrow: '1' }} />
            <CircularCloseButton onClick={() => onClose()} />
          </div>
          <div
            style={{ fontFamily: 'Manrope', fontWeight: 600, fontSize: '16px', color: '#767980', paddingLeft: '20px' }}>
            {selectedRow.service}
          </div>
          {/* <div className="Resources__SidebarTags" style={{ display: 'flex', padding: '17px 20px' }}>
            <div>Compute</div>
           
          </div> */}
          <div style={{ display: 'flex', borderTop: '1px solid #CBD5E0' }}>
            <div className="Resources__SidebarTabs" style={{ display: 'flex' }}>
              <div onClick={() => setCurrentTab('details')} className={currentTab === 'details' ? 'selectedTab' : ''}>
                {' '}
                Details
              </div>
              <div
                onClick={() => setCurrentTab('resources')}
                className={currentTab === 'resources' ? 'selectedTab' : ''}>
                Resource
              </div>
            </div>
            {currentTab === 'resources' ? (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '157px' }}>
                <span
                  style={{
                    fontFamily: 'Manrope',
                    fontWeight: 700,
                    fontSize: '16px',
                    color: '#484D56',
                    lineHeight: 1,
                    marginRight: '6px',
                    display: 'none',
                  }}>
                  117
                </span>
                <span
                  style={{
                    fontFamily: 'Manrope',
                    fontSize: '16px',
                    color: '#484D56',
                    fontWeight: 500,
                    lineHeight: 1,
                    display: 'none',
                  }}>
                  Resources
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {/* content */}

        <div
          className="Resources__SidebarContent CustomScrollbar"
          style={currentTab == 'details' ? { height: 'calc(100% - 110px)' } : {}}>
          {currentTab == 'details' && (
            <>
              {' '}
              <div className="Resources__SidebarSubTabs" style={{ display: 'flex', borderBottom: '1px solid #CBD5E0' }}>
                <Box
                  onClick={() => setCurrentSubTab('Properties')}
                  className={currentSubTab === 'Properties' ? 'Resources__SelectedSubTab' : ''}>
                  Vulnerability Description
                </Box>
                <Box
                  onClick={() => setCurrentSubTab('Issues')}
                  className={currentSubTab === 'Issues' ? 'Resources__SelectedSubTab' : ''}>
                  References
                </Box>
              </div>
              {currentSubTab === 'Properties' ? (
                <>
                  <DescriptionToggle text={selectedRow.threat_trend_data.Description} />
                  {/* <p
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      color: '#767980',
                      padding: '15px 20px 20px 20px',
                      borderBottom: '1px solid #CBD5E0',
                    }}>
                    {selectedRow.threat_trend_data.Description}
                  </p> */}
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">ID</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <p style={{ fontFamily: 'Manrope', fontSize: '14px', color: '#194BFB' }}>{selectedRow.cve_id}</p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">CVSS Severity</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <p className="description">
                        <Tooltip title={selectedRow.severity}>
                          {selectedRow.severity === 'HIGH' ? (
                            // <HighIcon textDisabled={true} />
                            <img src={highIcon} />
                          ) : selectedRow.severity === 'CRITICAL' ? (
                            // <CriticalIcon textDisabled={true} />
                            <img src={criticalIcon} />
                          ) : selectedRow.severity === 'MEDIUM' ? (
                            // <MediumIcon textDisabled={true} />
                            <img src={mediumIcon} style={{ width: '88px' }} />
                          ) : selectedRow.severity === 'LOW' ? (
                            // <LowIcon textDisabled={true} />
                            <img src={lowIcon} />
                          ) : selectedRow.cvss_severity === 'INFORMATIONAL' ? (
                            <img
                              src={info}
                              style={{
                                height:
                                  selectedRow.cvss_severity === 'INFORMATIONAL' ||
                                  selectedRow.cvss_severity === 'UNTRIAGED'
                                    ? '30px'
                                    : '',
                                width:
                                  selectedRow.cvss_severity === 'INFORMATIONAL' ||
                                  selectedRow.cvss_severity === 'UNTRIAGED'
                                    ? '30px'
                                    : '',
                              }}
                            />
                          ) : (
                            <img
                              src={question1}
                              style={{
                                height:
                                  selectedRow.cvss_severity === 'INFORMATIONAL' ||
                                  selectedRow.cvss_severity === 'UNTRIAGED'
                                    ? '30px'
                                    : '',
                                width:
                                  selectedRow.cvss_severity === 'INFORMATIONAL' ||
                                  selectedRow.cvss_severity === 'UNTRIAGED'
                                    ? '30px'
                                    : '',
                              }}
                            />
                          )}
                        </Tooltip>
                        <img
                          src={
                            selectedRow.priorit_score_dif === '0' ? (
                              ''
                            ) : selectedRow.priorit_score_dif === '1' ? (
                              <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                                  fill="#F72121"
                                />
                              </svg>
                            ) : selectedRow.priorit_score_dif === '-1' ? (
                              <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M9.58711 3.76592L5.98011 0.382145C5.43697 -0.127382 4.5596 -0.127382 4.01646 0.382144L0.409459 3.76592C-0.467918 4.589 0.15878 6 1.39825 6L8.61224 6C9.85171 6 10.4645 4.589 9.58711 3.76592Z"
                                  fill="#03BB34"
                                />
                              </svg>
                            ) : (
                              ''
                            )
                          }
                        />
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">CVSS Score</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <p className="description">
                        {' '}
                        <span
                          style={{
                            backgroundColor: '#E2E8F0',
                            borderRadius: '50%',
                            width: 'auto',
                            minWidth: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '14px',
                            fontFamily: 'Manrope',
                            fontWeight: 600,
                            color: '#484D56',
                            border: '1px solid #CBD5E0',
                          }}>
                          {selectedRow['cvss_score'] === 0 ? 0 : parseFloat(selectedRow['cvss_score']).toFixed(1)}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">Vector</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <Tooltip title={selectedRow?.details?.Vector ?? ''}>
                        <p className="description overflow-description">{selectedRow?.details?.Vector ?? '-'}</p>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">Packages</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <Tooltip title={selectedRow?.details?.Packages ?? ''}>
                        <p className="description overflow-description">{selectedRow?.details?.Packages ?? '-'}</p>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">EPSS Score</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <p className="description">{selectedRow.threat_trend?.epss}</p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">CISA Score</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <p className="description">{selectedRow.threat_trend?.cisa}</p>
                    </Grid>
                  </Grid>
                  {data.map(item => (
                    <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                      <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                        <p className="title">{item.title}</p>
                      </Grid>
                      <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                        {item.title === 'Weakness' ? (
                          Array.isArray(item.Description) ? (
                            <ul style={{ paddingLeft: 0, marginBottom: 0, listStyleType: 'none' }}>
                              {item.Description?.map(details => (
                                <li>
                                  <div style={{ display: 'flex', gap: '10px' }}>
                                    <p
                                      className="description"
                                      style={{
                                        color: '#F62121',
                                      }}>
                                      {details?.cwe_id}
                                    </p>
                                    :
                                    <Tooltip title={details?.cwe_name}>
                                      <p
                                        className="description overflow-description"
                                        style={{
                                          color: '#F62121',
                                        }}>
                                        {details?.cwe_name}
                                      </p>
                                    </Tooltip>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="description">{item.Description}</p>
                          )
                        ) : item.title === 'Patch URL' || item.title === 'Vendor Advisory' ? (
                          <LinkToggle links={item.Description} />
                        ) : (
                          // item.Description.split(',').map((link, index) => (
                          //   <Tooltip title={link.trim()}>
                          //     <a
                          //       key={index}
                          //       target="_blank"
                          //       href={link.trim()}
                          //       className="description overflow-description"
                          //       style={{ color: 'rgb(25, 75, 251)', display: 'block', margin: '5px 0' }}>
                          //       {link.trim()}
                          //     </a>
                          //   </Tooltip>
                          // ))
                          <p
                            className="description"
                            // style={{ color: 'rgb(25, 75, 251)' }}
                          >
                            {item.Description}
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  {/* <div className="Resources__SidebarSubTabs" style={{ display: 'flex' }}>
                    <Box
                      //   onClick={() => setCurrentSubTab('Properties')}
                      className={'Resources__SelectedSubTab'}
                      style={{ color: '#194bfb' }}>
                      Exploits
                    </Box>
                  </div> */}
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">First Seen</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <Tooltip title={'UTC+5:30'}>
                        <p className="description"> {DateFormatPipe(selectedRow?.first_seen)}</p>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">Last Updated</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <Tooltip title={'UTC+5:30'}>
                        <p className="description"> {DateFormatPipe(selectedRow?.last_seen)}</p>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">OWASP Top 10</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <p className="description">
                        {' '}
                        {selectedRow?.details['OWASP Top 10'] === false ? 'False' : 'True'}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                    <Grid item sx={{ minWidth: '185px', px: '20px' }}>
                      <p className="title">SANS Top 25</p>
                    </Grid>
                    <Grid item sx={{ textAlign: 'right', px: '20px' }}>
                      <p className="description"> {selectedRow?.details['SANS Top 25'] === false ? 'False' : 'True'}</p>
                    </Grid>
                  </Grid>

                  {/* {extraColumns.map(item => {
              return (
                <div className="Resources__SidebarItem">
                  <div className="Resources__SidebarItemTitle">{item?.colName}</div>
                  <div className="Resources__SidebarItemSubTitle">{selectedRow[item?.colKey.toLowerCase()]}</div>
                </div>
              );
            })} */}
                </>
              ) : currentSubTab === 'Issues' ? (
                <>
                  <div style={{ marginTop: '10px' }}>
                    {selectedRow.details.References.map(item => (
                      <Grid sx={{ px: '20px' }}>
                        <a href={item} _target="_blank" className="title" style={{ color: '#194BFB' }}>
                          {item}
                        </a>
                      </Grid>
                    ))}
                  </div>
                </>
              ) : null}
            </>
          )}
          {currentTab === 'resources' && (
            <>
              <Grid container sx={{ borderBottom: '1px solid #CBD5E0', p: '13px 20px 13px 20px' }}>
                <Grid item sx={{ width: '83px' }}>
                  <p
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      color: '#484D56',
                      marginBottom: '0',
                      lineHeight: 1,
                      fontWeight: 500,
                    }}>
                    Type
                  </p>
                </Grid>
                <Grid item sx={{ width: '181px' }}>
                  <p
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      color: '#484D56',
                      marginBottom: '0',
                      lineHeight: 1,
                      fontWeight: 500,
                    }}>
                    Name
                  </p>
                </Grid>

                <Grid item sx={{ width: '160px' }}>
                  <p
                    style={{
                      fontFamily: 'Manrope',
                      fontSize: '14px',
                      color: '#484D56',
                      marginBottom: '0',
                      lineHeight: 1,
                      fontWeight: 500,
                    }}>
                    CQ Shield Priority
                  </p>
                </Grid>
              </Grid>
              <>
                {(resourceData?.data?.data || []).map((item, index) => {
                  return (
                    <Grid container sx={{ borderBottom: '1px solid #CBD5E0', p: '15px 21px' }}>
                      <Grid
                        key={index}
                        item
                        sx={{ width: '79px', display: 'flex', alignItems: 'center', paddingLeft: '4px' }}>
                        {/* {item.cloud_provider === 'gcp' ? (
                          <AWSServiceIcons
                            name={'gcp' + item.service.replace('AWS', '').replace(/ +/g, '').toLowerCase()}
                            tooltip={true}
                          />
                        ) : (
                          <AWSServiceIcons
                            key={index}
                            name={[item.service]}
                            multiple={true}
                            showName={false}
                            tooltip={true}
                          />
                         
                        )} */}
                        <Tooltip title={item.service}>
                          {' '}
                          <AWSServiceIcons name={item.service} />
                        </Tooltip>
                      </Grid>
                      <Grid key={index} item sx={{ width: '181px', paddingRight: '29px' }}>
                        <Tooltip key={index} title={item.resource_id}>
                          <a
                            onClick={() => {
                              newURL.pathname = '/inventory/resources-table';
                              newURL.searchParams.append('id', item.resource_id);

                              newURL.searchParams.append('resource_type', item.resource_type);
                              history.push(
                                appendBreadcrumbsToUrl({
                                  path: `${newURL.pathname}${newURL.search}`,
                                  breadcrumbs: [
                                    {
                                      title: 'Resources',
                                    },
                                  ],
                                }),
                                { prevPath },
                              );
                              // history.go(0);
                            }}
                            className="SingleLine"
                            style={{
                              fontFamily: 'Manrope',
                              fontSize: '14px',
                              fontWeight: '500',
                              marginBottom: '12px !important',

                              lineHeight: 1,
                              color: '#194BFB',
                              cursor: 'pointer',
                              // marginBottom: '1rem',
                              display: 'inline-block',
                              width: '151px',
                            }}>
                            {item.resource_id}
                          </a>
                        </Tooltip>

                        <div
                          style={{
                            fontFamily: 'Manrope',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#767980',
                            marginBottom: '0',
                            lineHeight: 1,
                            marginTop: '.5rem',
                            display: 'flex',
                          }}>
                          <img
                            src={item.cloud_provider === 'gcp' ? gcp_icon : aws_light_svg}
                            style={{ width: '23px', marginRight: '10px' }}
                          />
                          <Tooltip title={item.environment_id}>
                            <div style={{ width: '100px' }} className="SingleLine">
                              {item.environment_id}
                            </div>
                          </Tooltip>
                        </div>
                      </Grid>

                      <Grid key={index} item sx={{ width: '118px', display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={'Click here to see the trend'}>
                          {item.priority_score_severity === 'high' ? (
                            // <HighIcon textDisabled={true} pointer={true} />
                            <img src={highIcon} />
                          ) : item.priority_score_severity === 'critical' ? (
                            // <CriticalIcon textDisabled={true} pointer={true} />
                            <img src={criticalIcon} />
                          ) : item.priority_score_severity === 'medium' ? (
                            // <MediumIcon textDisabled={true} pointer={true} />
                            <img src={mediumIcon} style={{ width: '88px' }} />
                          ) : (
                            // <LowIcon textDisabled={true} pointer={true} />
                            <img src={lowIcon} />
                          )}
                        </Tooltip>

                        {item?.priorit_score_dif === '0' ? (
                          ''
                        ) : item?.priorit_score_dif === '1' ? (
                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                              fill="#F72121"
                            />
                          </svg>
                        ) : item?.priorit_score_dif === '-1' ? (
                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M9.58711 3.76592L5.98011 0.382145C5.43697 -0.127382 4.5596 -0.127382 4.01646 0.382144L0.409459 3.76592C-0.467918 4.589 0.15878 6 1.39825 6L8.61224 6C9.85171 6 10.4645 4.589 9.58711 3.76592Z"
                              fill="#03BB34"
                            />
                          </svg>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid
                        key={index}
                        item
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexGrow: '1',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}>
                        <Tooltip
                          title={
                            existingJiraTicket !== null && existingJiraTicket
                              ? 'Click here to view Jira ticket'
                              : 'Click here to create Jira ticket'
                          }>
                          <img
                            src={atlassian_jira_logo_icon}
                            style={{ width: '20px', height: '20px' }}
                            onClick={() => jiraOnClick(item)}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                })}
                <JiraConfiguration open={open} setOpen={setOpen} rowData={rowData} resourceData={resourceData} />
              </>
            </>
          )}
        </div>
        <div style={{ display: 'flex !important', flexGrow: '1 !important' }}></div>
        {currentTab === 'resources' && (
          <>
            <Grid
              sx={{ display: 'flex', justifyContent: 'end', marginRight: '21px', alignItems: 'flex-end', my: '3rem',  }}>
              <Button
                onClick={() => {
                  newURL.pathname = '/inventory/resources-table';
                  newURL.searchParams.append('cve_id', selectedRow.cve_id);

                  history.push(`${newURL.pathname}${newURL.search}`, { prevPath });
                  // history.go(0);
                }}
                disabled={_.isEmpty(resourceData?.data?.data)}
                style={{
                  background: '#194BFB',
                  fontFamily: 'Manrope',
                  padding: '10px 18px',
                  fontSize: '14px',
                  fontWeight: 600,
                }}>
                <span style={{ paddingRight: '10px' }}> Show in Resources Lists </span>
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.28217 1.6338L7.6592 4.0048L5.28217 6.3662C5.19271 6.45507 5.12175 6.56058 5.07333 6.67669C5.02492 6.79281 5 6.91726 5 7.04294C5 7.16862 5.02492 7.29308 5.07333 7.40919C5.12175 7.52531 5.19271 7.63081 5.28217 7.71968C5.37163 7.80856 5.47783 7.87905 5.59472 7.92715C5.7116 7.97524 5.83688 8 5.96339 8C6.08991 8 6.21519 7.97524 6.33207 7.92715C6.44895 7.87905 6.55516 7.80856 6.64462 7.71968L9.71737 4.67674C10.0942 4.30237 10.0942 3.69762 9.71737 3.32326L6.64462 0.280314C6.46395 0.100831 6.2189 -2.71837e-07 5.96339 -2.60668e-07C5.70789 -2.495e-07 5.46284 0.100831 5.28217 0.280314C5.1015 0.459798 5 0.70323 5 0.957057C5 1.21088 5.1015 1.45432 5.28217 1.6338Z"
                    fill="white"
                  />
                  <path
                    d="M0.282171 1.6338L2.6592 4.0048L0.282171 6.3662C0.192712 6.45507 0.121749 6.56058 0.0733337 6.67669C0.0249186 6.79281 -4.54429e-08 6.91726 -4.18344e-08 7.04294C-3.82258e-08 7.16862 0.0249186 7.29308 0.0733337 7.40919C0.121749 7.52531 0.192712 7.63081 0.282171 7.71968C0.371631 7.80856 0.477835 7.87905 0.594719 7.92715C0.711603 7.97525 0.83688 8 0.963394 8C1.08991 8 1.21518 7.97525 1.33207 7.92715C1.44895 7.87905 1.55516 7.80856 1.64462 7.71968L4.71737 4.67674C5.09421 4.30237 5.09421 3.69762 4.71737 3.32326L1.64462 0.280314C1.46395 0.100831 1.2189 -5.32799e-08 0.963394 -4.21113e-08C0.707886 -3.09427e-08 0.462843 0.100831 0.282171 0.280314C0.1015 0.459798 -3.18952e-07 0.70323 -3.07857e-07 0.957057C-2.96762e-07 1.21089 0.1015 1.45432 0.282171 1.6338Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};
export default SideOverlay;
