import AWSServiceIcons from '@components/awsServiceIcons';
import EditModal from './editModal';
import RemediationPolicyMenuButton from './menu/menuButton';
import RevokeModal from './revokeModal';

import CountTooltipW from '@root/components/Tooltip/CountTooltip';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';
import './policies-columns.scss';

import AWSServiceCategoryIcons from '@root/components/awsServiceCategoryIcons';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import { RiskTypeIcons } from '@root/pipes/riskTypeIcons';
import EnvCellComponent from '../../remediation-report/report/env-cell';

export const makeColumns = ({ onPolicyRevoked, isRevokeModalOpen, setRevokeModalOpen }) => {
  return [
    {
      field: 'environment',
      width: 232,
      renderHeader: () => <span>Environment(ID/Tag)</span>,
      classes: 'align-middle',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        // if (cell === "-") {
        //   return <NoResourcePrompt />;
        // }
        // return cell;
        return (
          <EnvCellComponent
            cloud={params.row.cloud_provider}
            environmentName={''}
            environmentId={params.row.environment_id}
            environmentTags={params.row.environment_tags}
          />
        );
      },
      sortFunc: (a, b, order) => {
        a = String(a).toString();
        b = String(b).toString();
        if (a.trim().length < 1) {
          return 1;
        }
        let first = a?.trim().charAt(0);
        let second = b?.trim().charAt(0);
        if (order === 'asc') {
          return first == second ? 0 : first > second ? 1 : -1;
        }
        return first == second ? 0 : first < second ? 1 : -1; // desc
      },
    },
    {
      field: 'policy_name',
      width: 220,
      renderHeader: () => <span>Policy Name</span>,
      headerClasses: 'text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        return <div className="SingleLine">{params.row.policy_name}</div>;
      },
    },
    {
      field: 'service_category',
      headerName: 'Service Category',
      headerClasses: ' text-center',
      classes: 'align-middle text-center',
      minWidth: 180,
      sort: true,
      renderCell: params => (
        <>
          <AWSServiceCategoryIcons name={params.row.service_category} />
          <span style={{ paddingLeft: '7px' }}>{params.row.service_category}</span>
        </>
      ),
    },
    {
      field: 'service',
      width: 120,
      renderHeader: () => <span>Service</span>,
      headerClasses: ' text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        // console.log('policy_icons', params);
        return (
          <div className="d-flex">
            <AWSServiceIcons name={params.row.service} />
            <div className="ml-2">{params.row.service}</div>
          </div>
        );
      },
    },
    {
      field: 'resource_type',
      width: 150,
      renderHeader: () => <span>Resource Type</span>,
      headerClasses: 'text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto', minWidth: '96px' };
      },
      renderCell: params => {
        return (
          <Tooltip title={params.row.resource_type}>
            <div className="w-100 SingleLine">{params.row.resource_type}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'risk_category',
      width: 220,
      renderHeader: () => <span>Risk Category</span>,
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        console.log('riskParams', params);
        return <RiskTypeIcons name={params.value[0]} tooltip={true} />;
      },
    },
    {
      field: 'group',
      width: 150,
      renderHeader: () => <span>Group</span>,
      headerClasses: 'text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto', minWidth: '96px' };
      },
      renderCell: params => {
        let groups = [];
        params?.row?.user_group?.forEach(g => {
          if (g && g.length) {
            g?.forEach(group => {
              groups.push(group);
            });
          }
        });
        groups = groups.reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []);
        return (
          <div className="w-100">
            <CountTooltipW labels={groups} />
          </div>
        );
      },
    },
    {
      field: 'generated',
      width: 250,
      renderHeader: () => <span>Generated (By/Date)</span>,
      headerClasses: ' text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        return (
          <div className="SingleLine">
            <Tooltip title={params.row.created_by}>
              <div className="SingleLine">{params.row.created_by}</div>
            </Tooltip>
            <div className="SingleLine">
              {/* {moment(momentTz.tz(params.row.created_at, 'UTC').toDate()).format('YYYY-MM-DD hh:mm A')} */}
              {DateFormatPipe(params.row.created_at)}
            </div>
          </div>
        );
      },
    },
    {
      field: 'last_edit',
      width: 250,
      renderHeader: () => <span>Last Edit (By/Date)</span>,
      headerClasses: 'text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        return (
          <div className="SingleLine">
            <div className="SingleLine">{params.row.updated_by || '-'}</div>
            <div className="SingleLine">
              {params.row.updated_at
                ? // ? moment(momentTz.tz(params.row.updated_at, 'UTC').toDate()).format('YYYY-MM-DD hh:mm A')
                  DateFormatPipe(params.row.updated_at)
                : '-'}
            </div>
          </div>
        );
      },
    },
    {
      field: 'manual',
      width: 110,
      renderHeader: () => <span>Manual</span>,
      headerClasses: ' text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        if (params.row.allow_manual) {
          return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clip-path="url(#clip0_12575_22522)">
                <path
                  d="M8.6 11.8L6.425 9.625C6.24167 9.44167 6.01667 9.35 5.75 9.35C5.48333 9.35 5.25 9.45 5.05 9.65C4.86667 9.83333 4.775 10.0667 4.775 10.35C4.775 10.6333 4.86667 10.8667 5.05 11.05L7.9 13.9C8.08333 14.0833 8.31667 14.175 8.6 14.175C8.88333 14.175 9.11667 14.0833 9.3 13.9L14.975 8.225C15.1583 8.04167 15.25 7.81667 15.25 7.55C15.25 7.28333 15.15 7.05 14.95 6.85C14.7667 6.66667 14.5333 6.575 14.25 6.575C13.9667 6.575 13.7333 6.66667 13.55 6.85L8.6 11.8ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z"
                  fill="#03BB34"
                />
              </g>
              <defs>
                <clipPath id="clip0_12575_22522">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        }
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_12575_22524)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10ZM6.97 6.97C7.11063 6.82955 7.30125 6.75066 7.5 6.75066C7.69875 6.75066 7.88937 6.82955 8.03 6.97L10 8.94L11.97 6.97C12.1122 6.83752 12.3002 6.7654 12.4945 6.76882C12.6888 6.77225 12.8742 6.85097 13.0116 6.98838C13.149 7.12579 13.2277 7.31118 13.2312 7.50548C13.2346 7.69978 13.1625 7.88783 13.03 8.03L11.06 10L13.03 11.97C13.1625 12.1122 13.2346 12.3002 13.2312 12.4945C13.2277 12.6888 13.149 12.8742 13.0116 13.0116C12.8742 13.149 12.6888 13.2277 12.4945 13.2312C12.3002 13.2346 12.1122 13.1625 11.97 13.03L10 11.06L8.03 13.03C7.88783 13.1625 7.69978 13.2346 7.50548 13.2312C7.31118 13.2277 7.12579 13.149 6.98838 13.0116C6.85097 12.8742 6.77225 12.6888 6.76882 12.4945C6.7654 12.3002 6.83752 12.1122 6.97 11.97L8.94 10L6.97 8.03C6.82955 7.88937 6.75066 7.69875 6.75066 7.5C6.75066 7.30125 6.82955 7.11063 6.97 6.97Z"
                fill="#F72121"
              />
            </g>
            <defs>
              <clipPath id="clip0_12575_22524">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      },
    },
    {
      field: 'automated',
      width: 110,
      renderHeader: () => <span>Automated</span>,
      headerClasses: ' text-center',
      classes: 'align-middle text-center',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        if (params.row.allow_automated) {
          return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clip-path="url(#clip0_12575_22522)">
                <path
                  d="M8.6 11.8L6.425 9.625C6.24167 9.44167 6.01667 9.35 5.75 9.35C5.48333 9.35 5.25 9.45 5.05 9.65C4.86667 9.83333 4.775 10.0667 4.775 10.35C4.775 10.6333 4.86667 10.8667 5.05 11.05L7.9 13.9C8.08333 14.0833 8.31667 14.175 8.6 14.175C8.88333 14.175 9.11667 14.0833 9.3 13.9L14.975 8.225C15.1583 8.04167 15.25 7.81667 15.25 7.55C15.25 7.28333 15.15 7.05 14.95 6.85C14.7667 6.66667 14.5333 6.575 14.25 6.575C13.9667 6.575 13.7333 6.66667 13.55 6.85L8.6 11.8ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z"
                  fill="#03BB34"
                />
              </g>
              <defs>
                <clipPath id="clip0_12575_22522">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        }
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_12575_22524)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10ZM6.97 6.97C7.11063 6.82955 7.30125 6.75066 7.5 6.75066C7.69875 6.75066 7.88937 6.82955 8.03 6.97L10 8.94L11.97 6.97C12.1122 6.83752 12.3002 6.7654 12.4945 6.76882C12.6888 6.77225 12.8742 6.85097 13.0116 6.98838C13.149 7.12579 13.2277 7.31118 13.2312 7.50548C13.2346 7.69978 13.1625 7.88783 13.03 8.03L11.06 10L13.03 11.97C13.1625 12.1122 13.2346 12.3002 13.2312 12.4945C13.2277 12.6888 13.149 12.8742 13.0116 13.0116C12.8742 13.149 12.6888 13.2277 12.4945 13.2312C12.3002 13.2346 12.1122 13.1625 11.97 13.03L10 11.06L8.03 13.03C7.88783 13.1625 7.69978 13.2346 7.50548 13.2312C7.31118 13.2277 7.12579 13.149 6.98838 13.0116C6.85097 12.8742 6.77225 12.6888 6.76882 12.4945C6.7654 12.3002 6.83752 12.1122 6.97 11.97L8.94 10L6.97 8.03C6.82955 7.88937 6.75066 7.69875 6.75066 7.5C6.75066 7.30125 6.82955 7.11063 6.97 6.97Z"
                fill="#F72121"
              />
            </g>
            <defs>
              <clipPath id="clip0_12575_22524">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      },
    },
    {
      field: 'notification',
      width: 114,
      renderHeader: () => <span>Notification</span>,
      headerClasses: '',
      classes: 'align-middle',
      sort: true,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        return (
          <div className="d-flex flex-row justify-content-between">
            {params.row.is_notification_allowed ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_12575_22522)">
                  <path
                    d="M8.6 11.8L6.425 9.625C6.24167 9.44167 6.01667 9.35 5.75 9.35C5.48333 9.35 5.25 9.45 5.05 9.65C4.86667 9.83333 4.775 10.0667 4.775 10.35C4.775 10.6333 4.86667 10.8667 5.05 11.05L7.9 13.9C8.08333 14.0833 8.31667 14.175 8.6 14.175C8.88333 14.175 9.11667 14.0833 9.3 13.9L14.975 8.225C15.1583 8.04167 15.25 7.81667 15.25 7.55C15.25 7.28333 15.15 7.05 14.95 6.85C14.7667 6.66667 14.5333 6.575 14.25 6.575C13.9667 6.575 13.7333 6.66667 13.55 6.85L8.6 11.8ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z"
                    fill="#03BB34"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_12575_22522">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_12575_22524)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10ZM6.97 6.97C7.11063 6.82955 7.30125 6.75066 7.5 6.75066C7.69875 6.75066 7.88937 6.82955 8.03 6.97L10 8.94L11.97 6.97C12.1122 6.83752 12.3002 6.7654 12.4945 6.76882C12.6888 6.77225 12.8742 6.85097 13.0116 6.98838C13.149 7.12579 13.2277 7.31118 13.2312 7.50548C13.2346 7.69978 13.1625 7.88783 13.03 8.03L11.06 10L13.03 11.97C13.1625 12.1122 13.2346 12.3002 13.2312 12.4945C13.2277 12.6888 13.149 12.8742 13.0116 13.0116C12.8742 13.149 12.6888 13.2277 12.4945 13.2312C12.3002 13.2346 12.1122 13.1625 11.97 13.03L10 11.06L8.03 13.03C7.88783 13.1625 7.69978 13.2346 7.50548 13.2312C7.31118 13.2277 7.12579 13.149 6.98838 13.0116C6.85097 12.8742 6.77225 12.6888 6.76882 12.4945C6.7654 12.3002 6.83752 12.1122 6.97 11.97L8.94 10L6.97 8.03C6.82955 7.88937 6.75066 7.69875 6.75066 7.5C6.75066 7.30125 6.82955 7.11063 6.97 6.97Z"
                    fill="#F72121"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_12575_22524">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        );
      },
    },
    {
      field: 'placeholder',
      width: 96,
      renderHeader: () => <span>Actions</span>,
      headerClasses: 'text-center',
      classes: 'align-middle text-center',
      sort: false,
      headerStyle: () => {
        return { whiteSpace: 'nowrap', width: 'auto' };
      },
      renderCell: params => {
        return (
          <div className="d-flex flex-row justify-content-between mx-2">
            <div className="ml-2">
              <RemediationPolicyMenuButton
                row={params.row}
                EditModal={EditModal}
                RevokeModal={RevokeModal}
                isRevokeModalOpen={isRevokeModalOpen}
                setRevokeModalOpen={setRevokeModalOpen}
                onPolicyRevoked={onPolicyRevoked}></RemediationPolicyMenuButton>
            </div>
          </div>
        );
      },
    },

    // {
    //   field: "tag",
    //   text: "Tags",
    //   headerClasses: " text-center",
    //   classes: "align-middle text-center",
    //   headerStyle: () => {
    //     return { whiteSpace: "nowrap", width: "25%" };
    //   },
    //   renderCell: (params) => {
    //     if (_.isEmpty(cell) || cell == "-") return <span>-</span>;
    //     return (
    //       <span>
    //         <strong>{cell[0]?.Key}</strong> {cell[0]?.Value}
    //       </span>
    //     );
    //   },
    //   sort: true,
    // },
  ];
};
