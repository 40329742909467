import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './style.scss';

function FilterItem({ field, value, fields, options, handleRemoveFieldClick, handleFieldChange, index, noFormat }) {
  return (
    <div className="FilterItem">
      <div className="FilterItem__OptionsContainer" style={{ width: '275px' }}>
        <FormControl sx={{ minWidth: ' 220px' }}>
          {/* <InputLabel id="demo-simple-select-autowidth-label">Attribute Key</InputLabel> */}
          <Select
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return (
                  <span style={{ fontSize: '14px', fontWeight: '500', color: '#A3A6AB', fontFamily: 'Manrope' }}>
                    Select a property
                  </span>
                );
              }

              return (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#484D56',
                    fontFamily: 'Manrope',
                    textTransform: 'capitalize',
                  }}>
                  {selected}
                </span>
              );
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            value={field && field.id}
            onChange={e => handleFieldChange(e, index, 'field')}
            fullWidth
            style={{
              color: 'black',
              backgroundColor: 'var(--filter-item-select-background)',
              borderColor: 'var(--filter-item-select-border)',
              marginBottom: '15px',
              height: '40px',
              width: '275px',
            }}>
            {fields
              ? fields.map(f => {
                  if (f !== 'Detected Date')
                    return (
                      <MenuItem style={{ textTransform: 'capitalize' }} value={f}>
                        {f}
                      </MenuItem>
                    );
                })
              : null}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: ' 220px' }}>
          {/* <InputLabel id="demo-simple-select-autowidth-label">Attribute Values</InputLabel> */}
          <Select
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return (
                  <span style={{ fontSize: '14px', fontWeight: '500', color: '#A3A6AB', fontFamily: 'Manrope' }}>
                    Select a value
                  </span>
                );
              }

              return (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#484D56',
                    fontFamily: 'Manrope',
                    textTransform: 'capitalize',
                  }}>
                  {selected.toLowerCase()}
                </span>
              );
            }}
            value={value && value.id}
            onChange={e => handleFieldChange(e, index, 'value')}
            fullWidth
            style={{
              color: 'black',
              backgroundColor: 'var(--filter-item-select-background)',
              borderColor: 'var(--filter-item-select-border)',
              height: '40px',
              width: '275px',
            }}>
            {options
              ? noFormat
                ? options?.[field?.id]?.map?.(o => (
                    <MenuItem style={{ textTransform: 'capitalize' }} value={o}>
                      {o}
                    </MenuItem>
                  ))
                : // added a regex such that if there are more than 2 words they are joined by _
                  // initially replace(' ', '_) only converts 2 words
                  options?.[field?.id?.replace(/ /g, '_')?.toLowerCase()]?.map?.(o => (
                    <MenuItem style={{ textTransform: 'capitalize' }} value={o}>
                      {o.toLowerCase()}
                    </MenuItem>
                  ))
              : null}
          </Select>
        </FormControl>
      </div>

      <div>
        <IconButton
          onClick={() => handleRemoveFieldClick(index)}
          sx={{
            width: '25px',
            height: '25px',
            background: 'var(--background)',
            border: '1px solid var(--border-color)',
            '&:hover': {
              background: 'var(--background)',
            },
          }}>
          <Close
            sx={{
              width: '15px',
              color: 'var(--text-color)',
            }}
          />
        </IconButton>
      </div>
    </div>
  );
}

export default FilterItem;
