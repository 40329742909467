// import { useEffect } from 'react';
import auto_scaling from '@assets/img/aws_services/auto-scaling.png';
import access_analyzer from '@assets/img/aws_services/access-analyzer.svg';
import sagemaker from '@assets/img/aws_services/sagemaker.svg';
import direct_connect from '@assets/img/aws_services/direct_connect.svg';
import amazon_cloudfront from '@assets/img/aws_services/amazon-cloudfront.svg';
import amazon_elasticcache from '@assets/img/aws_services/amazon-elasticcache.svg';
import amazon_redshift from '@assets/img/aws_services/amazon-redshift.svg';
import amazon_s3 from '@assets/img/aws_services/amazon-s3.svg';
import amazon_ses from '@assets/img/aws_services/amazon-ses.svg';
import amazon_sns from '@assets/img/aws_services/amazon-sns.svg';
import amazon_sqs from '@assets/img/aws_services/amazon-sqs.svg';
import amazon_vpc from '@assets/img/aws_services/amazon-vpc.svg';
import api_gateway from '@assets/img/aws_services/api-gateway.svg';
import app_sync from '@assets/img/aws_services/app-sync.svg';
import doc_db from '@assets/img/aws_services/doc-db.svg';
import aws_certificate_manager from '@assets/img/aws_services/aws-certificate-manager.svg';
import aws_network from '@assets/img/aws_services/aws_network.svg';
import aws_kinesis from '@assets/img/aws_services/aws-kinesis.svg';
import aws_cloudformation from '@assets/img/aws_services/aws-cloudformation.svg';
import aws_guardduty from '@assets/img/aws_services/aws-guardduty.svg';
import aws_cloudtrail from '@assets/img/aws_services/aws-cloudtrail.svg';
import aws_cloudwatch from '@assets/img/aws_services/aws-cloudwatch.svg';
import aws_codebuild from '@assets/img/aws_services/aws-codebuild.svg';
import aws_config from '@assets/img/aws_services/aws-config.svg';
import aws_dynamodb from '@assets/img/aws_services/aws-dynamodb.svg';
import aws_elastic_beanstalk from '@assets/img/aws_services/aws-elastic-beanstalk.svg';
import aws_emr from '@assets/img/aws_services/aws-emr.svg';
import aws_opensearch from '@assets/img/aws_services/aws-opensearch.svg';
import aws_kms from '@assets/img/aws_services/aws-kms.svg';
import aws_dms from '@assets/img/aws_services/aws-dms.svg';
import aws_dax from '@assets/img/aws_services/aws-dax.svg';
import aws_lambda from '@assets/img/aws_services/aws-lambda.svg';
import aws_organizations from '@assets/img/aws_services/aws-organizations.svg';
import aws_rds from '@assets/img/aws_services/aws-rds.svg';
import aws_route53 from '@assets/img/aws_services/aws-route53.svg';
import ec2_instances from '@assets/img/aws_services/ec2-instances.svg';
import ECR from '@assets/img/aws_services/ECR.svg';
import ECS from '@assets/img/aws_services/ECS.svg';
import EFS from '@assets/img/aws_services/EFS.svg';
import elastic_load_balancing from '@assets/img/aws_services/elastic-load-balancing.svg';
import iam from '@assets/img/aws_services/iam.svg';
import secrets_manager from '@assets/img/aws_services/secrets-manager.svg';
// import security_group from '@assets/img/aws_services/security-group.svg';
// import vpc_elastic_network_interface from '@assets/img/aws_services/vpc-elastic-network-interface.svg';
import amazon_sfn from '@assets/img/aws_services/sfn.png';
import amazon_wfa from '@assets/img/aws_services/wfa.png';
import amazon_glue from '@assets/img/aws_services/aws-glue.png';
import aws_step_functions from '@assets/img/aws_services/aws_step_functions.png';
import athena from '@root/assets/img/aws_services/aws_athena.svg';
import CountTooltip from '@root/components/table/cell-types/CountTooltip';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Neptune from '@assets/img/aws_services/AWS_Neptune.svg';
import Ebs from '@assets/img/aws_services/AWS__EBS.svg';
import Account from '@assets/img/aws_services/AWS_Account.svg';
import Backup from '@assets/img/aws_services/aws_backup_1.svg';
import eks from '@assets/img/aws_services/aws_eks.svg';
import aws_system_manager from '@assets/img/aws_services/aws-systems-manager.svg';
import aws_fsx from '@assets/img/aws_services/aws-fsx.svg';
import aws_events from '@assets/img/aws_services/aws-events.svg';
import aws_eventbridge from '@assets/img/aws_services/aws-eventbridge.svg';

import cloud_dns from '@assets/img/gcp-service-icons/cloud_dns.svg';
import cloud_load_balancing from '@assets/img/gcp-service-icons/cloud_load_balancing.svg';
import identity_and_access_management from '@assets/img/gcp-service-icons/identity_and_access_management.svg';
import Cloud_key_management_service from '@assets/img/gcp-service-icons/key_management_service.svg';
import compute_engine from '@assets/img/gcp-service-icons/compute_engine.svg';
import cloud_functions from '@assets/img/gcp-service-icons/cloud_functions.svg';
import cloud_storage from '@assets/img/gcp-service-icons/cloud_storage.svg';
import cloud_sql from '@assets/img/gcp-service-icons/cloud_sql.svg';
import bigquery from '@assets/img/gcp-service-icons/bigquery.svg';
import pubsub from '@assets/img/gcp-service-icons/pubsub.svg';
import cloud_logging from '@assets/img/gcp-service-icons/cloud_logging.svg';
import service_usage from '@assets/img/gcp-service-icons/service_discovery.svg';
import assetinventory from '@assets/img/gcp-service-icons/asset_inventory.svg';
import gcpvpc from '@assets/img/gcp-service-icons/virtual_private_cloud.svg';
import dataproc from '@assets/img/gcp-service-icons/dataproc.svg';
import { memo } from 'react';
import cognito from '@assets/img/aws_services/aws-cognito-logo.png';
import cloud_run from '@assets/img/gcp-service-icons/cloud_run.svg';
import gcp_gke from '@assets/img/gcp-service-icons/gke_on-prem.svg';
import essential_contact from '@assets/img/gcp-service-icons/essential_contact.png';
import artifact_registry from '@assets/img/gcp-service-icons/artifact_registry.svg';
import google_kubernetes_engine from '@assets/img/gcp-service-icons/google_kubernetes_engine.svg';

// const IMAGE_MAP = {
//   account: Account,
//   ebs: Ebs,
//   backup: Backup,
//   neptune: Neptune,
//   accessanalyzer: access_analyzer,
//   acm: aws_certificate_manager,
//   apigateway: api_gateway,
//   api: api_gateway,
//   cloudtrail: aws_cloudtrail,
//   autoscaling: auto_scaling,
//   ['auto scaling']: auto_scaling,
//   auto: auto_scaling,
//   beanstalk: aws_elastic_beanstalk,
//   elasticbeanstalk: aws_elastic_beanstalk,
//   cloudformation: aws_cloudformation,
//   cloudfront: amazon_cloudfront,
//   ec2: ec2_instances,
//   ['stepfunctions']: aws_step_functions,
//   ['step functions']: aws_step_functions,
//   config: aws_config,
//   cloudwatch: aws_cloudwatch,
//   dynamodb: aws_dynamodb,
//   ecr: ECR,
//   ecs: ECS,
//   efs: EFS,
//   elb: elastic_load_balancing,
//   lambda: aws_lambda,
//   elbv2: elastic_load_balancing,
//   elasticache: amazon_elasticcache,
//   emr: aws_emr,
//   iam: iam,
//   kms: aws_kms,
//   organization: aws_organizations,
//   organizations: aws_organizations,
//   route53: aws_route53,
//   route: aws_route53,
//   ['aws route 53']: aws_route53,
//   ['route 53']: aws_route53,
//   rds: aws_rds,
//   redshift: amazon_redshift,
//   vpc: amazon_vpc,
//   s3: amazon_s3,
//   ses: amazon_ses,
//   secretsmanager: secrets_manager,
//   secrets: secrets_manager,
//   sns: amazon_sns,
//   sqs: amazon_sqs,
//   sfn: amazon_sfn,
//   waf: amazon_wfa,
//   glue: amazon_glue,
//   wafv2: amazon_wfa,
//   ['aws access analyzer']: access_analyzer,
//   ['access analyzer']: access_analyzer,
//   ['athena']: athena,
//   ['aws eks']: eks,
//   eks: eks,
//   gcpclouddns: cloud_dns,
//   gcpdns: cloud_dns,
//   dns: cloud_dns,
//   gcpcloudloadbalancing: cloud_load_balancing,
//   gcploadbalancer: cloud_load_balancing,
//   balancer: cloud_load_balancing,
//   'gcpidentityandaccessmanagement(iam)': identity_and_access_management,
//   gcpiam: identity_and_access_management,
//   'gcpcloudkeymanagementservice(kms)': Cloud_key_management_service,
//   gcpkms: Cloud_key_management_service,
//   gcpcomputeengine: compute_engine,
//   engine: compute_engine,
//   gcpcloudfunctions: cloud_functions,
//   cloudfunctions: cloud_functions,
//   gcpcloudstorage: cloud_storage,
//   cloudstorage: cloud_storage,
//   gcpcloudlogging: cloud_logging,
//   cloudlogging: cloud_logging,
//   gcpcloudsql: cloud_sql,
//   cloudsql: cloud_sql,
//   ['gcpbigquery']: bigquery,
//   bigquery: bigquery,
//   'gcppub/sub': pubsub,
//   'pub/sub': pubsub,
//   gcpserviceusage: service_usage,
//   '(su)': service_usage,
//   'gcpserviceusage(su)': service_usage,
//   gcpfunctions: cloud_functions,
//   functions: cloud_functions,
//   gcpstorage: cloud_storage,
//   storage: cloud_storage,
//   gcplogging: cloud_logging,
//   logging: cloud_logging,
//   gcpsql: cloud_sql,
//   sql: cloud_sql,
//   gcpassetinventory: assetinventory,
//   inventory: assetinventory,
//   gcpvpc: gcpvpc,
//   gcpdataproc: dataproc,
//   dataproc: dataproc,
//   gcpgke: gcp_gke,
//   gke: gcp_gke,
//   gcpessentialcontacts: essential_contact,
//   contacts: essential_contact,
//   cloudrun: cloud_run,
//   gcpcloudrun: cloud_run,
//   gcpartifactregistry: artifact_registry,
//   registry: artifact_registry,
//   cognito: cognito,
//   ['aws cognito']: cognito,
//   gcpkubernetesengine: google_kubernetes_engine,
// };

const IMAGE_MAP = {
  awsaccount: Account,
  account: Account,
  ebs: Ebs,
  awsebs: Ebs,
  awsbackup: Backup,
  awsneptune: Neptune,
  backup: Backup,
  neptune: Neptune,
  accessanalyzer: access_analyzer,
  awsaccessanalyzer: access_analyzer,
  awsdirectconnect: direct_connect,
  awssagemaker: sagemaker,
  awsacm: aws_certificate_manager,
  acm: aws_certificate_manager,
  awsacmpca: aws_certificate_manager,
  acmpca: aws_certificate_manager,
  awsapigateway: api_gateway,
  api: api_gateway,
  awsapigatewayv2: api_gateway,
  apigatewayv2: api_gateway,
  apigateway: api_gateway,
  awsappsync: app_sync,
  appsync: app_sync,
  awsdocdb: doc_db,
  docdb: doc_db,
  awsdocumentdb: doc_db,
  documentdb: doc_db,
  awsnetworkfirewall: aws_network,
  networkfirewall: aws_network,
  awsnetwork: aws_network,
  network: aws_network,
  awscloudtrail: aws_cloudtrail,
  awsautoscaling: auto_scaling,
  ['auto scaling']: auto_scaling,
  auto: auto_scaling,
  awsbeanstalk: aws_elastic_beanstalk,
  elasticbeanstalk: aws_elastic_beanstalk,
  awselasticbeanstalk: aws_elastic_beanstalk,
  awscloudformation: aws_cloudformation,
  awscloudfront: amazon_cloudfront,
  awsec2: ec2_instances,
  ec2: ec2_instances,
  awsevents: aws_events,
  events: aws_events,
  awseventbridge: aws_eventbridge,
  eventbridge: aws_eventbridge,
  awsfsx: aws_fsx,
  fsx: aws_fsx,
  awssystemsmanager: aws_system_manager,
  systemsmanager: aws_system_manager,
  awsssm: aws_system_manager,
  ssm: aws_system_manager,
  awsdax: aws_dax,
  dax: aws_dax,
  awskinesis: aws_kinesis,
  kinesis: aws_kinesis,
  awsstepfunctions: aws_step_functions,
  ['stepfunctions']: aws_step_functions,
  ['step functions']: aws_step_functions,
  config: aws_config,
  awscloudwatch: aws_cloudwatch,
  awscodebuild: aws_codebuild,
  codebuild: aws_codebuild,
  awsopensearch: aws_opensearch,
  opensearch: aws_opensearch,
  awsdynamodb: aws_dynamodb,
  awsecr: ECR,
  awsecs: ECS,
  awsefs: EFS,
  ecr: ECR,
  ecs: ECS,
  efs: EFS,
  awselb: elastic_load_balancing,
  elb: elastic_load_balancing,
  awslambda: aws_lambda,
  lambda: aws_lambda,
  awselbv2: elastic_load_balancing,
  awselasticache: amazon_elasticcache,
  awsemr: aws_emr,
  emr: aws_emr,
  awsiam: iam,
  iam: iam,
  awskms: aws_kms,
  kms: aws_kms,
  awsdms: aws_dms,
  dms: aws_dms,
  awsguardduty: aws_guardduty,
  guardduty: aws_guardduty,
  organization: aws_organizations,
  awsorganizations: aws_organizations,
  awsroute53: aws_route53,
  route: aws_route53,
  ['aws route 53']: aws_route53,
  ['route 53']: aws_route53,
  awsrds: aws_rds,
  awsredshift: amazon_redshift,
  awsvpc: amazon_vpc,
  vpc: amazon_vpc,
  awss3: amazon_s3,
  s3: amazon_s3,
  awsses: amazon_ses,
  ses: amazon_ses,
  eks: eks,
  awssecretsmanager: secrets_manager,
  secrets: secrets_manager,
  awssns: amazon_sns,
  awssqs: amazon_sqs,
  awssfn: amazon_sfn,
  awswaf: amazon_wfa,
  awsglue: amazon_glue,
  awswafv2: amazon_wfa,
  sns: amazon_sns,
  sqs: amazon_sqs,
  sfn: amazon_sfn,
  waf: amazon_wfa,
  glue: amazon_glue,
  wafv2: amazon_wfa,
  ['aws access analyzer']: access_analyzer,
  ['access analyzer']: access_analyzer,
  ['athena']: athena,
  ['aws eks']: eks,
  awsathena: athena,
  awseks: eks,
  gcpclouddns: cloud_dns,
  gcpdns: cloud_dns,
  dns: cloud_dns,
  gcpcloudloadbalancing: cloud_load_balancing,
  gcploadbalancer: cloud_load_balancing,
  // balancer: cloud_load_balancing,
  'gcpidentityandaccessmanagement(iam)': identity_and_access_management,
  gcpiam: identity_and_access_management,
  'gcpcloudkeymanagementservice(kms)': Cloud_key_management_service,
  gcpkms: Cloud_key_management_service,
  gcpcomputeengine: compute_engine,
  // engine: compute_engine,
  gcpcloudfunctions: cloud_functions,
  // cloudfunctions: cloud_functions,
  gcpcloudstorage: cloud_storage,
  // cloudstorage: cloud_storage,
  gcpcloudlogging: cloud_logging,
  // cloudlogging: cloud_logging,
  gcpcloudsql: cloud_sql,
  // cloudsql: cloud_sql,
  // ['gcpbigquery']: bigquery,
  gcpbigquery: bigquery,
  bigquery: bigquery,
  'gcppub/sub': pubsub,
  // 'pub/sub': pubsub,
  gcpserviceusage: service_usage,
  // '(su)': service_usage,
  'gcpserviceusage(su)': service_usage,
  gcpfunctions: cloud_functions,
  // functions: cloud_functions,
  gcpstorage: cloud_storage,
  // storage: cloud_storage,
  gcplogging: cloud_logging,
  logging: cloud_logging,
  gcpsql: cloud_sql,
  // sql: cloud_sql,
  gcpassetinventory: assetinventory,
  // inventory: assetinventory,
  gcpvpc: gcpvpc,
  gcpdataproc: dataproc,
  // dataproc: dataproc,
  gcpgke: gcp_gke,
  // gke: gcp_gke,
  gcpessentialcontacts: essential_contact,
  // contacts: essential_contact,
  // cloudrun: cloud_run,
  gcpcloudrun: cloud_run,
  gcpartifactregistry: artifact_registry,
  // registry: artifact_registry,
  awscognito: cognito,
  // ['aws cognito']: cognito,
  gcpkubernetesengine: google_kubernetes_engine,
};
const AWSServiceIcons = ({
  height = 20,
  width = 20,
  name,
  multiple,
  showName = true,
  tooltip = false,
  marginRight = 0,
}) => {
  // const serviceName = multiple ? name : name?.replace('gcp', '');
  const getImgName = serviceName => {
    if (!serviceName) return null;
    const formattedName = serviceName.toLowerCase().replace(/\s+/g, '').replace(/-/g, '');
    return IMAGE_MAP[formattedName] || null;
  };

  // useEffect(() => {
  //   const getImgName = serviceName => {
  //     if (!serviceName) return null;
  //     const formattedName = serviceName.toLowerCase().replace(/\s+/g, '').replace(/-/g, '');
  //     return formattedName || null;
  //   };
  //   console.log('iconsNames', getImgName(name));
  // }, []);

  if (multiple) {
    return (
      <>
        {/* {name[0]?.toLowerCase().split(' ')?.slice(1).join('') in IMAGE_MAP && (
          <Tooltip title={tooltip ? name : ''}>
            <img
              src={IMAGE_MAP[name[0]?.toLowerCase().split(' ')?.slice(1).join('')]}
              height={height}
              width={width}
              style={{ objectFit: 'contain' }}
              alt={name}
            />
          </Tooltip>
        )} */}
        {name[0] && getImgName(name[0]) && (
          <Tooltip title={tooltip ? name[0] : ''}>
            <img
              src={getImgName(name[0])}
              height={height}
              width={width}
              style={{ objectFit: 'contain' }}
              alt={name[0]}
            />
          </Tooltip>
        )}

        {showName && (
          <span
            style={{
              margin: '0 5px',
            }}>
            {name[0]}
          </span>
        )}
        {name.length > 1 && (
          <CountTooltip
            // showFirst={false}
            labels={name}
            visibleLabelStyle={{ width: '140px !important' }}
            borderless
            showFirst={false}
          />
        )}
      </>
    );
  } else {
    // const imgName = IMAGE_MAP[name?.toLowerCase()] || null;
    const imgName = getImgName(name);

    return (
      <>
        {imgName ? (
          tooltip ? (
            <Tooltip title={tooltip ? name : ''}>
              <img
                src={imgName}
                height={height}
                width={width}
                style={{ objectFit: 'contain', marginRight: marginRight ? marginRight : 0 }}
                alt={name}
              />
            </Tooltip>
          ) : (
            <img
              src={imgName}
              height={height}
              width={width}
              style={{ objectFit: 'contain', marginRight: marginRight ? marginRight : 0 }}
              alt={name}
            />
          )
        ) : null}
      </>
    );
  }
};

export default memo(AWSServiceIcons);
