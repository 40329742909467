import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Searchbar from '@root/components/Searchbar';
import Text from '@root/components/v2/ui-elements/Text';

import FilterItem from './FilterItem';
import ButtonIcon from '@root/components/common/ButtonIcon';
import { faAngleDoubleDown, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';

import athena from '@root/assets/img/aws_services/aws_athena.svg';
import './style.scss';
import { useQuery } from '@root/hooks/useQuery';
import Accordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Checkbox } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import AWSServiceIcons from '@root/components/awsServiceIcons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NetworkingIcon from '@root/assets/svg/Service_Category_Icons/Networking.svg';
import ComputeIcon from '@root/assets/svg/Service_Category_Icons/Compute.svg';
import DeveloperIcon from '@root/assets/svg/Service_Category_Icons/Developer_Tools.svg';
import MachineLearningIcon from '@root/assets/svg/Service_Category_Icons/Machine_Learning.svg';
import StorageIcon from '@root/assets/svg/Service_Category_Icons/Storage.svg';
import OperationIcon from '@root/assets/svg/Service_Category_Icons/Operations.svg';
import SecurityIdentityIcon from '@root/assets/svg/Service_Category_Icons/Security_Identity.svg';
import ServerlessIcon from '@root/assets/svg/Service_Category_Icons/Serverless.svg';
import ManagementToolsIcon from '@root/assets/svg/Service_Category_Icons/Management_Tools.svg';
import ContainersIcon from '@root/assets/svg/Service_Category_Icons/Containers.svg';
import Analytics from '@root/assets/svg/Service_Category_Icons/Data_Analytics.svg';
import Database from '@root/assets/svg/Service_Category_Icons/Database.svg';

import vulnerabilitiesLogo from '@root/assets/svg/Cloud_Events/Vulnerabilities.svg';
import dataLogo from '@root/assets/svg/Cloud_Events/Data.svg';
import encryptionLogo from '@root/assets/svg/Cloud_Events/Encryption.svg';
import exposedSecretsLogo from '@root/assets/svg/Cloud_Events/ExposedSecrets.svg';
import networkLogo from '@root/assets/svg/Cloud_Events/Network.svg';
import anomalousLogo from '@root/assets/svg/Cloud_Events/Anomalous.svg';
import userLogo from '@root/assets/svg/Cloud_Events/User.svg';
import noRiskLogo from '@root/assets/svg/Cloud_Events/No_Risk.svg';
import loggingMonitoringLogo from '@root/assets/svg/Cloud_Events/LoggingMonitoring.svg';
import lateralMovementLogo from '@root/assets/svg/Cloud_Events/LateralMovement.svg';
import highAvailabilityLogo from '@root/assets/svg/Cloud_Events/HighAvailability.svg';
import identityLogo from '@root/assets/svg/Cloud_Events/Identity.svg';

import CustomizedSlider from '../RangeSlider';

const serviceGridIcons = {
  [`Networking`]: NetworkingIcon,
  ['Operations']: OperationIcon,
  ['Developer Tools']: DeveloperIcon,
  ['Machine Learning']: MachineLearningIcon,
  ['Storage']: StorageIcon,
  ['Compute']: ComputeIcon,
  ['Security & Identity']: SecurityIdentityIcon,
  ['Serverless']: ServerlessIcon,
  ['Management Tools']: ManagementToolsIcon,
  ['Containers']: ContainersIcon,
  ['Data Analytics']: Analytics,
  ['Database']: Database,
  ['Athena']: athena,
};
const active_risk_mapping = {
  ['Data Security']: dataLogo,
  ['Encryption']: encryptionLogo,
  ['Exposed Secrets']: exposedSecretsLogo,
  ['Network']: networkLogo,
  ['Anomalous']: anomalousLogo,
  ['Logging & Monitoring']: loggingMonitoringLogo,
  ['Lateral Movement']: lateralMovementLogo,
  ['High Availability']: highAvailabilityLogo,
  ['Identity']: identityLogo,
  ['User']: userLogo,
  ['Vulnerabilities']: vulnerabilitiesLogo,
  ['NoRisk']: noRiskLogo,
};

function Filter({
  // tab,
  // setTab,
  children,
  open,
  noFormat,
  data,
  propertyKeys,
  propertyValues,
  updateFilterAllResources,
  handleUpdatePropertiesFilter,
  updateFilterPropertiesAnyAll,
  showCategoryFilter = true,
  setSelectedRiskCategory,
  setShowBreadcrumb,
  showRiskCategoryFilter = false,
  showRangeFilter = false,
  bucketAge,
  setBucketAge,
  bucketSize,
  setBucketSize,
  defaultSelectedFields,
  customClassName,
}) {
  const query = useQuery();
  const queryService = query.get('service');
  const queryCategory = !_.isEmpty(query.get('category'))
    ? query.get('category')?.replace('and', '&')
    : query.get('category');
  const queryResourceType = query.get('resource_type');
  const RiskCategory = query.get('RiskCategory');
  const from = query.get('from');
  const querySrc = query.get('source');
  const masterRuleId = query.get('masterRuleId');
  // const querySrc = query.get('source');
  const rule_id = query.get('rule_id');
  const fromRuntime = query.get('fromRunTime');
  const [searchFilterTerm, setSearchFilterTerm] = useState('');
  const [any, setAny] = useState(false);
  const [isCriticalTab] = useState(false);
  const tab = query.get('tab');

  const [selectedFields, setSelectedFields] = useState(
    tab === 'Threat' && querySrc
      ? [
          {
            field: {
              id: masterRuleId ? 'Threat/Anomaly Rule' : rule_id ? 'Rule ID' : querySrc ? 'Source' : '',
              title: masterRuleId ? 'Threat/Anomaly Rule' : rule_id ? 'Rule ID' : querySrc ? 'Source' : '',
            },
            value: {
              id: masterRuleId ? masterRuleId : rule_id ? rule_id : querySrc ? querySrc : '',
              title: masterRuleId ? masterRuleId : rule_id ? rule_id : querySrc ? querySrc : '',
            },
            isApplied: Boolean(masterRuleId) || Boolean(rule_id) || Boolean(querySrc),
          },
          {
            field: {
              id: masterRuleId ? 'Action' : rule_id ? 'Rule ID' : querySrc ? 'Source' : '',
              title: masterRuleId ? 'Threat/Anomaly Rule' : rule_id ? 'Rule ID' : querySrc ? 'Source' : '',
            },
            value: {
              id: masterRuleId ? masterRuleId : rule_id ? rule_id : querySrc ? querySrc : '',
              title: masterRuleId ? masterRuleId : rule_id ? rule_id : querySrc ? querySrc : '',
            },
            isApplied: Boolean(masterRuleId) || Boolean(rule_id) || Boolean(querySrc),
          },
        ]
      : [
          {
            field: {
              id: fromRuntime ? 'Action' : '',
              title: fromRuntime ? 'Action' : '',
            },
            value: {
              id: fromRuntime ? 'Open' : '',
              title: fromRuntime ? 'Open' : '',
            },
            isApplied: Boolean(fromRuntime),
          },
        ],
  );

  const firstLoad = useRef(true);
  useEffect(() => {
    if (defaultSelectedFields && firstLoad.current) {
      setSelectedFields(prev => [...defaultSelectedFields, ...prev]);
      firstLoad.current = false;
    }
    console.log(selectedFields, 'selectedFields');
  }, [defaultSelectedFields, selectedFields]);

  const isAnyFieldsEmpty = selectedFields.some(
    field => field.field.id === '' || field.field.title === '' || field.value.id === '' || field.value.title === '',
  );

  const [dataList, setDataList] = useState([]);

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(queryCategory ? [queryCategory] : []);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);

  const [filterType, setFilterType] = useState(false);

  // add empty field to selected fields

  let initialRiskCategories = useMemo(
    () => [
      { risk_category: 'Data Security', checked: false },
      { risk_category: 'Encryption', checked: false },
      { risk_category: 'Exposed Secrets', checked: false },
      { risk_category: 'Network', checked: false },
      { risk_category: 'Logging & Monitoring', checked: false },
      { risk_category: 'Lateral Movement', checked: false },
      { risk_category: 'High Availability', checked: false },
      { risk_category: 'Identity', checked: false },
      { risk_category: 'Vulnerabilities', checked: false },
    ],
    [],
  );
  const [riskCategories, setRiskCategories] = useState(initialRiskCategories);
  const handleAddFieldClick = useCallback(() => {
    setSelectedFields(prev => [
      ...prev,
      {
        field: {
          id: '',
          title: '',
        },
        value: {
          id: '',
          title: '',
        },
      },
    ]);
  }, []);

  const handleRemoveFieldClick = index => {
    if (selectedFields[index]?.isApplied) handleApplyProperties(index);

    if (selectedFields.length === 1) {
      setSelectedFields([
        {
          field: {
            id: '',
            title: '',
          },
          value: {
            id: '',
            title: '',
          },
        },
      ]);
      handleAnyToggle('all');
    } else if (selectedFields.length === 2) {
      handleAnyToggle('all');
      setSelectedFields(prev => prev.filter((_, i) => i !== index));
    } else {
      setSelectedFields(prev => prev.filter((_, i) => i !== index));
    }
  };

  const handleFieldChange = useCallback(
    (e, index, field) => {
      setSelectedFields(prev => {
        const newFields = [...prev];
        newFields[index][field].id = e.target.value;
        newFields[index][field].title = e.target.value;

        return newFields;
      });
    },
    [setSelectedFields],
  );

  const handleAnyToggle = useCallback(
    data => {
      setAny(data === 'any' ? true : false);
      updateFilterPropertiesAnyAll(data);
    },
    [setAny, updateFilterPropertiesAnyAll],
  );

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const mappedData = data.map(item => {
        return {
          service_category: item.service_category.category_name,
          service_icon: serviceGridIcons[`${item.service_category.category_name}`],
          // checked: selectedServiceCategory.includes(item.service_category.category_name) ? true : false,
          checked: item.service_category.category_name === queryCategory ? true : false,
          service_groups: item.service_category.services.map(service => ({
            service_group: service.service_name,
            service_group_id: service.service_name.split(' ').join('-'),
            // checked: selectedServices.find(i => i.service === service.service_name) ? true : false,
            checked: service.service_name === queryService ? true : false,
            service_types: service.resource_types.map(type => {
              if (queryResourceType === type) {
                return {
                  service_type_id: type,
                  // checked: selectedServiceTypes.find(item => item.type === type) ? true : false,
                  checked: true,
                };
              } else if (
                !queryResourceType &&
                queryCategory === item.service_category.category_name &&
                queryService === service.service_name
              ) {
                return {
                  service_type_id: type,
                  // checked: selectedServiceTypes.find(item => item.type === type) ? true : false,
                  checked: true,
                };
              } else if (!queryResourceType && !queryService && queryCategory === item.service_category.category_name) {
                return {
                  service_type_id: type,
                  // checked: selectedServiceTypes.find(item => item.type === type) ? true : false,
                  checked: true,
                };
              } else {
                return {
                  service_type_id: type,
                  // checked: selectedServiceTypes.find(item => item.type === type) ? true : false,
                  checked: false,
                };
              }
            }),
          })),
        };
      });
      setDataList(mappedData);
    }
  }, [data, queryCategory, queryResourceType, queryService]);

  useEffect(() => {
    if (filterType && updateFilterAllResources) {
      updateFilterAllResources(
        selectedServiceTypes.length > 0 ? [] : selectedServiceCategory,
        selectedServiceTypes.length > 0 ? [] : selectedServices,
        selectedServiceTypes,
      );
    }
  }, [selectedServiceCategory, selectedServices, selectedServiceTypes, filterType, updateFilterAllResources]);

  const handleSelectCategory = (service_category, checked) => {
    const newDataList = dataList.map(item => {
      if (item.service_category === service_category) {
        item.checked = checked;

        item.service_groups.map(service_group_item => {
          service_group_item.checked = checked;
          service_group_item.service_types.map(service_type_item => {
            service_type_item.checked = checked;
          });
        });
      }
      return item;
    });

    setDataList(newDataList);
  };

  const handleSelectServiceGroup = (service_category, service_group, checked) => {
    const newDataList = dataList.map(item => {
      if (item.service_category === service_category) {
        item.checked = checked;
        item.service_groups = item.service_groups.map(service_group_item => {
          if (service_group_item.service_group === service_group) {
            service_group_item.checked = checked;
            service_group_item.service_types.map(service_type_item => {
              service_type_item.checked = checked;
            });
          }
          return service_group_item;
        });
      }
      return item;
    });

    setDataList(newDataList);
  };

  const handleSelectServiceType = (service_category, service_group, service_type, checked) => {
    const newDataList = dataList.map(item => {
      if (item.service_category === service_category) {
        item.checked = checked;
        item.service_groups = item.service_groups.map(service_group_item => {
          if (service_group_item.service_group === service_group) {
            service_group_item.checked = checked;
            service_group_item.service_types = service_group_item.service_types.map(service_type_item => {
              if (service_type_item.service_type_id === service_type) {
                service_type_item.checked = checked;
              }
              return service_type_item;
            });
          }
          return service_group_item;
        });
      }
      return item;
    });

    setDataList(newDataList);
  };
  // const clearAllCheckboxes = () => {
  //   // Create a new copy of the data with all checkboxes set to false
  //   const updatedData = dataList.map(category => ({
  //     ...category,
  //     checked: false,
  //     service_groups: category.service_groups.map(group => ({
  //       ...group,
  //       checked: false,
  //       service_types: group.service_types.map(type => ({
  //         ...type,
  //         checked: false,
  //       })),
  //     })),
  //   }));

  //   // Update the state with the new data
  //   setDataList(updatedData);
  // };

  useEffect(() => {
    // Filter and update the selected data arrays
    if (filterType) {
      const selectedCategories = dataList
        .filter(category => category.checked)
        .map(category => category.service_category);

      const selectedGroups = dataList
        .flatMap(category => category.service_groups.filter(group => group.checked))
        .map(group => group.service_group);

      const selectedTypes = dataList
        .flatMap(category => category.service_groups.flatMap(group => group.service_types.filter(type => type.checked)))
        .map(type => type.service_type_id);

      setSelectedServiceCategory(selectedCategories);
      setSelectedServices(selectedGroups);
      setSelectedServiceTypes(selectedTypes);
    }

    // console.log(selectedCategories, '------', selectedServices, '------', selectedTypes, 'selectttedField');
  }, [dataList, filterType]);
  let props = {};
  const handleApplyProperties = index => {
    // map over selected fields to get properties

    let tempFields = selectedFields.map((item, i) => {
      if (index === i) return;

      if (!_.isEmpty(item.field)) {
        if (!props[item.field.id.toLowerCase().replaceAll(' ', '_')])
          props[item.field.id.toLowerCase().replaceAll(' ', '_')] = [];
        props[item.field.id.toLowerCase().replaceAll(' ', '_')] = [
          ...props[item.field.id.toLowerCase().replaceAll(' ', '_')],
          item.value.id,
        ];
        if (Object.keys(props).includes('mitre_att&ck')) {
          props['mitre_attack'] = props['mitre_att&ck'];
          delete props['mitre_att&ck'];
        }
        item.isApplied = true;
      }
      return item;
    });

    setSelectedFields(tempFields);
    console.log(props, 'propsss');

    handleUpdatePropertiesFilter({ ...props, match: any ? 'any' : 'all' });
  };
  const ResetFilter = () => {
    const newProps = { ...props };
    for (let key in newProps) {
      newProps[key] = [];
    }
    console.log(newProps, 'propsss');
    handleUpdatePropertiesFilter({ ...newProps, match: any ? 'any' : 'all' });
    setFilterType(true);
    // setSelectedServices([]);
    // setSelectedServiceCategory([]);
    // setSelectedServiceTypes([]);
    setSelectedFields([
      {
        field: {
          id: fromRuntime ? 'Action' : '',
          title: fromRuntime ? 'Action' : '',
        },
        value: {
          id: fromRuntime ? 'Open' : '',
          title: fromRuntime ? 'Open' : '',
        },
        isApplied: Boolean(fromRuntime),
      },
    ]);

    // clearAllCheckboxes();
  };

  // ---risk filter----

  initialRiskCategories.forEach(item => {
    if (item.risk_category === RiskCategory) {
      item.checked = true;
    }
  });
  useEffect(() => {
    initialRiskCategories.forEach(item => {
      if (from === 'Inventory') {
        item.checked = true;
      }
    });
  }, [from, initialRiskCategories]);

  const handleCheckboxChange = index => () => {
    setShowBreadcrumb(false);
    const updatedRiskCategories = [...riskCategories];
    updatedRiskCategories[index].checked = !updatedRiskCategories[index].checked;
    setRiskCategories(updatedRiskCategories);
    setSelectedRiskCategory(
      riskCategories.filter(category => {
        if (category.checked) {
          return category.risk_category;
        }
      }),
    );
  };
  const checkedCategories = riskCategories.filter(category => category.checked);

  const ageMarks = useMemo(() => {
    // Generate marks dynamically based on min and max values
    const newMarks = Array.from({ length: 5 }, (_, index) => ({
      value:
        propertyValues?.bucket_age?.min_age +
        ((propertyValues?.bucket_age?.max_age - propertyValues?.bucket_age?.min_age) / 4) * index,
      label: parseInt(
        propertyValues?.bucket_age?.min_age +
          ((propertyValues?.bucket_age?.max_age - propertyValues?.bucket_age?.min_age) / 4) * index,
      ),
    }));

    return newMarks;
  }, [propertyValues?.bucket_age?.min_age, propertyValues?.bucket_age?.max_age]);
  const sizeMarks = useMemo(() => {
    // Generate marks dynamically based on min and max values
    const newMarks = Array.from({ length: 5 }, (_, index) => ({
      value:
        propertyValues?.bucket_size?.min_size +
        ((propertyValues?.bucket_size?.max_size - propertyValues?.bucket_size?.min_size) / 4) * index,
      label: parseFloat(
        (
          propertyValues?.bucket_size?.min_size +
          ((propertyValues?.bucket_size?.max_size - propertyValues?.bucket_size?.min_size) / 4) * index
        ).toFixed(2),
      ),
    }));

    return newMarks;
  }, [propertyValues?.bucket_size?.min_size, propertyValues?.bucket_size?.max_size]);

  return (
    <div
      style={{
        ...(open && { height: '85vh !important' }),
      }}
      className={`FilterWrapper__New_PR  ${open ? 'FilterOpen' : ''} ${customClassName ?? ''}`}>
      <div className="FilterWrapper_Inner_New">
        <div className={`FilterContainer`}>
          {/* <div className="FilterContainer__Header">
            <Text element="h3" className="fs__3">
              Filters
            </Text>

            <Button size="small" style={{ marginRight: '10px' }} onClick={ResetFilter}>
              <Text className="fs__1">CLEAR ALL</Text>
            </Button>
          </div> */}

          <div className="FilterContainer__Inner CustomScrollbar">
            <div>
              {/* <div className="FilterContainer__Inner_service_category"> */}
              {/* <ButtonGroup
                  variant="outlined"
                  size="large"
                  style={{
                    margin: '20px',
                    width: '100%',
                    border: '1px solid var(--border-color-resource)',
                  }}>
                  <Button
                    // variant={tab === 'ALL' ? 'contained' : null}
                    style={{
                      textTransform: 'none',
                      width: '100%',
                      background: tab === 'ALL' ? '#194BFB' : null,
                      color: tab === 'ALL' ? '#FFFFFF' : '#484D56',
                    }}
                    onClick={() => setTab('ALL')}>
                    All Resources
                  </Button>
                  <Button
                    // variant={tab === 'CRITICAL' ? 'contained' : null}
                    onClick={() => setTab('CRITICAL')}
                    style={{
                      textTransform: 'none',
                      width: '100%',
                      background: tab === 'CRITICAL' ? '#194BFB' : null,
                      color: tab === 'CRITICAL' ? '#FFFFFF' : '#484D56',
                    }}>
                    Critical Resources
                  </Button>
                </ButtonGroup>{' '} */}
              {/* </div> */}

              <div className="FilterContainer__InnerContent CustomScrollbar" style={{ padding: '15px' }}>
                {showRangeFilter && (
                  <>
                    <div style={{ borderBottom: '1px solid var(--border-color-resource)', marginBottom: '15px' }}>
                      <Text
                        font="bold"
                        fontSize="14px"
                        fontWeight="600"
                        style={{ display: 'flex', alignItems: 'center', color: '#0b1727' }}>
                        Bucket age(Days)
                      </Text>
                      <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                        <CustomizedSlider
                          value={bucketAge}
                          onChange={(e, newValue) => setBucketAge(newValue)}
                          min={propertyValues?.bucket_age?.min_age ?? 0}
                          step={1}
                          max={propertyValues?.bucket_age?.max_age ?? 0}
                          // valueLabelDisplay="auto"
                          defaultValue={[
                            propertyValues?.bucket_age?.min_age ?? 0,
                            propertyValues?.bucket_age?.max_age ?? 0,
                          ]}
                          marks={ageMarks}
                          // getAriaValueText={value}
                        />{' '}
                      </div>
                    </div>
                    <div style={{ margin: '15px 0px', borderBottom: '1px solid var(--border-color-resource)' }}>
                      <Text
                        font="bold"
                        fontSize="14px"
                        fontWeight="600"
                        style={{ display: 'flex', alignItems: 'center', color: '#0b1727' }}>
                        Bucket size(GB)
                      </Text>
                      <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                        <CustomizedSlider
                          value={bucketSize}
                          onChange={(e, newValue) => setBucketSize(newValue)}
                          // valueLabelDisplay="auto"
                          min={propertyValues?.bucket_size?.min_size ?? 0}
                          step={0.01}
                          max={propertyValues?.bucket_size?.max_size ?? 0}
                          // valueLabelDisplay="auto"
                          defaultValue={[
                            propertyValues?.bucket_size?.min_size ?? 0,
                            propertyValues?.bucket_size?.max_size ?? 0,
                          ]}
                          marks={sizeMarks}
                          // getAriaValueText={value}
                        />{' '}
                      </div>
                    </div>
                  </>
                )}
                {showCategoryFilter && (
                  <Accordion
                    sx={{
                      borderBottom: '1px solid var(--border-color-resource);',
                      background: 'var(--background-color)',
                      color: 'var(--text-color)',

                      '&.MuiAccordion-root:before': {
                        display: 'none',
                      },

                      '&.MuiAccordion-root.Mui-expanded': {},
                      '& .MuiAccordionSummary-content.Mui-expanded': {
                        margin: '12px 0',
                      },
                      '& .MuiAccordionSummary-root.Mui-expanded': {
                        minHeight: 'unset',
                      },
                    }}
                    elevation={0}>
                    <AccordionSummary
                      aria-controls={'resource-filter-content'}
                      id={'resource-filter-header'}
                      style={{
                        display: 'flex',
                      }}
                      sx={{
                        padding: '0px',
                        '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters': {
                          display: 'flex',
                          justifyContent: 'space-between',
                          // margin: '15px',
                        },

                        '&.Mui-expanded': {},
                      }}
                      expandIcon={<FontAwesomeIcon icon={faAngleDoubleDown} className="ico" />}>
                      <div style={{ display: 'flex' }}>
                        <Text
                          font="bold"
                          fontSize="14px"
                          fontWeight="600"
                          style={{ display: 'flex', alignItems: 'center' }}>
                          Services Categories and Types
                        </Text>
                        <span
                          className="count_chip"
                          style={{
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: '500',
                            fontFamily: 'Manrope',
                            color: '#484D56',
                            border: '1px solid #CBD5E0',
                            boxShadow: '0px 8px 18px 0px rgba(93, 106, 131, 0.05)',
                            padding: '1px 8px 3px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '5px',
                          }}>
                          {`${selectedServiceCategory.length} Selected`}
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        paddingInline: '0',
                        paddingBlock: '0',
                        marginInline: '0',
                        marginBlock: '0',
                      }}>
                      <div className={`FilterWrapper__Search`} style={{ padding: '0px' }}>
                        <Searchbar
                          height={'40px'}
                          placeholder={'Search service..'}
                          value={searchFilterTerm}
                          onChange={e => setSearchFilterTerm(e.target.value)}
                        />
                      </div>
                      <div className={`FilterWrapper__List `}>
                        {searchNestedArray(
                          dataList,
                          ['service_category', 'service_groups', 'service_group', 'service_type_id', 'service_types'],
                          searchFilterTerm,
                        ).map((item, index) => {
                          return (
                            <Accordion
                              sx={{
                                margin: '0',

                                borderBottom: '1px solid var(--border-color-resource);',
                                background: 'var(--background-color)',
                                color: 'var(--text-color)',

                                '&.MuiAccordion-root:before': {
                                  display: 'none',
                                },

                                '&.MuiAccordion-root.Mui-expanded': {
                                  margin: '0',
                                },
                              }}
                              elevation={0}>
                              <AccordionSummary
                                aria-controls={'panel1a-content' + index}
                                id={'panel1a-header' + index}
                                style={{
                                  display: 'flex',
                                  itemAlign: 'center',
                                  margin: '0px',
                                  height: '45px',
                                }}
                                sx={{
                                  '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters': {
                                    margin: '0',
                                  },

                                  '&.Mui-expanded': {
                                    margin: '0',
                                  },
                                }}>
                                <Checkbox
                                  size="small"
                                  sx={{
                                    color: 'var(--text-tab-panel)',
                                    // width: '12px',
                                    // height: '12px',
                                  }}
                                  checked={item.checked}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleSelectCategory(item.service_category, !item.checked);
                                    setFilterType(true);
                                  }}
                                />
                                <div
                                  className="service_grid_list"
                                  style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                  <img
                                    src={item.service_icon}
                                    style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                  />
                                  <Text fontWeight="600" fontSize="14px">
                                    {item.service_category}
                                  </Text>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails
                                style={{
                                  paddingInline: '0px',
                                  paddingBlock: '0',
                                  marginInline: '0',
                                  marginBlock: '0',
                                }}>
                                {item.service_groups.map((service_group, index) => (
                                  <Accordion
                                    style={{
                                      boxShadow: 'none',
                                      paddingInline: '2rem 15px',
                                      margin: 0,
                                      borderTop: '1px solid var(--border-color-resource)',
                                      background: 'var(--background-color)',
                                      color: 'var(--text-color)',
                                    }}>
                                    <AccordionSummary
                                      expandIcon={
                                        <div
                                          className="expandIcon"
                                          style={{
                                            background: 'var(--background-color)',
                                            color: 'var(--text-color)',
                                            display: 'flex',
                                            flexWrap: 'nowrap',
                                          }}>
                                          <Text>{service_group.service_types.length} Types</Text>
                                          <ExpandMore />
                                        </div>
                                      }
                                      aria-controls={'panel1a-content' + index}
                                      id={'panel1a-header' + index}
                                      style={{
                                        display: 'flex',
                                        itemAlign: 'center',
                                        margin: '0px',
                                        paddingRight: '0rem',
                                        height: '40px',
                                      }}
                                      sx={{
                                        '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters':
                                          {
                                            margin: '0',
                                          },
                                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                          transform: 'rotate(0deg)',
                                          marginBlock: '0',
                                        },
                                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded > .expandIcon > .MuiSvgIcon-root':
                                          {
                                            transform: 'rotate(180deg)',
                                          },
                                      }}>
                                      <Checkbox
                                        size="small"
                                        sx={{
                                          color: 'var(--text-tab-panel)',
                                        }}
                                        checked={service_group.checked}
                                        onClick={e => {
                                          e.stopPropagation();
                                          handleSelectServiceGroup(
                                            item.service_category,
                                            service_group.service_group,
                                            !service_group.checked,
                                          );
                                          setFilterType(true);
                                        }}
                                      />
                                      <div
                                        className="service_grid_list"
                                        style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                        <div
                                          style={{
                                            width: '15px',
                                            height: '15px',
                                            marginRight: '10px',
                                            marginLeft: '10px',
                                          }}>
                                          <AWSServiceIcons
                                            name={service_group.service_group}
                                            width="15px"
                                            height="15px"
                                          />
                                        </div>

                                        <Text fontSize="14px" fontWeight="400">
                                          {service_group.service_group}
                                        </Text>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{
                                        paddingInline: '3rem 15px',
                                        paddingBlock: '0',
                                        background: 'var(--background-color)',
                                        color: 'var(--text-color)',
                                      }}>
                                      {service_group.service_types.map(service_type => (
                                        <div
                                          className="service_grid_list"
                                          style={{
                                            display: 'flex',
                                            width: '100%',
                                            alignItems: 'center',
                                            height: '40px',
                                          }}>
                                          <Checkbox
                                            size="small"
                                            sx={{
                                              color: 'var(--text-tab-panel)',
                                            }}
                                            checked={service_type.checked}
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleSelectServiceType(
                                                item.service_category,
                                                service_group.service_group,
                                                service_type.service_type_id,
                                                !service_type.checked,
                                              );
                                              setFilterType(true);
                                            }}
                                          />
                                          <div
                                            className="service_grid_list"
                                            style={{
                                              display: 'flex',
                                              width: '100%',
                                              alignItems: 'center',
                                            }}>
                                            <div
                                              style={{
                                                marginRight: '10px',
                                                marginLeft: '10px',
                                              }}>
                                              <AWSServiceIcons
                                                name={service_group.service_group}
                                                width="15px"
                                                height="15px"
                                              />
                                            </div>

                                            <Text fontSize="14px" fontWeight="400">
                                              {service_type.service_type_id}
                                            </Text>
                                          </div>
                                        </div>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* ---risk category------ */}
                {showRiskCategoryFilter && (
                  <Accordion
                    sx={{
                      borderBottom: '1px solid var(--border-color-resource);',
                      background: 'var(--background-color)',
                      color: 'var(--text-color)',

                      '&.MuiAccordion-root:before': {
                        display: 'none',
                      },

                      '&.MuiAccordion-root.Mui-expanded': {},
                      '& .MuiAccordionSummary-content.Mui-expanded': {
                        margin: '12px 0',
                      },
                      '& .MuiAccordionSummary-root.Mui-expanded': {
                        minHeight: 'unset',
                      },
                    }}
                    elevation={0}>
                    <AccordionSummary
                      aria-controls={'resource-filter-content'}
                      id={'resource-filter-header'}
                      style={{
                        display: 'flex',
                      }}
                      sx={{
                        padding: '0px',
                        '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters': {
                          display: 'flex',
                          justifyContent: 'space-between',
                          // margin: '15px',
                        },

                        '&.Mui-expanded': {},
                      }}
                      expandIcon={<FontAwesomeIcon icon={faAngleDoubleDown} className="ico" />}>
                      <div style={{ display: 'flex' }}>
                        <Text
                          font="bold"
                          fontSize="14px"
                          fontWeight="600"
                          style={{ display: 'flex', alignItems: 'center' }}>
                          Risk Categories
                        </Text>
                        <span
                          className="count_chip"
                          style={{
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: '500',
                            fontFamily: 'Manrope',
                            color: '#484D56',
                            border: '1px solid #CBD5E0',
                            boxShadow: '0px 8px 18px 0px rgba(93, 106, 131, 0.05)',
                            padding: '1px 8px 3px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '5px',
                          }}>
                          {`${checkedCategories.length} Selected`}
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        paddingInline: '0',
                        paddingBlock: '0',
                        marginInline: '0',
                        marginBlock: '0',
                      }}>
                      {/* <div className={`FilterWrapper__Search`} style={{ padding: '0px' }}>
                        <Searchbar
                          height={'40px'}
                          placeholder={'Search service..'}
                          value={searchFilterTerm}
                          onChange={e => setSearchFilterTerm(e.target.value)}
                        />
                      </div> */}
                      <div className={`FilterWrapper__List `}>
                        {riskCategories.map((item, index) => {
                          return (
                            <Accordion
                              sx={{
                                margin: '0',

                                borderBottom: '1px solid var(--border-color-resource);',
                                background: 'var(--background-color)',
                                color: 'var(--text-color)',

                                '&.MuiAccordion-root:before': {
                                  display: 'none',
                                },

                                '&.MuiAccordion-root.Mui-expanded': {
                                  margin: '0',
                                },
                              }}
                              elevation={0}>
                              <AccordionSummary
                                aria-controls={'panel1a-content' + index}
                                id={'panel1a-header' + index}
                                style={{
                                  display: 'flex',
                                  itemAlign: 'center',
                                  margin: '0px',
                                  height: '45px',
                                }}
                                sx={{
                                  '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters': {
                                    margin: '0',
                                  },

                                  '&.Mui-expanded': {
                                    margin: '0',
                                  },
                                }}>
                                <Checkbox
                                  size="small"
                                  sx={{
                                    color: 'var(--text-tab-panel)',
                                    // width: '12px',
                                    // height: '12px',
                                  }}
                                  checked={item.checked}
                                  onChange={handleCheckboxChange(index)}
                                />
                                <div
                                  className="service_grid_list"
                                  style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                  <img
                                    src={active_risk_mapping[item.risk_category]}
                                    style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                  />
                                  <Text fontWeight="600" fontSize="14px">
                                    {item.risk_category}
                                  </Text>
                                </div>
                              </AccordionSummary>
                            </Accordion>
                          );
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* ----property filter---- */}

                <Accordion
                  sx={{
                    borderBottom: '1px solid var(--border-color-resource);',
                    background: 'var(--background-color)',
                    color: 'var(--text-color)',

                    '&.MuiAccordion-root:before': {
                      display: 'none',
                    },
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      margin: '0px',
                    },
                    '& .MuiAccordionSummary-root.Mui-expanded': {
                      minHeight: '52px',
                    },
                    '& .MuiPaper-root-MuiAccordion-root.Mui-expanded': {
                      margin: '0px',
                    },
                  }}
                  elevation={0}>
                  <AccordionSummary
                    aria-controls={'resource-filter-content'}
                    id={'resource-filter-header'}
                    style={{
                      display: 'flex',
                    }}
                    sx={{
                      padding: '0px',
                      '& .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters': {
                        display: 'flex',
                        marginRight: '15px',
                      },
                      '& .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginRight: '15px',
                      },
                    }}
                    expandIcon={<FontAwesomeIcon icon={faAngleDoubleDown} className="ico" />}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <div style={{ display: 'flex' }}>
                        <Text
                          font="bold"
                          fontSize="14px"
                          fontWeight="600"
                          style={{ display: 'flex', alignItems: 'center' }}>
                          Properties
                        </Text>
                        <span
                          className="count_chip"
                          style={{
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: '500',
                            fontFamily: 'Manrope',
                            color: '#484D56',
                            border: '1px solid #CBD5E0',
                            boxShadow: '0px 8px 18px 0px rgba(93, 106, 131, 0.05)',
                            padding: '1px 8px 3px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '5px',
                          }}>
                          {`${
                            isCriticalTab
                              ? selectedFields.filter(item => item.isApplied).length - 1
                              : selectedFields.filter(item => item.isApplied).length
                          } Selected`}
                        </span>
                      </div>

                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          fontFamily: 'Manrope',
                          color: '#194BFB',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={ResetFilter}>
                        {' '}
                        Clear All{' '}
                      </span>
                    </div>

                    {/* <Text
                    color={'#A0AEC0'}
                  >
                    Clear All
                  </Text> */}
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      paddingInline: '0',
                      paddingBlock: '0',
                      marginInline: '0',
                      marginBlock: '0',
                      borderTop: '1px solid #CBD5E0',
                    }}>
                    <Text
                      className=""
                      style={{ fontSize: '14px', fontWeight: '600', color: '#1A202C', padding: '15px 0px' }}>
                      Match{' '}
                      <ButtonGroup
                        variant="outlined"
                        size="small"
                        style={{
                          marginLeft: '16px',
                          marginRight: '8px',
                          border: '1px solid var(--border-color-resource)',
                          height: '26px',
                        }}>
                        <Button
                          variant={any ? 'contained' : null}
                          style={{ textTransform: 'none' }}
                          disabled={selectedFields.length <= 1}
                          onClick={() => handleAnyToggle('any')}
                          sx={{
                            bgcolor: any ? '#194BFB' : null,
                          }}>
                          Any
                        </Button>
                        <Button
                          variant={!any ? 'contained' : null}
                          style={{ textTransform: 'none' }}
                          onClick={() => handleAnyToggle('all')}
                          sx={{
                            bgcolor: !any ? '#194BFB' : null,
                          }}>
                          All
                        </Button>
                      </ButtonGroup>{' '}
                      of the following
                    </Text>

                    <Text className="" style={{ fontSize: '14px', fontWeight: '500', color: '#484D56' }}>
                      Multiple values selected for the same property are always OR'ed by the filtering algorithm.
                    </Text>

                    <div style={{ marginTop: '16px' }}>
                      {selectedFields.map((f, index) => (
                        <FilterItem
                          fields={propertyKeys}
                          noFormat={noFormat}
                          field={f.field}
                          options={propertyValues}
                          value={f.value}
                          handleRemoveFieldClick={handleRemoveFieldClick}
                          handleFieldChange={handleFieldChange}
                          index={index}
                        />
                      ))}

                      {!selectedFields.length ? (
                        <div style={{ textAlign: 'center', paddingTop: '16px', paddingBottom: '16px' }}>
                          Add an option to start filtering
                        </div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '16px',
                        // paddingLeft: '16px',
                        // paddingRight: '16px',
                      }}>
                      <ButtonIcon
                        icon={faCheck}
                        disabled={
                          selectedFields[0]?.field.id === '' ||
                          selectedFields[0]?.value.id === '' ||
                          selectedFields.length === 0
                            ? true
                            : false
                        }
                        onClick={handleApplyProperties}
                        style={{
                          marginRight: '16px',
                          background: '#194BFB',
                          color: '#FFFFFF',
                          padding: '10px',
                          height: '36px',
                          fontSize: '14px',
                          fontWeight: '600',
                          fontFamily: 'Manrope',
                        }}>
                        Apply Properties
                      </ButtonIcon>

                      <ButtonIcon
                        icon={faPlus}
                        onClick={handleAddFieldClick}
                        disabled={isAnyFieldsEmpty}
                        style={{
                          marginRight: '16px',
                          background: '#F7FAFC',
                          color: '#484D56',
                          border: '1px solid #CBD5E0',
                          padding: '10px',
                          height: '36px',
                          fontSize: '14px',
                          fontWeight: '600',
                          fontFamily: 'Manrope',
                        }}>
                        Add Property
                      </ButtonIcon>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`FilterWrapper__ContentContainer`}
          style={{
            width: '100%',
          }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Filter;

function searchNestedArray(objects, searchKeys, searchString) {
  return objects.filter(object =>
    searchKeys.some(key => {
      if (typeof object[key] === 'string') {
        return object[key].toLowerCase().includes(searchString.toLowerCase());
      } else if (Array.isArray(object[key])) {
        return searchNestedArray(object[key], searchKeys, searchString).length > 0;
      }
    }),
  );
}
