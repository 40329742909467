import React, { useState, useEffect } from 'react';

const DescriptionToggle = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = 240;

  useEffect(() => {
    setIsExpanded(false);
  }, [text]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isTextShortEnough = text.length <= MAX_LENGTH;

  return (
    <>
      <p
        style={{
          fontFamily: 'Manrope',
          fontSize: '14px',
          color: '#767980',
          padding: '15px 20px 20px 20px',
          borderBottom: '1px solid #CBD5E0',
        }}>
        {isExpanded || isTextShortEnough ? text : `${text.substring(0, MAX_LENGTH)}...`}
        {!isTextShortEnough && (
          <span
            style={{
              fontFamily: 'Manrope',
              fontSize: '14px',
              color: '#194BFB',
              cursor: 'pointer',
              marginLeft: '3px',
            }}
            onClick={handleToggle}>
            {isExpanded ? 'Less' : 'More'}
          </span>
        )}
      </p>
    </>
  );
};

export default DescriptionToggle;
