import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
// import PieCharts from '@root/pages/inventory1.3/charts/PieChart';
import { Box, Grid } from '@mui/material';
import agent from '@root/agent';
// import PieChart from '@root/components/v2/charts/PieChart';
// import { PieChart } from '@mui/x-charts';
import ContentBox from '@root/components/v2/content/ContentBox';
// import Text from '@root/components/v2/ui-elements/Text';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { makeOpenThreatsAndAnamolyKey } from '@root/query/dashboard';
import { useQuery } from '@tanstack/react-query';

// import Loader from '@root/components/common/Loader';
import NoData from '@root/components/common/NoData';

import './CQShieldPriority.scss';
import { OpenThreatSkeleton } from '../../skeleton';

dayjs.extend(utc);
dayjs.extend(timezone);
// const TIMEZONE = dayjs.tz.guess();

const colorMapping = {
  Critical: '#CD0404',
  High: '#F62121',
  Medium: '#FFA825',
  Low: '#E8DF07',
  Info: '#476FFC',
  Warning: '#ED5656',
};

const InfoComponent = ({ severity, severity_count, onClick }) => {
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      onClick(severity.toLowerCase());
    },
    [severity, onClick],
  );
  return (
    <Box
      onClick={severity_count > 0 ? handleClick : () => {}}
      sx={{
        height: '88px',
        border: '1px solid #CBD5E0',
        borderRadius: '8px',
        width: '33%',
        cursor: severity_count ? 'pointer' : 'default',
        padding: '12px 16px 12px 16px',
        '&:hover': {
          boxShadow: severity_count > 0 ? '0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -1px rgba(0, 0, 0, 0.12)' : '',
        },
      }}>
      <Box sx={{ dislay: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '20px' }}>
          <Box
            sx={{
              height: '12px',
              width: '12px',
              borderRadius: '100%',
              backgroundColor: colorMapping[severity],
            }}></Box>
          <p
            style={{
              padding: 0,
              margin: 0,
              marginLeft: '8px',
              lineHeight: '20px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              fontSize: '14px',
              color: ' rgb(26, 32, 44)',
            }}>
            {severity}
          </p>
        </Box>
        <p
          style={{
            padding: 0,
            margin: 0,
            marginTop: '8px',
            fontFamily: 'Manrope',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            textAlign: 'left',
            color: '#242424',
          }}>
          {severity_count}
        </p>
      </Box>
    </Box>
  );
};

type Props = {
  cloudProvider: string;
  dateFilter: string;
  timezone: any;
};

function OpenThreatsAndAnamoly({ cloudProvider, timezone }: Props) {
  const history = useHistory();
  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();
  // const [selectedValue, setSelectedValue] = useState('');
  const queryEnabled = useMemo(
    () =>
      Boolean(
        (selectedEnvironments?.length && cloudProvider === 'all') || cloudProvider === 'aws' || cloudProvider === 'gcp',
      ),
    [cloudProvider, selectedEnvironments],
  );

  const { data: openThreatsData, isFetching } = useQuery(
    makeOpenThreatsAndAnamolyKey(
      selectedEnvironmentTags,
      selectedEnvironments?.map((e: any) => e.id) || [],
      cloudProvider,
      timezone,
    ),
    () =>
      agent.Dashboard_New.get_open_threat_anamoly({
        scope: selectedEnvironmentTags,
        environments: selectedEnvironments?.map((e: any) => e.id) || [],
        cloud_provider: cloudProvider,
        filter: '90days',
        timezone: timezone,
      }),
    {
      enabled: queryEnabled,
    },
  );

  const _openThreatsData = useMemo(() => {
    return {
      critical: openThreatsData?.data?.threat_by_severity?.critical || 0,
      high: openThreatsData?.data?.threat_by_severity?.high || 0,
      low: openThreatsData?.data?.threat_by_severity?.low || 0,
      medium:
        openThreatsData?.data?.threat_by_severity?.meidum || openThreatsData?.data?.threat_by_severity?.medium || 0,
      warning: openThreatsData?.data?.threat_by_severity?.warning || 0,
      info: openThreatsData?.data?.threat_by_severity?.info || 0,
      total: openThreatsData?.data?.threat_by_severity?.total_threats || 0,
    };
  }, [openThreatsData]);

  const handleCountClick = useCallback(
    (priority: any) => {
      history.push(`/events/all?tab=Threat&severity=${priority}&fromDashBoardOpenThreats=true`);
    },
    [history],
  );

  // const valueArray = useMemo(() => {
  //   return [
  //     { value: _openThreatsData.critical, name: 'Critical' },
  //     { value: _openThreatsData.high, name: 'High' },
  //     { value: _openThreatsData.medium, name: 'Medium' },
  //     { value: _openThreatsData.low, name: 'Low' },
  //     { value: _openThreatsData.warning, name: 'Warning' },
  //     { value: _openThreatsData.info, name: 'Info' },
  //   ];
  // }, [_openThreatsData]);

  return (
    <Grid xs={6} item>
      <ContentBox
        title="Open Threats / Anomaly"
        height="264px"
        containerStyle={{ display: 'flex', flexDirection: 'column', borderRadius: '5px' }}
        paddingMode="content">
        {cloudProvider === 'azure' ? (
          <NoData height={'100%'} />
        ) : isFetching ? (
          <OpenThreatSkeleton />
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              padding: '15px',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',

            }}>
             <Box display={'flex'} flexDirection={'column'} width={'100%'} justifyContent={'center'} gap={'12px'}>
               <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} gap={'12px'}>
                 <InfoComponent severity={'Critical'} onClick={handleCountClick} severity_count={_openThreatsData.critical} />
                 <InfoComponent severity={'High'} onClick={handleCountClick} severity_count={_openThreatsData.high} />
                 <InfoComponent severity={'Medium'} onClick={handleCountClick} severity_count={_openThreatsData.medium} />

               </Box>
               <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'}gap={'12px'}>
                 <InfoComponent severity={'Low'} onClick={handleCountClick} severity_count={_openThreatsData.low} />
                 <InfoComponent severity={'Warning'} onClick={handleCountClick} severity_count={_openThreatsData.warning} />
                 <InfoComponent severity={'Info'} onClick={handleCountClick} severity_count={_openThreatsData.info} />

               </Box>
             </Box>
          
          </Box>
        )}
      </ContentBox>
    </Grid>
  );
}

export default OpenThreatsAndAnamoly;
