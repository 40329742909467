import { Box } from '@mui/material';

import NetworkingIcon from '@root/assets/svg/Service_Category_Icons/Networking.svg';
import ComputeIcon from '@root/assets/svg/Service_Category_Icons/Compute.svg';
import DeveloperIcon from '@root/assets/svg/Service_Category_Icons/Developer_Tools.svg';
import MachineLearningIcon from '@root/assets/svg/Service_Category_Icons/Machine_Learning.svg';
import StorageIcon from '@root/assets/svg/Service_Category_Icons/Storage.svg';
import OperationIcon from '@root/assets/svg/Service_Category_Icons/Operations.svg';
import SecurityIdentityIcon from '@root/assets/svg/Service_Category_Icons/Security_Identity.svg';
import ServerlessIcon from '@root/assets/svg/Service_Category_Icons/Serverless.svg';
import ManagementToolsIcon from '@root/assets/svg/Service_Category_Icons/Management_Tools.svg';
import ContainersIcon from '@root/assets/svg/Service_Category_Icons/Containers.svg';
import Analytics from '@root/assets/svg/Service_Category_Icons/Data_Analytics.svg';
import Database from '@root/assets/svg/Service_Category_Icons/Database.svg';
import athena from '@root/assets/img/aws_services/aws_athena.svg';
import './components.scss';
import { useQuery } from '@tanstack/react-query';
import agent from '@root/agent';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import ContentBox from '@root/components/v2/content/ContentBox';
import { InventoryComponentSkeleton } from '../../skeleton';
import NoData from '@root/components/common/NoData';

const serviceGridIcons = {
  [`Networking`]: NetworkingIcon,
  ['Operations']: OperationIcon,
  ['Developer Tools']: DeveloperIcon,
  ['Machine Learning']: MachineLearningIcon,
  ['Storage']: StorageIcon,
  ['Compute']: ComputeIcon,
  ['Security & Identity']: SecurityIdentityIcon,
  ['Serverless']: ServerlessIcon,
  ['Management Tools']: ManagementToolsIcon,
  ['Containers']: ContainersIcon,
  ['Data Analytics']: Analytics,
  ['Database']: Database,
  ['Athena']: athena,
};

const InventoryComponent = ({ cloudProvider, dateFilter, selectedValue, regions }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();

  const queryEnabled = useMemo(
    () =>
      Boolean(
        (selectedEnvironments?.length && cloudProvider === 'all') || cloudProvider === 'aws' || cloudProvider === 'gcp',
      ),
    [cloudProvider, selectedEnvironments],
  );
  const history = useHistory();
  const handleItemClick = useCallback(
    item => {
      const url = buildBreadcrumbUrl({
        path: `/inventory/resources-table?from=dashboardinventory&category=${
          item?.service_category.includes('&') ? 'Security and Identity' : item?.service_category
        }`,
        breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Resources' }, { title: item?.service_category }],
      });
      history.push(url);
    },
    [history],
  );
  const handleThreatClick = useCallback(
    item => {
      const url = buildBreadcrumbUrl({
        path: `/inventory/resources-table?from=dashboardInventory&category=${
          item?.service_category.includes('&') ? 'Security and Identity' : item?.service_category
        }&risk=Threat`,
        breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Resources' }, { title: item?.service_category }],
      });
      history.push(url);
    },
    [history],
  );
  const { data: inventoryCardData, isFetching } = useQuery(
    [
      'get-inventory-card',
      selectedEnvironmentTags,
      selectedEnvironments?.map(e => e.id) || [],
      cloudProvider,
      regions,
      dateFilter,
      selectedValue,
    ],
    () =>
      agent.Dashboard_New.get_inventory_card({
        scope: selectedEnvironmentTags,
        environments: selectedEnvironments?.map(e => e.id) || [],
        cloud_provider: cloudProvider,
        regions,
        assessment_time_filter: selectedValue,
        date_filter: dateFilter,
      }),
    {
      enabled: queryEnabled,
    },
  );
  let totalResourceCount = 0;
  if (!isFetching) {
    totalResourceCount = inventoryCardData?.data?.reduce(
      (previousValue, currentValue) => previousValue + currentValue?.resources_count,
      0,
    );
  }
  if (isFetching) return <InventoryComponentSkeleton />;
  return (
    <ContentBox
      titleSize="medium"
      titleContainerStyle={{
        // border: "0px",
        fontSize: '16px !important',
        // paddingRight: '6px',
        color: 'var(--keyinsight-text-color)',
        // background: '#F7FAFC',
        // backgroundColor: 'var(--keyinsight-box-header-background)',
      }}
      title={'Inventory'}
      headerEndElement={
        <span
          style={{
            cursor: 'pointer',
            fontFamily: 'Manrope',
            color: '#1A202C',
            fontSize: '14px',
            fontWeight: 800,
          }}>
          {' '}
          {totalResourceCount}
        </span>
      }
      paddingMode="content"
      containerClassName={'border-box-no-pad'}
      style={{
        height: '512px',
        width: '100%',
      }}>
      {inventoryCardData?.data.length > 0 ? (
        <Box sx={{ padding: '15px', width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {inventoryCardData?.data?.map(inventoryItem => (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '8px',
                  width: '160px',
                  paddingRight: '0px',
                }}>
                <img src={serviceGridIcons[`${inventoryItem.service_category}`]} alt="" />
                <p
                  style={{
                    padding: '0',
                    margin: '0',
                    fontWeight: 600,
                    fontFamily: 'Manrope',
                    color: '#767980',
                    fontSize: '14px',
                  }}>
                  {inventoryItem?.service_category}
                </p>
              </Box>
              {inventoryItem?.threat_count > 0 && (
                <Box
                  onClick={() => {
                    // set resource ids in localStorage
                    handleThreatClick(inventoryItem);
                  }}
                  sx={{
                    width: windowWidth > 1538 ? '86px' : '32px',
                    height: '24px',
                    borderRadius: '27px',
                    padding: '4px 12px 4px 12px',
                    gap: '10px',
                    backgroundColor: '#FEECEB',
                    cursor: 'pointer',
                  }}>
                  {windowWidth > 1538 ? (
                    <p
                      style={{
                        padding: '0',
                        margin: '0',
                        width: '64px',
                        height: '16px',
                        fontFamily: 'Manrope',
                        fontWeight: 500,
                        fontSize: '12px',
                        color: '#B12F30',
                        textAlign: 'center',
                      }}>
                      {`${inventoryItem?.threat_count} Threat${inventoryItem?.threat_count > 1 ? 's' : ''}`}
                    </p>
                  ) : (
                    <Tooltip title={`Threat${inventoryItem?.threat_count > 1 ? 's' : ''}`}>
                      <p
                        style={{
                          padding: '0',
                          margin: '0',
                          width: '8px',
                          height: '16px',
                          fontFamily: 'Manrope',
                          fontWeight: 500,
                          fontSize: '12px',
                          color: '#B12F30',
                          textAlign: 'center',
                        }}>
                        {`${inventoryItem?.threat_count}`}
                      </p>
                    </Tooltip>
                  )}
                </Box>
              )}

              <p
                style={{
                  margin: '0',
                  padding: '0',
                  fontFamily: 'Manrope',
                  fontSize: '14px',
                  fontWeight: 600,
                  color: '#194BFB',
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'red',
                  },
                }}
                onClick={() => {
                  // set resource ids in localStorage

                  handleItemClick(inventoryItem);
                }}>
                {inventoryItem?.resources_count}
              </p>
            </Box>
          ))}
        </Box>
      ) : (
        <NoData />
      )}
    </ContentBox>
  );
};
export default InventoryComponent;
