import React, { useState, useEffect } from 'react';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';

const LinkToggle = ({ links }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    setIsExpanded(false);
  }, [links]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const receivedLinks = links?.split(',');
  const displayedItems = isExpanded ? receivedLinks : receivedLinks?.slice(0, 2);

  return (
    <>
      {displayedItems?.map((link, index) => (
        <Tooltip key={index} title={link.trim()}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link.trim()}
            className="description overflow-description"
            style={{ color: 'rgb(25, 75, 251)', display: 'block', margin: '5px 0' }}>
            {link.trim()}
          </a>
        </Tooltip>
      ))}
      {receivedLinks?.length > 2 && (
        <span
          onClick={handleToggle}
          style={{
            fontFamily: 'Manrope',
            fontSize: '14px',
            color: '#194BFB',
            cursor: 'pointer',
            marginLeft: '3px',
            display: 'block',
            margin: '5px 0',
          }}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </span>
      )}
    </>
  );
};

export default LinkToggle;
