import { useCallback, useEffect, useMemo, useState } from 'react';

import { ChevronRight, ExpandMore } from '@mui/icons-material';

import _ from 'lodash';
import { ExpandRow } from './report/expand';
import { makeColumns } from './report/policies-columns';

import { Box } from '@mui/material';

import agent from '@root/agent';
import { useHistory } from 'react-router-dom';

import { useOCRPermissions } from '@root/hooks/permissions';
import { useQuery } from '@root/hooks/useQuery';
import SelectedRegionsModal from './newPolicy/components/SelectedRegionsModal';

import HeaderBarNew from '@components/HeaderBarNew';
import MuiTable from '@components/table/table';
import CloudProviderBar from '@root/components/v2/content/CloudProviderBar';
import ContentBox from '@root/components/v2/content/ContentBox';
import LastUpdate from '@root/components/v2/content/LastUpdate';
import PageContainer from '@root/components/v2/layout/PageContainer';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeader from '@root/components/v2/layout/PageHeader';
import { useQuery as useQueryReact } from '@tanstack/react-query';

import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import NoData from '@root/components/common/NoData';
import './style.scss';

import Column from '@root/components/v2/layout/columns/Column';
import ColumnsContainer from '@root/components/v2/layout/columns/ColumnsContainer';

import Filter from '@root/components/Filter';
import TableSkeleton from '@root/components/table/skeleton';
import useTimeFilter from '@root/hooks/SelectTimeFilter';
import { useEnvironments, useSelectedEnvironments } from '@root/hooks/environments';
import PolicySkeleton from './PolicySkeleton';
import { ExportPipe } from './export';
import Breadcrumbs from '@root/components/v2/global/breadcrumbs';
import { useCloudProvider } from '@root/components/v2/content/CloudProviderBar/CloudProviderContext';

const DEFAULT_PINNED_COLUMNS = {
  left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field],
};

const BREADCRUMBS = [{ title: 'Policies' }];

const RemediationPolicy = () => {
  // const cloudProvider = useSelector(state => state.oneClick.cloud);
  // const [cloudProvider, setCloudProvider] = useState('aws');
  const { cloudProvider } = useCloudProvider();
  const urlQuery = useQuery();
  const policy_id = urlQuery.get('policy_id');

  const history = useHistory();
  const { isCreateAllowed } = useOCRPermissions();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [setPolicies] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [isRevokeModalOpen, setRevokeModalOpen] = useState(false);
  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();
  const allEnvironments = useEnvironments();
  const { timeFilter } = useTimeFilter();
  const environments = useMemo(() => {
    if (selectedEnvironments.length) return selectedEnvironments;
    return allEnvironments;
  }, [selectedEnvironments, allEnvironments]);
  const [selectedFilterServices, setSelectedFilterServices] = useState([]);
  const [selectedFilterServiceCategory, setSelectedFilterServiceCategory] = useState([]);
  const [selectedFilterServiceTypes, setSelectedFilterServiceTypes] = useState([]);
  const [propertiesFilter, setPropertiesFilter] = useState({
    rule_id: [],
    region: [],
    severity: [],
    group: [],
    match: 'any',
  });
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  // Expanded view =============
  const [selectedPolicyForViewRegionsList, setSelectedPolicyForViewRegionsList] = useState(null);

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const [ruleStatusData] = useState([]);
  const [exceptionData] = useState([]);

  const [pinnedColumns, setPinnedColumns] = useState(DEFAULT_PINNED_COLUMNS);

  const _pinnedColumns = useMemo(() => {
    if (pinnedColumns.left.length === 1) {
      if (pinnedColumns.left[0] === GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field) {
        return { left: [], right: pinnedColumns.right || [] };
      } else {
        return { ...pinnedColumns, left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, pinnedColumns.left[0]] };
      }
    } else {
      return pinnedColumns;
    }
  }, [pinnedColumns]);

  useEffect(() => {
    if (policy_id) setSearchTerm(policy_id);
  }, [policy_id]);

  const ruleStatusDataMap = useMemo(() => {
    const data = {};

    for (const d of ruleStatusData) {
      data[d.masterId] = {
        isDisabled: Boolean(d.is_exception),
      };
    }

    return data;
  }, [ruleStatusData]);

  const exceptionDataMap = useMemo(() => {
    const data = {};

    for (const d of exceptionData) {
      for (const r of d.rule) {
        data[r] = true;
      }
    }

    return data;
  }, [exceptionData]);

  // api calls
  const { data: eventFilterServiceCategory, isLoading: isLoadingEventFilterServiceCategory } = useQueryReact(
    ['get_policy_service_category', environments.map(e => e.id), selectedEnvironmentTags, cloudProvider, timeFilter],
    () =>
      agent.Policy.get_policy_service_category({
        scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
      }),
  );
  const { data: policyFilterPropertyKeys, isLoading: isLoadingPolicyFilterPropertyKeys } = useQueryReact(
    ['get_policy_filter_property_keys', environments.map(e => e.id), selectedEnvironmentTags, cloudProvider],
    () => agent.Policy.get_policy_property_keys({}),
  );
  const { data: policyFilterPropertyValues, isFetching: isFetchingPolicyFilterPropertyValues } = useQueryReact(
    [
      'get_policy_filter_property_Values',
      cloudProvider,
      environments.map(e => e.id),
      selectedEnvironmentTags,
      selectedFilterServiceCategory,
    ],
    () => agent.Policy.get_policy_property_values({}),
  );

  const {
    data: tableData,
    isFetching: isFetchingTableData,
    isLoading: isLoadingTableData,
  } = useQueryReact(
    [
      'get_table_data',
      environments.map(e => e.id),
      selectedEnvironmentTags,
      cloudProvider,
      propertiesFilter?.rule_id,
      propertiesFilter?.group,
      propertiesFilter?.region,
      propertiesFilter?.severity,
      timeFilter,
      searchTerm,
      page,
      pageSize,

      selectedFilterServiceCategory,
      selectedFilterServiceTypes,
      selectedFilterServices,
      propertiesFilter.match,
      history.action === 'POP',
      isRevokeModalOpen,
    ],
    () =>
      agent.Policy.get_table_data({
        scope: selectedEnvironmentTags,
        scope_environment_id: environments.map(e => e.id),
        cloud_provider: cloudProvider,

        search_keyword: searchTerm ? searchTerm : '',

        all_resource: {
          service_category: selectedFilterServiceCategory,
          service: selectedFilterServices,
          resource_type: selectedFilterServiceTypes,
        },
        date_filter: 'all',
        // policy_id: policy_id ?? '',

        properties_filter: propertiesFilter,
        unique_id: '121',
        page_no: page,
        row_per_page: pageSize,
      }),
    {
      cacheTime: 0, // Disable caching
      staleTime: 0, // Consider data stale immediately
    },
  );
  const handleUpdatePropertiesFilter = values => {
    setPropertiesFilter(prev => {
      Object.keys(prev).map(key => {
        if (Object.keys(values).includes(key)) {
          prev[key] = values[key];
        } else {
          prev[key] = [];
        }
      });
      return { ...prev };
    });
  };

  const handlePolicyRevoked = useCallback(
    id => {
      setPolicies(prev => prev.filter(p => p.id !== id));
    },
    [setPolicies],
  );

  const handlePageChange = page => {
    if (isFetchingTableData) return;
    setPage(page);
  };
  const updateFilterAllResources = (service_category, service, resource_type) => {
    setSelectedFilterServiceCategory(
      !_.isEmpty(resource_type) && resource_type?.length > 0 ? [] : [...service_category],
    );
    setSelectedFilterServices(!_.isEmpty(resource_type) && resource_type?.length > 0 ? [] : [...service]);
    setSelectedFilterServiceTypes(!_.isEmpty(resource_type) && resource_type?.length > 0 ? resource_type : []);

    setPage(1);
  };
  useEffect(() => {
    selectedFilterServiceCategory.length > 0 ||
    selectedFilterServices.length > 0 ||
    selectedFilterServiceTypes.length > 0 ||
    propertiesFilter.rule_id.length > 0 ||
    propertiesFilter.group.length > 0 ||
    propertiesFilter.region.length > 0 ||
    propertiesFilter.severity.length > 0
      ? setIsFilterSelected(true)
      : setIsFilterSelected(false);
  }, [
    selectedFilterServiceCategory,
    selectedFilterServices,
    selectedFilterServiceTypes,
    propertiesFilter.rule_id,
    propertiesFilter.region,
    propertiesFilter.severity,
    propertiesFilter.group,
  ]);
  const handleExportData = async (data, type) => {
    if (type === 'all') {
      const exportTableData = await agent.Policy.get_table_data({
        scope: selectedEnvironmentTags,
        scope_environment_id: environments.map(e => e.id),
        cloud_provider: cloudProvider,

        search_keyword: searchTerm ? searchTerm : '',

        all_resource: {
          service_category: [],
          service: [],
          resource_type: [],
        },
        date_filter: 'all',
        // policy_id: policy_id ?? '',

        properties_filter: { rule_id: [], region: [], severity: [], match: 'any' },
        unique_id: '121',
        page_no: 1,
        row_per_page: 1000,
      });

      ExportPipe(exportTableData?.data);
    } else {
      const exportTableData = await agent.Policy.get_table_data({
        scope: selectedEnvironmentTags,
        scope_environment_id: environments.map(e => e.id),
        cloud_provider: cloudProvider,

        search_keyword: searchTerm ? searchTerm : '',

        all_resource: {
          service_category: selectedFilterServiceCategory,
          service: selectedFilterServices,
          resource_type: selectedFilterServiceTypes,
        },
        date_filter: 'all',
        // policy_id: policy_id ?? '',

        properties_filter: propertiesFilter,
        unique_id: '121',
        page_no: 1,
        row_per_page: 1000,
      });
      ExportPipe(exportTableData?.data);
    }
  };
  const propertyValue = useMemo(() => {
    if (policyFilterPropertyValues?.data) {
      const newValues = {
        ...policyFilterPropertyValues?.data,
        group: policyFilterPropertyValues?.data.user_group,
      };
      delete newValues['user_group'];
      return newValues;
    } else {
      return [];
    }
  }, [policyFilterPropertyValues]);

  if (
    isLoadingPolicyFilterPropertyKeys &&
    isFetchingPolicyFilterPropertyValues &&
    isLoadingTableData &&
    isLoadingEventFilterServiceCategory
  ) {
    return <PolicySkeleton />;
  }

  return (
    <PageContainer>
      <PageHeader title={<Breadcrumbs breadcrumbs={BREADCRUMBS} />} />

      <PageContent>
        <CloudProviderBar
          // cloudProvider={cloudProvider}
          // onChange={e => setCloudProvider(e)}
          rightElement={
            <LastUpdate
              date={cloudProvider !== 'azure' && tableData?.data && tableData?.data.length !== 0 ? new Date() : null}
            />
          }
        />

        <ContentBox>
          {!(cloudProvider !== 'azure') ? (
            <div style={{ height: '80vh', display: 'grid', placeItems: 'center' }}>
              <NoData />
            </div>
          ) : (
            <>
              <HeaderBarNew
                title={''}
                showCreatePolicyButton
                isCreatePolicyAllowed={isCreateAllowed}
                searchBar
                searchBarValue={searchTerm}
                setSearchTerm={setSearchTerm}
                onSearchBarSubmit={e => e.preventDefault()}
                exportButton={true}
                handleExportData={handleExportData}
                isFilterSelected={isFilterSelected}
                searchBarOnChange={e => setSearchTerm(e.target.value)}
                isFilterActive={drawerOpen}
                onFilterButtonClick={() => {
                  setDrawerOpen(prev => !prev);
                }}
              />
              <div id="remediationPolicy">
                {/* <div className={`FilterWrapper ${drawerOpen ? 'FilterOpen' : ''}`}> */}
                {/* <div className={`FilterWrapper__Content ${!urlQuery.get('policy_id') ? '' : 'FilterAbsent'}`}> */}
                <div style={{ height: '80vh' }}>
                  <ColumnsContainer columnSeparation={'5px'}>
                    <Column>
                      <Filter
                        data={eventFilterServiceCategory?.data}
                        propertyKeys={policyFilterPropertyKeys?.data.list_property_keys}
                        propertyValues={propertyValue}
                        updateFilterAllResources={updateFilterAllResources}
                        selectedFilterProperties={propertiesFilter}
                        handleUpdatePropertiesFilter={handleUpdatePropertiesFilter}
                        open={drawerOpen}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: '80vh' }}>
                          {isFetchingTableData ? (
                            <Box position={'relative'} height={'100%'} width={'100%'}>
                              <TableSkeleton />
                            </Box>
                          ) : (
                            <MuiTable
                              isRowSelectable={false}
                              components={{
                                DetailPanelExpandIcon: () => (
                                  <div>
                                    <ChevronRight />
                                  </div>
                                ),
                                DetailPanelCollapseIcon: ExpandMore,
                                NoRowsOverlay: () => <NoData />,
                              }}
                              getRowClassName={params => {
                                return `bl-${params.row.risk.toLowerCase()} bl-base`;
                              }}
                              rowThreshold={0}
                              getDetailPanelHeight={() => {
                                return 'auto';
                              }}
                              header
                              pagination={true}
                              rowsPerPageOptions={[10, 15, 50, 100]}
                              // total_size={}
                              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                              pageSize={pageSize}
                              page={page}
                              paginationMode="server"
                              rows={tableData?.data ? tableData?.data : []}
                              columns={makeColumns({
                                onPolicyRevoked: handlePolicyRevoked,
                                selectedPolicyForViewRegionsList,
                                isRevokeModalOpen,
                                setRevokeModalOpen,
                              })}
                              getDetailPanelContent={row =>
                                ExpandRow({
                                  row: row.row,
                                  selectedPolicyForViewRegionsList,
                                  setSelectedPolicyForViewRegionsList,
                                  expandedRowIds,
                                  ruleStatusDataMap,
                                  exceptionDataMap,
                                  onExpanded: (row, isExpand) => {
                                    setExpandedRowIds(prev => {
                                      if (isExpand) {
                                        if (prev.find(id => id === row.policy_id)) {
                                          return prev;
                                        } else {
                                          return [...prev, row.policy_id];
                                        }
                                      } else {
                                        return prev.filter(id => id !== row.policy_id);
                                      }
                                    });
                                  },
                                })
                              }
                              pinnedColumns={_pinnedColumns}
                              total_size={tableData?.num_records}
                              onPinnedColumnsChange={setPinnedColumns}
                              onPageChange={handlePageChange}
                              // onTableChange={handleTableChange}
                              rowHeight={80}
                            />
                          )}
                        </div>
                      </Filter>
                    </Column>
                  </ColumnsContainer>
                </div>
                {/* </div> */}
                {/* </div> */}
                {selectedPolicyForViewRegionsList ? (
                  <SelectedRegionsModal
                    open={Boolean(selectedPolicyForViewRegionsList.data)}
                    regions={
                      selectedPolicyForViewRegionsList.data.regions
                        ? selectedPolicyForViewRegionsList.data.regions
                        : selectedPolicyForViewRegionsList.data.resources
                    }
                    type={selectedPolicyForViewRegionsList?.type}
                    isRegionAny={selectedPolicyForViewRegionsList.data.is_all}
                    onClose={() => setSelectedPolicyForViewRegionsList(null)}
                  />
                ) : null}
              </div>
            </>
          )}
        </ContentBox>
      </PageContent>
    </PageContainer>
  );
};

export default RemediationPolicy;
