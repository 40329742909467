import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import PieCharts from '@root/pages/inventory1.3/charts/PieChart';
import { Box, Grid, Skeleton } from '@mui/material';
import agent from '@root/agent';
// import PieChart from '@root/components/v2/charts/PieChart';
// import { PieChart } from '@mui/x-charts';
import ContentBox from '@root/components/v2/content/ContentBox';
// import Text from '@root/components/v2/ui-elements/Text';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { makeCQShieldPriorityKey } from '@root/query/dashboard';
import { useQuery } from '@tanstack/react-query';

// import { styled } from '@mui/material/styles';

// import Loader from '@root/components/common/Loader';
import NoData from '@root/components/common/NoData';

import './CQShieldPriority.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
const TIMEZONE = dayjs.tz.guess();

const colorMapping = {
  Critical: '#CD0404',
  High: '#F62121',
  Medium: '#FFA825',
  Low: '#E8DF07',
};

const InfoComponent = ({ severity, severity_count, onClick, resource_type }) => {
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      onClick(severity, resource_type);
    },
    [severity, onClick, resource_type],
  );
  return (
    <Box
      onClick={handleClick}
      sx={{
        height: '76px',
        border: '1px solid #CBD5E0',
        borderRadius: '8px',
        flex: '1',
        padding: '12px 16px 12px 16px',
        cursor: 'pointer',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -1px rgba(0, 0, 0, 0.12)',
        },
      }}>
      <Box sx={{ dislay: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '20px' }}>
          <Box
            sx={{
              height: '12px',
              width: '12px',
              borderRadius: '100%',
              backgroundColor: colorMapping[severity],
            }}></Box>
          <p
            style={{
              padding: 0,
              margin: 0,
              marginLeft: '8px',
              lineHeight: '20px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              fontSize: '14px',
              color: ' rgb(26, 32, 44)',
            }}>
            {severity[0] + severity.substring(1).toLowerCase()}
          </p>
        </Box>
        <p
          style={{
            padding: 0,
            margin: 0,
            marginTop: '8px',
            fontFamily: 'Manrope',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            textAlign: 'left',
            color: '#242424',
          }}>
          {severity_count}
        </p>
      </Box>
    </Box>
  );
};

// function LegendRow({ label, count, color, onClick, resource_type }: any) {
//   const handleClick = useCallback(
//     e => {
//       e.preventDefault();
//       onClick(label, resource_type);
//     },
//     [label, onClick, resource_type],
//   );

//   return (
//     <Box display="flex" flexDirection="row" alignItems="center" marginBottom="5px">
//       <Box
//         width="10px"
//         height="10px"
//         marginRight="8px"
//         borderRadius="50%"
//         sx={{
//           backgroundColor: color || '#ccc',
//         }}
//         component="div"
//       />
//       <Text fontWeight={500} color={'#484D56'}>
//         {label}
//       </Text>
//       <Box flexGrow={1} />
//       <Text element="a" href="#" fontWeight={500} className="CQShieldPriority__CountLink" onClick={handleClick}>
//         {count}
//       </Text>
//     </Box>
//   );
// }

type Props = {
  cloudProvider: string;
  dateFilter: string;
  selectedValue: string;
};

function CQShieldPriority({ cloudProvider, dateFilter, selectedValue }: Props) {
  const history = useHistory();
  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();
  // const [selectedValue, setSelectedValue] = useState('');
  const queryEnabled = useMemo(
    () =>
      Boolean(
        (selectedEnvironments?.length && cloudProvider === 'all') || cloudProvider === 'aws' || cloudProvider === 'gcp',
      ),
    [cloudProvider, selectedEnvironments],
  );

  const priorityData = useQuery(
    makeCQShieldPriorityKey(
      selectedEnvironmentTags,
      selectedEnvironments?.map((e: any) => e.id) || [],
      cloudProvider,
      dateFilter,
      selectedValue,
    ),
    () =>
      agent.Dashboard_New.get_cq_shield_priority({
        scope: selectedEnvironmentTags,
        environments: selectedEnvironments?.map((e: any) => e.id) || [],
        cloud_provider: cloudProvider,
        date_filter: dateFilter,
        assessment_time_filter: selectedValue,
        timezone: TIMEZONE,
      }),
    {
      enabled: queryEnabled,
    },
  );

  const _priorityData = useMemo(() => {
    return {
      critical: priorityData?.data?.data?.critical || 0,
      critical_resource_type: priorityData?.data?.data?.critical_resource_type || [],
      high: priorityData?.data?.data?.high || 0,
      high_resource_type: priorityData?.data?.data?.high_resource_type || [],
      low: priorityData?.data?.data?.low || 0,
      low_resource_type: priorityData?.data?.data?.low_resource_type || [],
      medium: priorityData?.data?.data?.medium || 0,
      medium_resource_type: priorityData?.data?.data?.medium_resource_type || [],
      total: priorityData?.data?.data?.total_severity || 0,
    };
  }, [priorityData.data]);

  // "scope": ["Production"],
  //       "environments": ["881731855274"],
  //       "cloud_provider": "aws", // aws, gcp, azure
  //       "date_filter": "All", // 24hours, 7days, 30days, 90days, All,
  //       "timezone": "Asia/Kolakata"

  const handleCountClick = useCallback(
    (priority, resource_type: any) => {
      localStorage.setItem('resource_type_csp', resource_type);
      history.push(`/inventory/resources-table?from=dashboard-csp&severity=${priority?.toLowerCase()}`);
    },
    [history],
  );

  const priorityArray = [
    { name: 'Critical', value: _priorityData.critical },
    { name: 'High', value: _priorityData.high },
    { name: 'Medium', value: _priorityData.medium },
    { name: 'Low', value: _priorityData.low },
  ];
  // console.log(handleCountClick);

  return (
    <Grid xs={4} height="440px" item>
      <ContentBox
        title="CQ Shield Priority"
        infoMsg="Priority based on Violations, Vulnerabilities, Anomaly/Threat, Exposed identities, Secrets etc"
        height="100%"
        containerStyle={{ display: 'flex', flexDirection: 'column' }}
        paddingMode="content">
        {cloudProvider === 'azure' || !queryEnabled ? (
          <NoData height={'100%'} />
        ) : priorityData.isFetching ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent={'flex-start'}
            height="440px"
            padding="15px">
            <Box width="160px" height="160px" marginBottom="20px">
              <Skeleton variant="circular" width="160px" height="160px" />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              justifyContent="center"
              borderRadius="5px"
              component="div">
              <Box
                width="100%"
                height="160px"
                display="flex"
                marginBottom="8px"
                flexDirection="column"
                justifyContent="flex-start">
                <Box display="flex" height="80px">
                  {' '}
                  <Skeleton width="50%" height="100px" sx={{ marginRight: '10px' }} />
                  <Skeleton width="50%" height="100px" />
                </Box>
                <Box display="flex" height="80px">
                  <Skeleton width="50%" height="100px" sx={{ marginRight: '10px' }} />
                  <Skeleton width="50%" height="100px" />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" height="100%">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
              height="100%"
              width={'100%'}>
              {/* <PieChart
                  width={140}
                  middleText="Resources"
                  middleValue={_priorityData.total || 0}
                  data={[
                    { value: _priorityData.critical, percent: 10, name: 'Critical' },
                    { value: _priorityData.high, percent: 10, name: 'High' },
                    { value: _priorityData.medium, percent: 10, name: 'Medium' },
                    { value: _priorityData.low, percent: 10, name: 'Low' },
                  ]}
                  colors={{
                    Critical: '#CD0404',
                    High: '#EB6C6C',
                    Medium: '#FFA825',
                    Low: '#E8DF07',
                  }}
                /> */}
              {/* <PieChart
                  series={[
                    {
                      data: priorityArray.filter(priority => priority['value'] > 0),
                      arcLabelMinAngle: 40,
                      innerRadius: 75,
                      outerRadius: 90,
                      paddingAngle: 8,
                      cornerRadius: 5,
                      startAngle: -90,
                      endAngle: 360,
                      cx: 105,

                      cy: 145,
                    },
                  ]}
                  slotProps={{}}
                  legend={{ hidden: true }}>
                  <PieCenterLabel>
                    {_priorityData.total < 1000 ? _priorityData.total : `${_priorityData.total / 1000}K`}
                  </PieCenterLabel>
                  <PieCenterSecondLabel>Resources</PieCenterSecondLabel>
                </PieChart> */}
              <PieCharts
                chartData={priorityArray}
                count_msg={'Resources'}
                count_value={_priorityData.total}
                colors={colorMapping}
              />
            </Box>

            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '15px',
              }}>
              <Box width={'100%'} display={'flex'} gap={'8px'}>
                <InfoComponent
                  severity={'Critical'}
                  severity_count={_priorityData.critical}
                  resource_type={_priorityData.critical_resource_type}
                  onClick={handleCountClick}
                />
                <InfoComponent
                  severity={'High'}
                  severity_count={_priorityData.high}
                  onClick={handleCountClick}
                  resource_type={_priorityData.high_resource_type}
                />
              </Box>
              <Box width={'100%'} display={'flex'} gap={'8px'}>
                <InfoComponent
                  severity={'Medium'}
                  severity_count={_priorityData.medium}
                  onClick={handleCountClick}
                  resource_type={_priorityData.medium_resource_type}
                />
                <InfoComponent
                  severity={'Low'}
                  severity_count={_priorityData.low}
                  onClick={handleCountClick}
                  resource_type={_priorityData.low_resource_type}
                />
              </Box>
            </Box>
          </Box>
        )}
      </ContentBox>
    </Grid>
  );
}

export default CQShieldPriority;
