import React, { useRef, useState, useEffect } from 'react';
import { Tooltip as MuiTooltip, tooltipClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => <MuiTooltip {...props} classes={{ popper: className }} />)(
  ({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: props?.color || '#fafafa',
      color: props?.fontcolor || '#121212',
      boxShadow: theme.shadows[2],
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Manrope',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#efefef',
    },
  }),
);

function EllipsisTooltip({ title, children, style, className = '', ...props }) {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);
  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      setIsOverflow(element?.scrollWidth > element?.clientWidth);
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);
  if (!title) {
    return children;
  }
  return (
    <LightTooltip title={isOverflow ? title : ''} disableHoverListener={!isOverflow} arrow {...props}>
      <Typography
        className={className}
        ref={textRef}
        noWrap
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...style }}>
        {children}
      </Typography>
    </LightTooltip>
  );
}

export default EllipsisTooltip;
